export const AppConstants = {
    fallbackProductImageLink: '/static/hp-support-site-console/resources/images/tms/tms-fallback.png',
    imgWarrantyDefault: '/wcc-assets/content/dam/hp-wcc/headless-assets/images/contact/Black_Warranty.svg',
    contactOptionDefaultImage: '/wcc-assets/content/dam/hp-wcc/headless-assets/images/contact/Customer_support.svg',
    messagingDefaultImage: '/wcc-assets/content/dam/hp-wcc/headless-assets/images/contact/ccls-icons/CCLS_OmniChat.png',
    whatsappImage: '/wcc-assets/content/dam/hp-wcc/headless-assets/images/contact/WhatsApp_Logo.png',
    messengerImage: '/wcc-assets/content/dam/hp-wcc/headless-assets/images/contact/ccls-icons/CCLS_OmniChat.png',
    validLocales: 'au-en,bg-bg,ca-en,hr-hr,ee-et,emea_africa-en,emea_middle_east-en,id-en,ie-en,in-en,lamerica_nsc_carib-en,lt-en,lv-lv,my-en,nz-en,ph-en,ro-en,ro-ro,rs-sr,sg-en,si-sl,sk-sk,th-en,gb-en,us-en,vn-en,za-en,at-de,be-fr,fr-fr,ca-fr,ch-fr,emea_africa-fr,be-nl,nl-nl,ar-es,bo-es,cl-es,co-es,ec-es,lamerica_nsc_cnt_amer-es,mx-es,es-es,pe-es,pr-es,py-es,uy-es,ve-es,br-pt,by-ru,kz-ru,ru-ru,ua-ru,ch-de,de-de,cn-zh,cz-cs,dk-da,emea_middle_east-ar,fi-fi,gr-el,hk-zh,tw-zh,hu-hu,il-he,it-it,jp-ja,kr-ko,no-no,pl-pl,pt-pt,se-sv,th-th,tr-tr',
    deviceVerifyURL: 'wcc-services/profile/devices/confirm',
    warrantySpecsURL: 'wcc-services/profile/devices/warranty/specs',
    retiredProductImageUrl: '/wcc-assets/content/dam/hp-wcc/headless-assets/images/contact/Alerts_retired_warning_60.svg',
    fallbackHpplus: "/wcc-assets/content/dam/hp-wcc/fe-assets/images/HP+_desktop_productribbon.png",
    fallbackInstantInk: "/wcc-assets/content/dam/hp-wcc/fe-assets/images/Instant_Ink_logo_desktop_productribbon.png",
    waitingImage: "/wcc-assets/content/dam/hp-wcc/fe-assets/images/wpd/waitingicon.gif",
    loadingImage: "/wcc-assets/content/dam/hp-wcc/fe-assets/images/wpd/loader.svg",
    stepsImage: "/wcc-assets/content/dam/hp-wcc/fe-assets/images/wpd/stepsicon.gif",
    downloadImage: "/wcc-assets/content/dam/hp-wcc/fe-assets/images/wpd/downloadicon.gif",
    redoImage:"/wcc-assets/content/dam/hp-wcc/fe-assets/images/wpd/redo.svg",
    SSFCommonJs: 'https://sudf-resources.hpcloud.hp.com/DMDScripts/SSF.Common.js',
    SSFWpdJs: 'https://sudf-resources.hpcloud.hp.com/DMDScripts/SSF.HPWPD.js',
    SSFHpdiaJs: 'https://sudf-resources.hpcloud.hp.com/DMDScripts/SSF.HPDIA.js',
    modelDropdownEnabledRoutes: [
        'product-serial-number',
        'printer-serial-number',
        'laptop-serial-number',
        'desktop-serial-number',
        'poly-serial-number'
    ],
    activeWarrantyStatus: [
        "XW",
        "IW",
        "WE"
    ],
    regExpPN : "^[\s]*[a-zA-Z0-9#/-]{2,20}[\s]*$",
    polyCategoryWiseProductsPerPage: 16,
    subscriptionSupportedProductType : ['printer','laptop'],
    documentFallbackLocaletoUS_EN : "us-en",
    documentFallbackLocaletoTW_ZH : "tw-zh"
}

export const HP_ALL_IN_PLAN_SERIESOID = 2101795773;

export const ROUTE_PARAMS = {
    SEO_NAME: "seoName",
    MODEL_OID: "modelOid",
    SERIES_OID: "seriesOid",
    MODEL: "model",
    SUPPORT_CATEGORY: "supportCategory"
}

export const PAGE_NAMES = {
    PDPLANDING: "pdpLanding"
}
export enum SWDSeverityFlag {
    SEVERITY_FLAG_IMPORTANT = 'important',
    SEVERITY_FLAG_RECOMMENDED = 'recommended',
    SEVERITY_FLAG_CRITICAL = "critical"
}
export enum BrowserTypes {
  BROWSER_TYPE_GOOGLE = 'chrome',
  BROWSER_TYPE_EDGE  = 'ms-edge-chromium',
  BROWSER_TYPE_FIREFOX  = "firefox",
  BROWSER_TYPE_MS_EDGE = "ms-edge"
}
export enum OSTypes {
  OS_TYPE_MAC = "Mac OS",
  OS_TYPE_IOS = "iOS",
  OS_TYPE_WINDOWS = "Windows",
  OS_TYPE_ANDROID = "Android"

}

export enum RegisterApiResponseCodes {
    OK = 200,
    NOT_ACCEPTABLE = 406,
    NO_CONTENT = 204,
    INTERNAL_SERVER_ERROR = 500,
    INVALID_SESSION = 403
}

export enum RegisterApiResponseMessages {
  SUCCESS = 'Success',
  MULTIPLE_DEVICES = 'Multiple products, need PN',
  NO_DEVICE_FOUND = 'Device Not Found',
  ERROR_IN_PROCESSING = 'Serial number not found',
  INVALID_INPUT = 'Bad data in payload.',
  DEVICE_ALREADY_REGISTERED = 'Device already registered',
  INVALID_SESSION = 'Invalid session',
  PN_NOT_MATCHING = 'Device not found, PN not matching with SN',
  NO_SN_PN_FOUND = 'Serial Number and Product Number not found',
  PN_NOT_VALID = "The Product Number is invalid. Please verify and try again",
  DEVICE_ALREADY_CLAIMED = 'Device already claimed'
}

export enum SPECS_ERROR_MESSAGES {
  MULTIPLE_DEVICES = 'Multiple products, need PN',
  NO_DEVICE_FOUND = 'Device Not Found',
  PN_NOT_VALID = 'The Product Number is invalid. Please verify and try again',
  PN_NOT_MATCHING = 'Device not found, PN not matching with SN',
  SN_INVALID = 'SNInvalid',
  PN_INVALID = 'PNInvalid',
  BAD_PAYLOAD = 'Bad data in payload.'
}

export enum CommonErrorMessages {
  MULTIPLE_DEVICES = 'Multiple products, need PN',
  NO_DEVICE_FOUND = 'Device Not Found',
  PN_NOT_VALID = 'The Product Number is invalid. Please verify and try again',
  PN_NOT_MATCHING = 'Device not found, PN not matching with SN',
  BAD_PAYLOAD = 'Bad data in payload.'
}

export enum DeviceNumberType {
    SERIAL = 'SN',
    PRODUCT = 'PN'
}

export enum Errors {
    INVALID_SN_INPUT = 'invalidSN',
    MULTIPLE_RECORDS = 'mulitpleRecords',
    INVALID_PN_INPUT = 'invalidPN',
    INVALID_SN_PN_INPUT = 'invalidSNPN'
}

export enum syncApiError{
    key = 'syncApiError',
    value = 'true'
}

export const ALERT_TYPE_ORDER =  ['Negative','Warning', 'Positive','Neutral'];
export const ONE_HP_NAMES = ["HpOne", "HPOnePrint", "HPOnePC", "HP Axis ONE"];
export const HP_ONE_WARRANTY_TYPE = "5";

export const CLIENT_STRINGS = [{
  s: 'Windows 10',
  r: /(Windows 10.0|Windows NT 10.0)/
}, {
  s: 'Windows 8.1',
  r: /(Windows 8.1|Windows NT 6.3)/
}, {
  s: 'Windows 8',
  r: /(Windows 8|Windows NT 6.2)/
}, {
  s: 'Windows 7',
  r: /(Windows 7|Windows NT 6.1)/
}, {
  s: 'Windows Vista',
  r: /Windows NT 6.0/
}, {
  s: 'Windows Server 2003',
  r: /Windows NT 5.2/
}, {
  s: 'Windows XP',
  r: /(Windows NT 5.1|Windows XP)/
}, {
  s: 'Windows 2000',
  r: /(Windows NT 5.0|Windows 2000)/
}, {
  s: 'Windows ME',
  r: /(Win 9x 4.90|Windows ME)/
}, {
  s: 'Windows 98',
  r: /(Windows 98|Win98)/
}, {
  s: 'Windows 95',
  r: /(Windows 95|Win95|Windows_95)/
}, {
  s: 'Windows NT 4.0',
  r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
}, {
  s: 'Windows CE',
  r: /Windows CE/
}, {
  s: 'Windows 3.11',
  r: /Win16/
}, {
  s: 'Android',
  r: /Android/
}, {
  s: 'Open BSD',
  r: /OpenBSD/
}, {
  s: 'Sun OS',
  r: /SunOS/
}, {
  s: 'Linux',
  r: /(Linux|X11)/
}, {
  s: 'iOS',
  r: /(iPhone|iPad|iPod)/
}, {
  s: 'Mac OS X',
  r: /Mac OS X/
}, {
  s: 'Mac OS',
  r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
}, {
  s: 'QNX',
  r: /QNX/
}, {
  s: 'UNIX',
  r: /UNIX/
}, {
  s: 'BeOS',
  r: /BeOS/
}, {
  s: 'OS/2',
  r: /OS\/2/
}, {
  s: 'Search Bot',
  r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
}];

export const MONTH_SHORT_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export enum DeviceType {
    PRINTER = 'printer',
    LAPTOP =  'laptop',
    DESKTOP = 'desktop',
    HEADSET = 'headset',
    OTHER = 'other'
}

export enum POSITION {
    left= "left", 
    right = "right", 
    top = "top", 
    bottom = "bottom"
}

export const SUBSCRIBED_WARRANTY_TYPE = "5";
export const NONSIGNEDIN_USER_STATUS = 24;
export const WRONG_USER_STATUS= 25;
export const NONSIGNEDIN_USER_STATE="LR"

export const SWD_MEDIA_TYPE_REFERENCE = "Reference";
export const COMMERCIAL_SERIES_LEVEL_FLG = "Commercial";
export const COMMERCIAL_MODEL_LEVEL_FLG = "Large";
export const SWD_VIDEOS_PER_PAGE = 4;
export const SWD_VIDEO_HEIGHT = '569px';
export const SWD_VIDEO_WIDTH = '1010px';
export const SSF_INSTALLATION_WAITING_TIME = 30000; // 30 seconds in milliseconds
export const SSF_TIME_OUT = 300000; // 5 mins in milliseconds
export const SWD_FULL_DATE_VALUE = 10;
export const MANUAL_INSTALL_PAGE_TEXT = 'closure';
export const APP_INSTALL_PAGE_TEXT = 'appclosure';
export const HPDIA_INSTALL_PAGE_TEXT = 'hpdiaclosure';
export const SOAR_ATTACHMENT_TEXT = 'soar-attachment/';
export const PRODUCT_ALERTS_COUNT_MOBILE = 3;
export const PRODUCT_ALERTS_COUNT_DESKTOP = 5;
export const DEFAULT_ALERTS_COUNT = 10;
export const SWD_CATEGORY_PAGETEMPLATENAMES = {
  printers: 'SWD-PrinterLanding',
  laptops: 'SWD-LaptopLanding',
  desktops: 'SWD-DesktopLanding',
  products: 'SWD-OtherLanding'
};
export const SWD_INSTALL_PAGE_TEMPLATE_NAMES = {
	appclosure: "SWDClosure_App",
	hpdiaclosure: "SWDClosure_HPDIA",
	closure: "SWDClosure_Manual"
}
export const PRODUCT_SOURCE_MAPPING = {
  swd: 'SWD',
  contact:'CONTACT_HP'
};

export const PAGENAME_SOURCE_NAMES = {
  addDevice: 'add-device',
  contact: 'contact',
  swdLanding: 'swd'
};
export const POLY_PROD_CATEGORY = 'poly';

export const MASTIFF_CONTACT_OPTION_SERVICEID = 'Mastiff';
export const MASTIFF_CONTACT_OPTION_SERVICETYPE = 50;

export const MASTIFF_CONTACT_OPTION_TEMPLATEID = 'contact_option_mastiff_tile_template';

export const URLS = {
    REGIONS: '/wcc-services/ssc/region',
    CLC_REGIONS: '/wcc-services/clc/allRegions',
    REGISTER: '/wcc-services/profile/devices/register'
}
export const ALERT_DESC_LIMIT = 125;
export const ALERT_CTA_LIMIT = 25;

export enum STORE {
    REGIONS = 'Regions',
    CLC_REGIONS = 'CLCRegions',
    SSC_KEYS = 'sscKeys',
    PRODUCT_CONTEXT_DATA = 'productContextData',
    WARRANTY_SPECS_DATA = 'warrantySpecsData',
    ALL_DEVICES = 'allDeviceData',
    CONFIRM_DEVICE = 'confirmDeviceData',
    DEVICE_DEVICEID = 'device_DeviceID',
    DEVICE_WARRANTY = 'DeviceWarrantyData',
    TERMBASE = 'termbaseKeys',
    SYNC_STATUS = 'SyncStatusData',
    PDP_FEATURE = 'pdpFeature'
}

export const COUNTRIES_WITH_STATES = [ 'us', 'ca'];

export const VIDEO_TYPE = {
  YOUTUBE : "YouTube",
  BRIGHTCOVE: "Brightcove"
}

export const GDL_NOT_SET = 'Not Set';
