import { Injectable, OnDestroy, Inject, SecurityContext } from '@angular/core';
import { DOCUMENT , Location } from '@angular/common';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { orderBy, filter, includes, isEmpty, cloneDeep } from 'lodash-es';
import { PageService } from './page.service';
import { Subject, BehaviorSubject, Observable, Observer, throwError } from 'rxjs';
import { takeUntil, map, filter as rxjsFilter } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { cclcAlias } from '../../pages/contact/configuration/cclc-aliases.config';
import { AppConstants, URLS } from "../../constants/app-constants";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RetiredProductComponent } from '../../modules/feature-module/retired-product/retired-product-component/retired-product.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import * as  AllDeviceAction  from "../../store/all-devices-store/all-devices.actions";
import { allDeviceSelector } from '../../store/all-devices-store/all-devices.selectors';
import * as  ConfirmDeviceAction  from "../../store/confirm-device-store/confirm-device.actions";
import { confirmDeviceSelector } from '../../store/confirm-device-store/confirm-device.selectors';
import { AllDeviceData } from '../../store/all-devices-store/all-devices.reducer';
import { ProductSpecs } from '../../model/warranty-spec.model';
import { ConfirmDeviceData } from '../../store/confirm-device-store/confirm-device.reducer';
import { CountryList } from '../../modules/feature-module/country-lang-selector/countryList.model';
import { whitelistedDomains as WhiteListedDomains } from '../../config/whitelisted-domains.config';
import { RegisterResponse } from '../../modules/feature-module/device-carousel/device-carousel/device.model';
import * as DOMPurify from 'dompurify';
import { DomPurifierService } from './dom-sanitizer.service';

// Root provider is required
@Injectable({
  providedIn: 'root'
})

export class CommonService implements OnDestroy {
  // Page related config
  pageConfig: any = this.pageService.config;

  displayKeyValue;

  componentDataToPass;

  allCcLcAlias = cclcAlias;
  // Flag to decide if RequestToClose button on Case Details modal should be inactive
  caseCloseRequested = false;

  hpDotComCcLcMappingSub = new BehaviorSubject<any>({});
  contexualSecNavContactHpLink = new BehaviorSubject<any>({});
  destroySubject$: Subject<void> = new Subject();

  resetSerialNumberEntryForm: Subject<void> = new Subject();
  resetServiceSelection: Subject<void> = new Subject();
  productSubmit: Subject<any> = new Subject();
  productSubmitToContactForm: Subject<any> = new Subject();
  ChatBotTriggeredSubject: Subject<any> = new Subject<string>();

  devicesDataSub = new Subject();
  navigateBackToContactParam:string;
  private window: Window;
  location: Location;

  kaasSearchExtraParam:BehaviorSubject<{
    search: {[key:string]:string},
    typeahead: {[key:string]:string}
  }> = new BehaviorSubject<{
    search: {[key:string]:string},
    typeahead: {[key:string]:string}
  }>({
    search: {},
    typeahead: {}
  });

  polyFlags = {
    isPdpRedirectFromPoly: false,
    polyUrl: null
  }
  previousUrl: string;
  currentUrl: string;
  // docManipulateFlag = {
  //   isManipulatedURL: false //SSVSPRT-15885 CHECK IF URL IS MANIPULATED
  // }

  constructor(
    private pageService: PageService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document,
    private http: HttpClient,
    private modalService: NgbModal,
    private store:Store<AppState>,
    location: Location,
    private domPurifierService: DomPurifierService
  ) {
    this.window = this.document.defaultView;
    this.location = location;
    this.route.queryParams
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(
        (queryParams) => {
          this.displayKeyValue = queryParams;
        }
      );

    this.router.events.pipe(
      rxjsFilter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
       this.previousUrl = this.currentUrl;
       this.currentUrl = event.url;
    });
  }

  getFormattedWebReleaseDate(webReleaseDate: string) {
    const getFormattedDate = (webReleaseDate: string) => {
      const modifiedString = webReleaseDate.split('');
      modifiedString.splice(4, 0, '-');
      modifiedString.splice(7, 0, '-');
      return modifiedString.join('');
    };

    if (!isEmpty(webReleaseDate)) {
      return moment(new Date(getFormattedDate(webReleaseDate))).format(('YYYY-MM-DD'));
    }
    return '';
  }

  addViewEncapsulation(tag: HTMLElement){
    const ngId:string = tag.getAttributeNames()?.filter(v=>/^_ngcontent/.test(v))[0];
    if(ngId){
      tag.querySelectorAll('*').forEach(e=>{
        e.setAttribute(ngId, '');
      });
    }
  }

  onUrlRouteChanges$():Observable<ActivatedRouteSnapshot>{
    return this.router.events.pipe(
      rxjsFilter(event => event instanceof NavigationEnd),
      map(() => this.route.snapshot),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
    )
  }


  parseStringtoHtml(content) {
    content = this.getSanitizedData(content);
    
    if (content) {
      const el = this.document.createElement('div');
      el.innerHTML = content;
      return el;
    }
  }

  getGdlEntitlementResults(warrantyData){
    if (!warrantyData) return {};
    let gdlObj;
    if (warrantyData && warrantyData) {
      let warrantyRemainingDays;
      let itemPeriodInMonths;
      let currentDate = new Date();
      let wEndDate = warrantyData.warrantyEndDate
        ? new Date(warrantyData.warrantyEndDate)
        : null;
      let wStartDate = warrantyData.warrantyStartDate
        ? new Date(warrantyData.warrantyStartDate)
        : null;
      if (wEndDate && wStartDate) {
        warrantyRemainingDays = Math.ceil(
          (wEndDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
        );
        itemPeriodInMonths =
          (wEndDate.getFullYear() - wStartDate.getFullYear()) * 12;
        itemPeriodInMonths -= wStartDate.getMonth() + 1;
        itemPeriodInMonths += wEndDate.getMonth() + 1;
      }
      gdlObj = {
        EntitlementResults: {
          WarrantyState: warrantyData.state,
          EntResults: warrantyData.state,
          serialNumber: warrantyData.serialNumber,
          serviceObligationLineItemStartDate: warrantyData.warrantyStartDate
            ? moment(new Date(warrantyData.warrantyStartDate).toUTCString()).format(('YYYY-MM-DD'))
            : "",
          serviceObligationLineItemRemainingDays: warrantyRemainingDays ?? null,
          serviceObligationLineItemEndDate: warrantyData.warrantyEndDate
            ? moment(new Date(warrantyData.warrantyEndDate).toUTCString()).format(('YYYY-MM-DD'))
            : "",
          serviceObligationTypeCode: warrantyData.warrantyType ?? "",
          serviceProductTypeCode: warrantyData.productLineCode ?? "",
          WarrantyType: warrantyData?.warrantyType ||  "",
          tierServiceObligationLineItemPeriodInMonths:
            itemPeriodInMonths ?? null,
        }
      };
      return gdlObj;
    }
  }

  openRetiredProductModal() {
    const historicalProductData = sessionStorage?.getItem('historicalProductData') && JSON.parse(sessionStorage?.getItem('historicalProductData'));
    const retiredProductData = { imageUrl: AppConstants.retiredProductImageUrl }

    const modalRef = this.modalService.open(RetiredProductComponent, { windowClass : 'retired-product-modal', size: 'lg'});
    modalRef.componentInstance.retiredProductData = retiredProductData;
    historicalProductData ?modalRef.componentInstance.historicalProductData = historicalProductData: null;
    return modalRef;
  }

  addDevice(payload: any) {
    return this.http.post(URLS.REGISTER, payload)
      .pipe(map((res: RegisterResponse) => {
        return res;
      }))
  }

  getResolvedUrl() {
    return this.route.snapshot.url;
  }

  scrollToElement(element: any) {
    const userBrowser = this.pageConfig.browser.toLowerCase();
    const getScrollTop = (el) => {
      if (this.pageConfig.isBrowser && this.window && this.document && el) {
        const elOffsetTop = el.getBoundingClientRect().top;
        const scrollTop = this.window?.pageYOffset || this.document?.documentElement?.scrollTop;
        return elOffsetTop + scrollTop;
      }
    }

    const scrollMovement = (time) => {
      const browserList = ['ie', 'ms-edge-chromium', 'ms-edge'];

      setTimeout(() => {
        if (includes(browserList, userBrowser)) {
          this.window?.scroll(0, getScrollTop(element)) //x-axis, y-axis
        } else {
          this.window?.scrollTo({
            top: getScrollTop(element),
            left: 0,
            behavior: 'smooth'
          });
        }
      }, time)
    }
    scrollMovement(0);
  }

  matchRoute() {
    if (typeof this.location !== 'undefined') {
      const urlQuerySeparated = this.location.path().split('?');
      const urlSlashSeparated = urlQuerySeparated[0].split('/');

      if (urlSlashSeparated.length === 3 && urlSlashSeparated.pop() === 'contact') {
        const params = new URLSearchParams(urlQuerySeparated[1] || '');
        const originId = params.get('originid');

        switch (originId) {
          case 'callme':
            return 'DCCLanding';

          case 'messaging':
            return 'DCCMessaging';

          //HPX Changes - start
          case 'myhp':
          case 'hpsmart':
          case 'hpaccount':
          case 'hpsa': //HPSA90-9482: as per request from Julia
            return 'DCCMyHP';
          //HPX Changes - end

          default:
            return 'UCLanding';
        }
      }

      if (urlSlashSeparated.length === 2 && urlSlashSeparated.pop() === 'contact') {
        const params = new URLSearchParams(urlQuerySeparated[1] || '');
        const originId = params.get('originid');
        switch (originId) {
          case 'messaging':
            return 'DCCMessaging';

          default:
            return 'UCLanding';
        }
      }
    }
    if(typeof this.navigateBackToContactParam !== 'undefined'){
      if(this.navigateBackToContactParam === 'callme'){
        return 'DCCLanding';
      }else if(this.navigateBackToContactParam === 'messaging'){
        return 'DCCMessaging';
      }
    }

    return null;
  }

  // As per R203-1-  included international targeting at page-level
  getPathFromRoute() {
    let path = [];
    path = this.router.url.split('/');
    path.splice(0, 2);
    return `/${path.join('/')}`;
  }

  safeParseJSON(jsonData) {
    let parsedData;
    try {
      if (jsonData != undefined) {
        parsedData = JSON.parse(jsonData);
      }
    } catch (error) {
      console.log('Error occured while parsing data in commonService', error);
    }

    return parsedData;
  }



  localizeURL(link,countryCode?, languageCode?) {
    const domainName = this.pageConfig.isBrowser ? this.window?.location?.origin : 'https://support.hp.com';

    if (link) {
      const cc = countryCode || this.pageConfig.cc;
      const lc = languageCode || this.pageConfig.lc;

      link = link.replace(/{lc}/g, lc);
      link = link.replace(/{cc}/g, cc);

      link = link.replace(/{host}/g, domainName);
      return this.getSanitizedUrl(link);
    } else {
      return '';
    }
  }

  formatDate(utcDate, format = 'MMM DD, YYYY') {
    let formattedDate = '';

    if (utcDate) {
      formattedDate = moment(utcDate).format(format);
    }

    return formattedDate;
  }

  convertUTCdateFormat(utcDate) {
    if (utcDate) {
      const momentUtcDate = moment(utcDate).utc();
      const dateFormat = utcDate ? momentUtcDate.format('LL') : '';
      return dateFormat ? moment(dateFormat).format(('MMM Do YYYY')) : '';
    } else {
      return '';
    }
  }

  convertDateForCSV(utcDate) {
    if (utcDate) {
      const dateTime = utcDate ? moment(utcDate).format(('MMM DD, YYYY HH:mm')).replace(/(\d+)(st|nd|rd|th)/, '$1') : '';
      return dateTime;
    } else {
      return '';
    }
  }

  smoothScrollToElementWithId(elementId: string) {
    if (typeof document !== 'undefined') {
      let top = document.getElementById(elementId);
      if (top !== null) {
        this.document.documentElement.classList.add('smooth-scroll');
        top.scrollIntoView();
        this.document.documentElement.classList.remove('smooth-scroll');
      }
    }
  }

  getRelativeUrl(url: string): string {
    const regexToIdentifyDomain = new RegExp('^https?://(?:www\\.)?[^/]+', 'i');
    const relativeUrl = url.replace(regexToIdentifyDomain, '');
    return relativeUrl;
  }

  isAbsoluteUrl(url: string): boolean {
    try {
      const urlObject = new URL(url);
      return urlObject.protocol !== '';
    } catch (e) {
      return false;
    }
  }

  isWhitelistedDomain(url: string): boolean {
    const domain = url.split('/')[2];
    const isWhitelisted = WhiteListedDomains.some(whitelistedDomain => domain == whitelistedDomain);
    return isWhitelisted;
  }

  getSanitizedData(data) {
    return this.sanitizeUntrustedData(data)
  }

  getSanitizedUrl(url: string): string {
    return this.sanitizeUntrustedData(url);
  }

  sanitizeUntrustedData(unsanitizedData, allowPeriod = false) {

    const sanitizeData = html => {
      if(this.pageConfig.isBrowser){
        return this.domPurifierService.sanitize(html, { ADD_ATTR: ['target'] });
      }else{
        return html;
      }
    };

    if (typeof unsanitizedData == 'object') {
      try{
        let stringifiedData = JSON.stringify(unsanitizedData);
        // This regex is aimed at preventing Open Redirect attacks. Blocks - \,.,@,%,<,>, and double forward slashes
        stringifiedData = sanitizeData(stringifiedData);
        return JSON.parse(stringifiedData);
      }
      catch (e) {
        console.log("Error in getSanitizedString");
      }
    }
    else if (typeof unsanitizedData == 'string') {
      // This regex is aimed at preventing Open Redirect attacks.  Blocks - \,.,@,%,<,>, and double forward slashes
      return sanitizeData(unsanitizedData);
    }
  }

  sanitizeXSSThreats(data: string): string {
    // This regex is aimed at preventing XSS attacks.  Blocks - %, /, \, <, >, ', ", :, ;, (, ), and `
    return data?.replace(/[""'%\\`:;()\/<>]/g, '');
  }

  gethashedURLParms(paramInput){
    const params = new URLSearchParams(this.router.url.split("?")[1]);
    const newParams = new URLSearchParams();
    let paramValue;
    params.forEach((value, name)=>{
      newParams.append(name.toLowerCase(), value);
    });
    paramValue = newParams.get(paramInput);
    if (paramValue && paramValue.indexOf("#") > 0) {
      paramValue = paramValue.slice(0, newParams.get(paramInput).indexOf("#"));
    }
    return paramValue;
  }

  setFocusAtEnd(elem) {
    const elemLen = elem.value.length;
    const elemVal = elem.value;
    // For IE Only
    if (this.document.selection) {
      // Set focus
      elem.focus();
      // Use IE Ranges
      const oSel = this.document.selection.createRange();
      // Reset position to 0 & then set at end
      oSel.moveStart('character', -elemLen);
      oSel.moveStart('character', elemLen);
      oSel.moveEnd('character', 0);
      oSel.select();
    } else {
      // Firefox/Chrome
      elem.focus();
      elem.value = '';
      elem.value = elemVal;
    }
  }

  // Decode data coming from API CRID2306
  decodeURIData(data) {
    if (data) {
      return decodeURIComponent(data);
    }
  }

  // Get UTC offset data
  getUTCOffset() {
    let formattedUTCOffsetTempData, UTCOffsetHrsTemp, UTCOffsetMinsTemp, negativePositiveFlag;
    let UTCOffset = new Date().getTimezoneOffset();
    UTCOffset > 0 ? negativePositiveFlag = 'M' : negativePositiveFlag = 'P';
    UTCOffset = Math.abs(UTCOffset);
    const UTCOffsetMins = UTCOffset % 60;
    const UTCOffsetHrs = Math.floor(UTCOffset / 60);
    if (UTCOffsetHrs < 10) {
      UTCOffsetHrsTemp = `0${UTCOffsetHrs}`;
    } else {
      UTCOffsetHrsTemp = `${UTCOffsetHrs}`;
    }
    if (UTCOffsetMins < 10) {
      UTCOffsetMinsTemp = `0${UTCOffsetMins}`;
    } else {
      UTCOffsetMinsTemp = `${UTCOffsetMins}`;
    }
    formattedUTCOffsetTempData = `${negativePositiveFlag}${UTCOffsetHrsTemp}${UTCOffsetMinsTemp}`;
    return formattedUTCOffsetTempData;
  }

  resolutionBaseDisplay() {
    const largeDevice = this.window?.innerWidth <= 800 ? false : true;
    const smallDevice = this.window?.innerWidth <= 800 ? true : false;

    return {
      _largedevice: largeDevice,
      _smallDevice: smallDevice
    };
  }

  iskeyAvaillableInObj(data, isDate) {
    if (isDate) {
      if (data != undefined) {
        const dateFormat = data ? moment(data) : '';
        return dateFormat;
      } else {
        return '';
      }
    } else {
      if (data != undefined) {
        return typeof data === 'string' ? data.toLowerCase() : data;
      } else {
        return '';
      }
    }
  }

  dataSorting(array, column, direction, isDate) {
    let dir;
    let result;

    if (array && array.length) {
      dir = direction ? 'asc' : 'desc';

      result = orderBy(array, (o) => {
        return this.iskeyAvaillableInObj(o[column], isDate);
      }, [dir]);
    }

    return result;
  }

  getDaydiff(data) {
    const currentTime = moment().utc();

    if (data) {
      const dataDate = moment(data);
      const dayDiff = currentTime.diff(dataDate, 'days');
      return dayDiff;
    }
  }

  removeHTMLtags(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  // Check Object key has value
  checkObjKeyHasValue(obj) {
    const nullKey = filter(Object.keys(obj), (key) => {
      return obj[key] === null || obj[key] === '';
    });

    return nullKey.length !== Object.keys(obj).length ? true : false;
  }

  // Check atleast one Object key has value in an Array
  checkArrayObjHasValue(array) {
    if (array.length) {
      const newArray = filter(array, (obj) => {
        if (this.checkObjKeyHasValue(obj)) {
          return obj;
        }
      });
      return newArray.length > 0 ? true : false;
    }
  }


  getOSBit(){
    //const deviceinfo = this.deviceDetectorService.getDeviceInfo();
    if (this.pageConfig !== undefined
        && this.pageConfig !== null
        && this.pageConfig.userAgent !== undefined
        && this.pageConfig.userAgent !== null
        ) {
      if( (this.pageConfig.userAgent.indexOf('Win64')!== -1) || (this.pageConfig.userAgent.indexOf('WOW64')!== -1 )) {
          return '64';
      } else if (this.pageConfig.userAgent.indexOf('Win32')!== -1 || this.pageConfig.userAgent.indexOf('WOW32')!== -1){
          return '32';
      } else {
          return '';
      }
    }
  }

  getOSname () {
      let osName;
      const isIpadDevice = this.pageConfig.isiPad;
      const os = this.pageConfig.os;
      if (os === 'Mac' && !isIpadDevice) {
        osName = 'Mac OS';
      } else if(os === 'Mac' && isIpadDevice) {
        osName = 'iOS';
      }
       else {
        osName = os;
      }
      return osName;
  }
  getOs() {
      let osInfo;
      if (this.pageConfig !== undefined
        && this.pageConfig !== null
        && this.pageConfig.userAgent !== undefined
        &&this.pageConfig.userAgent !== null
        ) {
          const deviceInfo =this.pageConfig && this.pageConfig !== null ? this.pageConfig.userAgent : '';
          osInfo = deviceInfo ? deviceInfo.split("(")[1].split(")")[0]: '';
      }
      return osInfo;
  }

  isSerialNumberPattern(str, sscKeys) {
    let snRegExPattern;
    if(sscKeys?.ENABLE_POLY_SEARCH_EXP?.keyValue === "Yes") {
      // validating SN with regex for POLY
      snRegExPattern = new RegExp(sscKeys?.PFINDER_SN_REG_EXP?.keyValue);
    } else {
       snRegExPattern = /^[a-zA-Z0-9]{10,16}$/;

    }
    return snRegExPattern.test(str);

  }

  isProductNumberPattern(str) {
    //const pnRegEx = /^[a-zA-Z0-9]{2,8}((#|-)+[a-zA-Z0-9]{3,6})?((\/|-)[a-zA-Z0-9]{1,3})?$/;
    const pnRegEx =  /^[a-zA-Z0-9#/-]{2,20}$/
    return pnRegEx.test(str);
  }

  //Set focus on input Field
  setFocusOnInputField(fieldID) {
    setTimeout(() => {
      const elemId = fieldID ? document.getElementById(fieldID) : null;
      if (elemId) {
        elemId.focus();
      }
    }, 0);
  }

  // Unused Methods

  isHTML(str) {
    const a = document.createElement('div');

    a.innerHTML = str;
    for (let c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType == 1) return true;
    }

    return false;
  }

  isMastiffEnabled(sscKeys , pageName){
    return  sscKeys?.MASTIFF_SPOS_ENABLE_ALL?.keyValue==='true'&& sscKeys?.[`MASTIFF_SPOS_ENABLE_${pageName}`]?.['keyValue']==='true' && (!includes(sscKeys?.[`MASTIFF_SPOS_BLACKLIST_${pageName}`]?.['keyValue'] ,`${this.pageConfig.cc}-${this.pageConfig.lc}`) || sscKeys?.[`MASTIFF_SPOS_BLACKLIST_${pageName}`]?.['keyValue']===' ' )
  }

  isTorontoEnabled(sscKeys, pageRef) {
    let chatBotPageList;
    const chatBotSscKey = `CHAT_BOT_${this.pageConfig.cc}_${this.pageConfig.lc}`;

    if (sscKeys[chatBotSscKey]) {
      chatBotPageList = sscKeys[chatBotSscKey].keyValue;
    }

    return includes(chatBotPageList, 'all') || includes(chatBotPageList, pageRef);
  }

  handleMastiffSposData(dataArray, isMastiffEnable){
    //Check mastiff js loaded or not in the dom
    const isMastiffJsLoaded = ()=>{
      var el = this.document.body.getElementsByTagName("*");
      for (var i = 0; i < el.length; i++) {
        if (el[i].tagName.toLowerCase() ==='script' && el[i].getAttribute('id')) {
          return el[i].getAttribute('id')==='mastiff';
        }
      }
    }
    if(dataArray) {
      var resultArray = dataArray.filter(item => !(item.desc === 'mastiffSpos'));
      return isMastiffEnable && isMastiffJsLoaded() ?dataArray: resultArray;
    }
  }

  getCustomLocale(fallBackCCLCValue, pageConfig){
    if(pageConfig){
      if(fallBackCCLCValue  && typeof fallBackCCLCValue ==='object' && fallBackCCLCValue.hasOwnProperty(pageConfig.locale)){
        return {'cc': fallBackCCLCValue[pageConfig.locale].split('-')[0], 'lc':fallBackCCLCValue[pageConfig.locale].split('-')[1]}
    } else {
      return  {'cc':pageConfig['cc'], 'lc': pageConfig['lc']}
    }
    }
  }
  replaceStringInURL(link ,fallBackCCLCValue, pageConfig){
    if(link && pageConfig){

      let domainName="https://support.hp.com";
      if(pageConfig.isBrowser) domainName = this.window?.location?.origin;
      const localObj = this.getCustomLocale(fallBackCCLCValue, pageConfig);
      const replaceObj = {
        '{cc}': localObj['cc'],
        '{lc}': localObj['lc'],
        '{host}': domainName
      };
      if(includes(link, '{cc}')|| includes(link, '{lc}') || includes(link, '{host}') ){
        const replacedDirectUrl = link.replace(/{cc}|{lc}|{host}/gi, function (matched) {
          return replaceObj[matched];
        });
        return replacedDirectUrl;
      }else {
        return `${replaceObj['{cc}']}-${replaceObj['{lc}']}${link}`
      }
    }
  }
  urlConverterExtrnlLink(link, fallBackCCLCValue, pageConfig){
    let url;
    if(includes(link, 'https://') && !includes(link, '{cc}') && !includes(link, '{lc}') && !includes(link, '{host}')){
      url = link;
    }  else {
      url = this.replaceStringInURL(link,fallBackCCLCValue, pageConfig);
    }

    return url;

  }

  getDevicesData(options:any = {}):Observable<AllDeviceData>{

    const anonymousRes = {data:{ customerState:'Anonymous' }};
    const errorRes = { error: null, dataLoded: false };

    const emitResponse = res => {
      if (this.pageConfig?.routeData?.skipDeviceCall) {
        this.devicesDataSub.next(res);
      }
    }
    this.store.dispatch(new AllDeviceAction.ClearAllDeviceData());
    this.store.dispatch(new AllDeviceAction.LoadAllDevice({options:options}));
    return this.store.select(allDeviceSelector)
    .pipe(
      map((res: any) => {
        if (!isEmpty(res) && res?.dataLoded) {
          if (res && res['data'] && res.code===200){
            if(res?.oldPayload?.options?.params?.startIndex === options?.params?.startIndex){
              emitResponse(res);
              return res;
            }
          } else {
            emitResponse(anonymousRes);
            return anonymousRes;
          }
        } else if(!isEmpty(res?.error)) {
          emitResponse(res?.error);
          errorRes.error = res?.error;
            return errorRes;
        }

      }, err=>{
        emitResponse(anonymousRes);
        return anonymousRes;
      })
  );
  }

  addParamsToUrl(urlString: string, params: any) {
    let url;
    const hasProtocol = urlString.startsWith('http');

    if (hasProtocol) {
      url = new URL(urlString);
    } else {
      url = new URL(urlString, this.pageConfig.domain);
    }

    for (let key in params) {
      url.searchParams.append(key, params[key]);
    }

    return hasProtocol ? url.href : url.pathname + url.search;
  }

  getOsTmsId() {
    if (typeof localStorage !== 'undefined') {
      const osInfo: any = JSON.parse(localStorage.getItem('osInfo'));

      if (osInfo && osInfo.osTmsId) {
        return osInfo.osTmsId;
      }
    }

    return null;
  }

  getNavigationUrl(path: string): string {
    if (path !== null && path !== "") {
      let formattedURL;
      const replaceObj = {
        "{host}": "",
        "{cc}": this.pageConfig.cc,
        "{lc}": this.pageConfig.lc,
      };
      formattedURL = path.replace(/{cc}|{lc}|{host}/gi, function (match) {
        return replaceObj[match];
      });
      return formattedURL;
    } else {
      return "";
    }
  }

  isDeviceInProfile(authData: any, payload: any) {
    return new Observable((observer: Observer<any>) => {
      const handleObserver = (response) => {
        observer.next(response);
        observer.complete();
      }

      if (!authData?.customerId) {
        handleObserver({success: false});
      }
      this.store.dispatch(new ConfirmDeviceAction.LoadConfirmDevice({...payload}));
      this.store.select(confirmDeviceSelector)
      .pipe(takeUntil(this.destroySubject$))
        .subscribe((res: any) => {
          if (res && res.dataLoded) {
            const deviceId = res?.data?.deviceId;
            const isDeviceInProfile = (res.code !== 204 && deviceId && deviceId !== '0')? true : false;
            const isDeviceClaimed = (res.code !== 204 && res?.data?.statusCode === 25 ) ? true : false;
            handleObserver({ success: true, ...cloneDeep(res), isDeviceInProfile, isDeviceClaimed })
          }
        }, err => {
          handleObserver({success: false});
        });
 
    })
  }
  checkDeviceAlreadyProfile(payloadDeviceArr, resoponseDeciceArr){

  }
  trackVideosViewed(id) {
    let videoIDArr:any =  JSON.parse(localStorage.getItem('trackVideoViewed')) || [];
    videoIDArr.push(id);
    localStorage.setItem('trackVideoViewed', JSON.stringify(videoIDArr));
  }

  trackDiagsDownloaded(id) {
    let videoIDArr:any =  JSON.parse(localStorage.getItem('trackDiagsDownloaded')) || [];
    videoIDArr.push(id);
    localStorage.setItem('trackDiagsDownloaded', JSON.stringify(videoIDArr));
  }

  getDefaultCCLC(){
    if(this.allCcLcAlias.hasOwnProperty(`${this.pageConfig.cc}-${this.pageConfig.lc}`)){
      return `${this.allCcLcAlias[`${this.pageConfig.cc}-${this.pageConfig.lc}`]['actualCc']}-${this.allCcLcAlias[`${this.pageConfig.cc}-${this.pageConfig.lc}`]['actualLc']}`
    } else {
      return `${this.pageConfig.cc}-${this.pageConfig.lc}`
    }
  }

  checkIfBot(){
    var botPattern = "(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
    var re = new RegExp(botPattern, 'i');
    if(navigator && navigator.userAgent){
      var userAgent = navigator?.userAgent;
      return re.test(userAgent);
    }
  }
  setLinkTarget(dataTarget){
    if(dataTarget==='newTab'){
      return '_blank'
    }else if(dataTarget==='self' || dataTarget ===''){
      return '_self'
    }
  }
  truncateText(str,limit) {
    return str.length > limit ? str.slice(0, limit - 1) + "..." : str;
  }

  getVertualLinkUrl(payload){
    const url = this.getSanitizedUrl(`wcc-services/methone/va-url?cc=${this.pageConfig.cc}&lc=${this.pageConfig.lc}`);
    return Observable.create(function (observer) {
      // here we are using xmlHttpRequest bucause angular doesn't support synchronous call in angular
      // reason for this call after getting response from service new tab popup getting blocked in browser.

        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = ()=> {
          let response ='';
          if (xhr.readyState == 4) {
            if (xhr.status == 200) {
              response = JSON.parse(xhr.response)
              observer.next(response);
            } else {
              observer.next(response)
              observer.error(xhr);
            }
          }
        };

      xhr.open("POST", url, false);
      xhr.send(JSON.stringify(payload));
      });
  }

  getPDPVirtualLinkUrl(payload){
    const url =`wcc-services/methone/va-url?cc=${this.pageConfig.cc}&lc=${this.pageConfig.lc}`;
    return this.http.post(url, payload);
  }

  // Virtual Agent Fallback senario
  getFallbackVirtualLinkUrl(chatUrl):string{
    if (!chatUrl) return;

    const config = this.pageConfig;
    const url = new URL(chatUrl);

    url.searchParams.set('Template', config.templateName);
    url.searchParams.set('query', '');
    url.searchParams.set('SerialNumber', '');
    url.searchParams.set('PL', '');
    url.searchParams.set('ProductNumber', '');
    url.searchParams.set('ProductName', '');
    url.searchParams.set('ProductNameOID', '');
    url.searchParams.set('ProductSeriesName', '');
    url.searchParams.set('ProductSeriesNameOID', '');
    url.searchParams.set('CC', config.cc);
    url.searchParams.set('LC', config.lc);

    if(config.pageName === 'printerLanding'){
      url.searchParams.set('BotSubClient', 'Landing');
      url.searchParams.set('BotSubClient', 'printer');
    }else if (config.pageName === 'computerLanding'){
      url.searchParams.set('BotSubClient', 'Landing');
      url.searchParams.set('producttype', 'pc');
    }else if(config.pageName === 'siteHome') {
      url.searchParams.set('BotSubClient', 'SiteHome');
    }else if(config.pageName === 'pdpLanding') {
      url.searchParams.set('BotSubClient', 'webwidget');
    }else if(config.pageName === 'productLanding') {
      url.searchParams.set('BotSubClient', 'webwidget');
    }else if(config.pageName === 'contact') {
      url.searchParams.set('BotSubClient', 'webwidget');
    }else if(config.pageName === 'deviceSearch') {
      url.searchParams.set('BotSubClient', 'webwidget');
    }else if(config.pageName === 'swdLanding' || config.pageName === 'swdClosure' || config.pageName === 'swdDrivers' || config.pageName === 'swdDriverDetails') {
      url.searchParams.set('BotSubClient', 'webwidget');
    }
    return url.href;
  }

getVaPayloadTemplate(initQuery?: string){
  return {
    "pageName":this.pageConfig.templateName,
        "product": {
            "productNumber":'',
            "productName":'',
            "productOID":'',
            "serialNumber":'',
            "productSeriesName":'',
            "productSeriesOID":'',
            "languageCode":this.pageConfig.lc,
            "countryCode":this.pageConfig.cc,
            "countryOfPurchase":'',
            "warrantyStartDate":'',
            "warrantyEndDate":'',
            "carePackEndDate":'',
          },
        "conversation": {
            "testTraffic": "",
            "clientIp":"",
            "originalQuery": "",
            "eventSource": "",
            "pageId": this.pageConfig.templateName,
            "initQuery": initQuery || ''
          }
    }
 }

 updateContactProductContext(productCtxt){
  const contactFormMetaData = sessionStorage?.getItem('contactFormMetaData') && JSON.parse(sessionStorage?.getItem('contactFormMetaData')) || {};
  if (contactFormMetaData) {
    if(!contactFormMetaData.contactProductContext){
      contactFormMetaData.contactProductContext={};
    }

    if (
      contactFormMetaData?.contactProductContext?.seriesOid !== productCtxt?.seriesOid ||
      contactFormMetaData?.contactProductContext?.modelOid !== productCtxt?.modelOid ||
      contactFormMetaData?.contactProductContext?.serialNumber !== productCtxt?.serialNumber
    ) {
      // clear form data if user has established a new product context
      sessionStorage?.removeItem('contactFormData');
    }
   contactFormMetaData.contactProductContext.sku = productCtxt?.sku;
   contactFormMetaData.contactProductContext.serialNumber = productCtxt?.serialNumber;
   contactFormMetaData.contactProductContext.seriesName = productCtxt?.seriesName;
   contactFormMetaData.contactProductContext.seriesOid = productCtxt?.seriesOid;
   contactFormMetaData.contactProductContext.modelOid = productCtxt?.modelOid;
  }
  sessionStorage?.setItem('contactFormMetaData', JSON.stringify(contactFormMetaData));
}

addProductContextToURL(link, specs: ProductSpecs) {
  link = this.localizeURL(link);
  if (link && specs) {
    link = link.includes('{ProductContext}') ? link.replace(/\/{ProductContext}/g, this.genarateProdContextUrl(specs,link)) : link;
    link = link.replace(/{SerialNumber}/g, specs.serialNumber || '');
    link = link.replace(/{pdpSource}/g, this.pageConfig?.routeParams?.supportCategory || 'details');
    link = link.replace(/{ProductNumber}/g, specs?.altProductNumber || specs.productNumber || '');
    link = link.replace(/{ProductName}/g, specs.productName || '');
    link = link.replace(/{ProductNameOID}/g, specs.productNameOid || '');
    link = link.replace(/{ProductSeriesName}/g, specs.productSeriesName || '');
    link = link.replace(/{ProductSeriesNameOID}/g, specs.productSeriesOid || '');

    return link;
  } else {
    return link || '';
  }
}

genarateProdContextUrl(specs: ProductSpecs, link = '') {
  if(!specs) return '';

  let url = new URL('', this.pageConfig.domain);
  const sku = specs?.altProductNumber || specs?.productNumber;
  specs?.productNumber && specs?.modelContext && url.searchParams.append('sku', sku);
  specs?.serialNumber && specs?.modelContext && url.searchParams.append('serialnumber', specs?.serialNumber);
  if(specs?.modelContext) {
    if(includes(link, 'drivers')){
      url.pathname = `${specs?.productSeriesSEOName}/model/${specs?.productNameOid}`;
    } else {
      url.pathname = `${specs?.productSeriesSEOName}/${specs.productSeriesOid}/model/${specs?.productNameOid}`;
    }
  } else if(specs?.productSeriesOid) {
    url.pathname = `${specs?.productSeriesSEOName}/${specs?.productSeriesOid}`;
  }
  return (url.pathname+ url.search);
}

getSscKeysForOneHp(sscKeys): string[]{
  let hpOneNames = [];
  const hpOnSscKeys = sscKeys?.CONTACT_SUBSCRIPTION_LOGO_DISPLAY?.keyValue;
      if(hpOnSscKeys && !isEmpty(hpOnSscKeys)) {
          const hpOneKeyValue = JSON.parse(hpOnSscKeys);
          for (const oneHpName in hpOneKeyValue) {
              if(Boolean(hpOneKeyValue[oneHpName])) {
                  hpOneNames.push(oneHpName);
              }
          }
      } else {
          hpOneNames = [];
      }
      return hpOneNames;
}
  getBookmarkUrl(url: string, index: number) {
    let start: number = url.split('/', index).join('/').length;
    return url.substring(start + 1, url.length);
  }

  containsNonEnglishCharacters(value : string) : boolean{
    if(value && /^[a-zA-Z0-9-\s]+$/.test(value)){
      return false;
    }
    return true;
  }

  isElementInViewport(elm) {
    return new Observable((observer: Observer<any>) => {
      let counter = 0;
      function callback(entries, observeelm) {
        entries.forEach((entry) => {
          if(entry.isIntersecting) {
            if(counter > 1){
              //stop observering when element is within viewport with 2 confirmations
              observeelm.unobserve(elm);
              observer.complete();
            } else {
              observer.next(true);
            }
            counter++;
          } else {
            observer.next(false);
          }
       });
      }

      const options = {
        root: null,
        threshold: 0
      };
      if(elm) {
        const elmObserver = new IntersectionObserver(callback, options);
        elmObserver.observe(elm);
      } else {
        observer.next(false);
      }
    });
  }

  getTranslatedCountryListData(skipLoadingScreen=false): Observable<CountryList[]>{

    let headers: HttpHeaders = new HttpHeaders();
    if(skipLoadingScreen) headers = headers.append('skipLoadingScreen', 'true');
    
    return this.http.get<any>(`wcc-services/productdata/translated/countrylist/${this.pageConfig.locale || 'us-en'}`, { headers }).pipe(
      map(resp => {
        if(resp && resp?.statusCode === 200 && resp?.data){
          return this.modifyTranslatedCountryListApi(resp?.data);
        }else{
          throwError({});
        }
      })
    );
  }

  private modifyTranslatedCountryListApi(countryList:CountryList[]):CountryList[]{
    return countryList.map(country => {
      country['alternativeName'] = country?.countryTranslatedName?.[0].tmsName ?? country.countryName;
      return country;
    })
  }

  //chat bot triggered for document page
  triggeredChatBot() {
    this.ChatBotTriggeredSubject.next();
  }
  //update sisplay name for typeahead
  filterTypeaheadData(masterArray, threshHold){
    return filter(
      masterArray, o => {
        if(o.matchScore >= threshHold){
          if(o?.btoFlag){
            o.name = `${o?.description} ${o?.name}`;//Fix for #SSVSPRT-12127 - Type ahead enclosed with paranthesis// update name gor bTO product // update name gor bTO product for SSVSPRT -8063
          }
          return o;
        }
      }  );
  }
  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
