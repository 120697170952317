import { Action, createAction } from "@ngrx/store";
export const warrantySpecificationData = createAction('get warranty details');

export enum WarrantySpecificationDetailsActionType{
    LoadWarrantyDetails = '[contact product Ribbon] load',
    LoadWarrantyDetailsSuccess = '[contact product Ribbon] load  success',
    LoadWarrantyDetailsFailure = '[contact product Ribbon] load failure',
    SaveWarrantyDetailsPayload = 'Save contact product ribbon payload',
    LoadingWarrantyDetailsData = 'Warranty Specs loading Started',
    ClearWarrantyDetailsData = 'Remove contact product ribbon Data'
}

export class LoadWarrantyDetails implements Action{
    readonly type = WarrantySpecificationDetailsActionType.LoadWarrantyDetails;
    constructor(public payload:any){};
}
export class LoadWarrantyDetailsSuccess implements Action{
    readonly type = WarrantySpecificationDetailsActionType.LoadWarrantyDetailsSuccess;
    constructor(public data:any){};
}
export class LoadWarrantyDetailsFailure implements Action{
    readonly type = WarrantySpecificationDetailsActionType.LoadWarrantyDetailsFailure;
    constructor(public data:any){};
}
export class ClearWarrantyDetailsData implements Action{
    readonly type = WarrantySpecificationDetailsActionType.ClearWarrantyDetailsData;
    constructor(){};
}
export class LoadingWarrantyDetailsData implements Action{
    readonly type = WarrantySpecificationDetailsActionType.LoadingWarrantyDetailsData;
    constructor(){};
}

export type WarrantySpecificationDetailsAction = LoadWarrantyDetails | LoadWarrantyDetailsSuccess | LoadWarrantyDetailsFailure | ClearWarrantyDetailsData | LoadingWarrantyDetailsData;
