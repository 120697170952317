import { Action } from "@ngrx/store";

export enum ConfirmDeviceActionType{
    loadConfirmDevice = '[Confirm Device device api] load',
    loadConfirmDeviceSuccess = '[Confirm Device device api] success',
    loadConfirmDeviceFailure = '[Confirm Device device api] failure',
}
export class LoadConfirmDevice implements Action{
    readonly type = ConfirmDeviceActionType.loadConfirmDevice;
    constructor(public payload:{}){};
}
export class LoadConfirmDeviceSuccess implements Action{
    readonly type = ConfirmDeviceActionType.loadConfirmDeviceSuccess;
    constructor(public data:{}){};
}
export class LoadConfirmDeviceFailure implements Action{
    readonly type = ConfirmDeviceActionType.loadConfirmDeviceFailure;
    constructor(public error:{}){};
}

export type ConfirmDeviceAction = LoadConfirmDevice | LoadConfirmDeviceSuccess | LoadConfirmDeviceFailure;

