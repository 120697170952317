import { Action } from "@ngrx/store";

export enum DeviceWarrantyActionType{
    DeviceWarranty = '[device warranty api] load',
    DeviceWarrantySuccess = '[device warranty api] success',
    DeviceWarrantyFailure = '[device warranty api] failure',
}
export class LoadDeviceWarranty implements Action{
    readonly type = DeviceWarrantyActionType.DeviceWarranty;
    constructor(public payload:any){};
}
export class LoadDeviceWarrantySuccess implements Action{
    readonly type = DeviceWarrantyActionType.DeviceWarrantySuccess;
    constructor(public data:any){};
}
export class LoadDeviceWarrantyFailure implements Action{
    readonly type = DeviceWarrantyActionType.DeviceWarrantyFailure;
    constructor(public error:any){};
}

export type DeviceWarrantyAction = LoadDeviceWarranty | LoadDeviceWarrantySuccess | LoadDeviceWarrantyFailure;

