import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// Root provider is required
@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  constructor( @Inject(DOCUMENT) private document) { }
  public loadScript(scriptSrcArray, callback = ()=>{}) {
    if(typeof this.document!==undefined){
      var isFound = false;
      var scripts = this.document.getElementsByTagName("script")
      for (var i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
          isFound = true;
        }
      }
      if (!isFound) {
        var dynamicScripts = scriptSrcArray;
        for (var i = 0; i < dynamicScripts.length; i++) {
          let node = this.document.createElement('script');
          node.src = dynamicScripts[i];
          node.setAttribute('defer','');
          node.type = 'text/javascript';
          node.addEventListener('load', callback);
          this.document.getElementsByTagName('body')[0].appendChild(node);
        }
      }else{
        callback();
      }
    }
  }
}