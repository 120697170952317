import { Directive, ElementRef, OnInit } from "@angular/core";
import { SvgVaultService } from "./svg-vault.service";


@Directive({
    selector:".svg-vault"
})
export class SvgVaultDirective implements OnInit{
    constructor(
        private el: ElementRef,
        private svgVaultService: SvgVaultService
    ){}

    ngOnInit(): void {
        const tag = this.el.nativeElement as HTMLElement;
        const link = tag?.getAttribute('data-fallback')||null;
        this.svgVaultService.renderTag(tag, link);
    }
}