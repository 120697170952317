import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Observer, Subject } from 'rxjs';
import { first} from 'rxjs/operators';
import { AppState } from '../../../store/app.reducer';
import { isEmpty } from "lodash-es";
import { ConfigKeysModel } from '../../../services/ssc-store/sscKeys.reducer';
import { sscKeysSelector } from '../../../services/ssc-store/config.selectors';

// Aleready Provided in Component
@Injectable()

export class HpFooterService implements OnDestroy{
  private destroySubject$: Subject<void> = new Subject();

  constructor(
    private store: Store<AppState>
  ) {}

  
  getFooterSSC() {
    return new Observable((observer: Observer<any>) => {
      this.store.select(sscKeysSelector)
      .pipe(first(res=> !isEmpty(res)))
      .subscribe((sscKeys: ConfigKeysModel) => {
          let displayCcLcSelector, disableCcLcSelector;
            if(sscKeys.CLC_DISABLED && sscKeys.CLC_DISABLED.keyValue){
              displayCcLcSelector = sscKeys.CLC_DISABLED.keyValue;
              disableCcLcSelector = sscKeys.SDP_HPONE_PCAAS_PAAS_SERIESOID?.keyValue;
            }else {
              displayCcLcSelector = '';
            }
            observer.next({ success: true, displayCcLcSelector, disableCcLcSelector });
            observer.complete();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
  }
  
}
