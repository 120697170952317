import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpService } from '../../../services/service_loader/http.service';
import { PageService } from '../../../services/common_service/page.service';
import { AppSelectorStore } from '../../../store/app.selector';
import { TermbaseKeysModel } from '../../../store/termbase-store/termbase.reducer';

@Component({
  selector: 'app-helpful-links',
  templateUrl: './helpful-links.component.html'
})

export class HelpfulLinksComponent implements OnInit, OnDestroy {
  // Page related config
  pageConfig: any = this.pageService.config;

  // Translations
  allTranslations:TermbaseKeysModel={};

  helpfulLinks;
  destroySubject$: Subject<void> = new Subject();

  constructor(
    private pageService: PageService,
    private http: HttpService,
    private router: Router,
    private appSelectorStore:AppSelectorStore
  ) {}

  ngOnInit() {
    
    this.appSelectorStore.getAllTranslations$().pipe(takeUntil(this.destroySubject$)).subscribe(res=>this.allTranslations=res);
      
    this.gethelpfulLinks();
  }

  gethelpfulLinks() {
    this.http
      .get('/wcc-assets/data/helpful-links.json')
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
        this.helpfulLinks = res;
      });
  }

  navigateToUrl(url) {
    if (url) {
      url = `${this.pageConfig.cc}-${this.pageConfig.lc}${url}`;
      this.router.navigate([url]);
    }
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }
}