import { Component, OnDestroy, OnInit } from '@angular/core';
import { HpFooterService } from './hp-footer.service';
import {  Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageService } from '../../../services/common_service/page.service';
@Component({
  selector: 'app-hp-footer',
  templateUrl: './hp-footer.component.html',
  providers:[HpFooterService]
})
export class HpFooterComponent implements OnInit, OnDestroy {
  pageConfig: any = this.pageService.config;
  private destroySubject$: Subject<void> = new Subject();
  disableCCLC:boolean;
  constructor(
    private hpFooterService: HpFooterService,
    private pageService: PageService
    ) {}
  
  ngOnInit() {
    this.hpFooterService.getFooterSSC()
    .pipe(takeUntil(this.destroySubject$))
    .subscribe((res: any) => {
      if (res && res.success) {
        const disableCcLcSelector = res?.disableCcLcSelector || "";
        this.disableCCLC = res.displayCcLcSelector.indexOf(this.pageConfig.templateName)>-1;

        this.subscribeToTemplateNameChange(res.displayCcLcSelector, disableCcLcSelector);
      }
    });
  }

  subscribeToTemplateNameChange(displayCcLcSelector, disableCcLcSelector){
    this.pageService.getRoutedataSub().pipe(takeUntil(this.destroySubject$)).subscribe(routeData=>{
      const seriesId = routeData?.params?.seriesId;
      const templateName = routeData?.data?.templateName;
      if(seriesId && templateName){
        this.disableCCLC = displayCcLcSelector.indexOf(templateName)>-1 || disableCcLcSelector?.indexOf(seriesId) > -1;
      }
    });
  }

  ngOnDestroy(): void {
      this.destroySubject$.next();
  }
}

