import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { PageService } from "../../services/common_service/page.service";
import { MastiffActionType, StoreMastiffOffers } from "./mastiff-offers.actions";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { MastiffOffersResponse } from "./mastiff-offers.reducer";
import { MastiffOffersPayload } from "./mastiff-offers.model";



@Injectable()
export class MastiffOffersEffect {

  pageConfig = this.pageService.config;

  constructor(
    private actions: Actions,
    private http: HttpClient,
    private pageService: PageService
  ) { }

  GetMastiffOffersData = createEffect(() => {
    return this.actions.pipe(
      ofType(MastiffActionType.GetMastiffOffersData),
      mergeMap((action: { payload: MastiffOffersPayload }) => {
        const renderMode = this.pageConfig.queryParams['render_mode'] === 'debug' && '&render_mode=debug' || '';
        const payload = Object.assign({}, action.payload);
        const pageId = payload?.pageId || '';
        delete payload?.pageId;

        const url = `/wcc-services/pdp/${this.pageConfig.locale}/offers?pageId=${pageId}${renderMode}`
        return this.http.post(url, payload)
          .pipe(
            map((response: MastiffOffersResponse) => {
              if (response?.code === 200 && response?.data?.length) {
                    let data = response?.data;
                    return StoreMastiffOffers({ offers: data });
                  } else {
                    return StoreMastiffOffers({ offers: [] });
                  }
                }),
            catchError(error => {
              return of(StoreMastiffOffers({ offers: [] }));
            })
          )
      })
    )
  })
}
