import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { ConfigKeysModel } from "../ssc-store/sscKeys.reducer";
import { includes } from "lodash-es";
import { ScriptLoaderService } from "../script_loader/scriptLoader.service";
import { filter, first, map } from "rxjs/operators";
import { BehaviorSubject, Observable, forkJoin } from "rxjs";
import { AppSelectorStore } from "../../store/app.selector";

// Root provider is required
@Injectable({
  providedIn: "root",
})
export class UdlLogService{
	private window: Window;

	// true === udl enabled
	private udlGlobalTemplateSwitch:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private udlScriptLoadComplete:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private externalUDLScript = [
    '/wcc-assets/content/dam/hp-wcc/libs/scripts/udl_analytics.js'
  ];

  	constructor(
		private scriptLoader: ScriptLoaderService,
		private appSelectorStore: AppSelectorStore,
    @Inject(DOCUMENT) private document: Document
  	) {
		this.window = this.document.defaultView;
  	}

	logEvent(key:string):void{
		// console.log(`UDL: log (${key}) START`);
		this.canLogEvents().subscribe(res=>{
			this.checkUdlTemplateEnabled(key).subscribe(res=>{
				this.appSelectorStore.getUdlKeys$().subscribe((udlKeys:ConfigKeysModel)=>{
					if(this.checkForSolutionPrefix(key)){
						this.logPrefixObjects(udlKeys, key, 'ContactHP-Solution_contact_topic_');
					}else if(this.checkForServicesVersionPrefix(key)){
						this.logPrefixObjects(udlKeys, key, 'ContactHP-services-');
					}else{
						if(udlKeys[key] && udlKeys[key].keyValue){
							this.logFinalObject(this.createLogObject(udlKeys[key].keyValue));
						}
					}
				});
			});
		});

	}

	private logPrefixObjects(udlKeys:ConfigKeysModel, logKey:string, prefixKey:string){
		if(udlKeys[prefixKey+'####'] && udlKeys[prefixKey+'####'].keyValue){
			const logObject = this.createLogObject(udlKeys[prefixKey+'####'].keyValue);
			logObject.pageNameL8 = logObject.pageNameL8.replace('####', logKey.replace(prefixKey, ''));
			this.logFinalObject(logObject);
		}
	}

	private checkForSolutionPrefix(key:string):boolean{
		return /^ContactHP-Solution_contact_topic_/.test(key);
	}

	private checkForServicesVersionPrefix(key:string):boolean{
		return /^ContactHP-services-/.test(key);
	}

	private logFinalObject(logObject){
		let currEvent = this.findExistingEventLog();
		if(currEvent){
			currEvent.pageNameL5 = logObject.pageNameL5;
			currEvent.pageNameL6 = logObject.pageNameL6;
			currEvent.pageNameL7 = logObject.pageNameL7;
			currEvent.pageNameL8 = logObject.pageNameL8;
		}else{
			this.window['dataLayer'] = this.window['dataLayer'] || [];
			this.window['dataLayer'].push(logObject);
		}
		// console.log(`UDL: log (${JSON.stringify(logObject)}) DONE`);
	}


	private createLogObject(value:string){
		const level:string[] = value.split(':');
		return{
			event: 'e_pageView',
			pageNameL5: level[0],
			pageNameL6: level[1],
			pageNameL7: level[2],
			pageNameL8: level[3]
		};
	}

	private findExistingEventLog(){
		if(this.window['dataLayer']){
			return this.window['dataLayer'].find(val=>{
				return val.event==='e_pageView' && val.pageNameL5!==undefined;
			}) || null;
		}
		return null;
	}

	private findExistingLoginLog(){
		if(this.window['dataLayer']){
			return this.window['dataLayer'].find(val=>{
				return val.event==='e_userLogin' && val.loginStatus!==undefined;
			}) || null;
		}
		return null;
	}

	logLoginStatus(status:boolean):void{
		// console.log(`UDL: log Login Status ${status} START`);	
		this.canLogEvents().subscribe(res=>{
			// console.log(`UDL: log Login Status ${status} DONE`);
			let currEvent = this.findExistingLoginLog();
			if(currEvent){
				currEvent.loginStatus = status;
			}else{
				this.window['dataLayer'] = this.window['dataLayer'] || [];
				this.window['dataLayer'].push({
					event: 'e_userLogin',
					loginStatus: status
				});
			}
		});
	}

	private checkUdlTemplateEnabled(templateName:string):Observable<boolean>{
		return this.appSelectorStore.getSscKey$(['UDL_DISABLED_TEMPLATE']).pipe(
			map((sscKeys:{[key:string]:string}):boolean=>{
				const {UDL_DISABLED_TEMPLATE} = sscKeys;
				return includes(UDL_DISABLED_TEMPLATE, templateName)?false:true;
			}),
			filter((value:boolean):boolean=>value)
		);
	}

	private canLogEvents():Observable<[boolean, boolean]>{
		return forkJoin([
			this.udlGlobalTemplateSwitch.pipe(
				filter(value=>value),
				first()
			),
			this.udlScriptLoadComplete.pipe(
				filter(value=>value),
				first(),
			)
		])
	}

	initiateUdlScript(){
		// console.log('UDL: script loading START');
		this.appSelectorStore.getSscKey$(['UDL_KILL_SWITCH'], false).subscribe((sscKeys:{[key:string]:string})=>{
			const {UDL_KILL_SWITCH} = sscKeys;

			if (UDL_KILL_SWITCH!=="false") {
				this.udlGlobalTemplateSwitch.next(true);
				// console.log('UDL: global switch is ON');
				this.scriptLoader.loadScript(this.externalUDLScript, ()=>{
					// console.log('UDL: script loading DONE');
					this.udlScriptLoadComplete.next(true);
				});
			}else{
				this.udlGlobalTemplateSwitch.next(false);
				// console.log('UDL: global switch in OFF');
			}

		});
	}
  
}