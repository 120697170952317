import { Injectable, RendererFactory2, ViewEncapsulation, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";

// Root provider is required
@Injectable({
    providedIn: 'root'
  })
export class LinkService {
removeTag(attr = null) {
    try {
        const renderer = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });

        let links = this.document.querySelector('[rel="canonical"]');

        if(attr?.href) {
            links = this.document.querySelector(`[href="${attr?.href}"]`);
        }
        
        const head = this.document.head;

        if (head === null) {
            throw new Error('<head> not found within DOCUMENT.');
        }
        if (links) {
            renderer.removeChild(head, links);
        }
    } catch (e) {
        console.error('Error within linkService: ', e);
    }
}

constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
) {}

/**
 * Inject the State into the bottom of the <head>
 */
addTag(tag: LinkDefinition, tagTobeAppended=null) {

    try {
        const renderer = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });

        const link = renderer.createElement('link');
        const head = this.document.head;
        // const selector = this._parseSelector(tag);

        if (head === null) {
            throw new Error('<head> not found within DOCUMENT.');
        }

        Object.keys(tag).forEach((prop: string) => {
            return renderer.setAttribute(link, prop, tag[prop]);
        });

        // [TODO]: get them to update the existing one (if it exists) ?
        !tagTobeAppended ? renderer.appendChild(head, link) : renderer.appendChild(tagTobeAppended, link);

    } catch (e) {
        console.error('Error within linkService : ', e);
    }
}


updateTag(tag: LinkDefinition) {
    try {
        const renderer = this.rendererFactory.createRenderer(this.document, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });
        let links;
        if(tag.rel==='alternate' && tag.hreflang){
            links = this.document.querySelector(`[rel="alternate"][hreflang="${tag.hreflang}"]`);
        }else{
            links = this.document.querySelector('[rel="canonical"]');
        }
        const head = this.document.head;

        if (head === null) {
            throw new Error('<head> not found within DOCUMENT.');
        }
        if (links) {
            renderer.removeChild(head, links);
        }
        if(tag.href.includes('?openCLC=true')){
            tag.href = tag.href.replace('?openCLC=true', '');
        }
        this.addTag(tag);

    } catch (e) {
        console.error('Error within linkService: ', e);
    }
}

removeHreflangTag() {
  try {
    const links = this.document.querySelectorAll(`[rel="alternate"][hreflang]`);
    links?.forEach(e => e?.remove());
  } catch (e) {
      console.error('Error removing link tags: ', e);
  }
}

tagExist(href: string) {
    if(href) {
        return this.document.querySelector(`[href="${href}"]`)?.length > 0 ? true : false;
    }

    return false
} 

// private _parseSelector(tag: LinkDefinition): string {
//     // Possibly re-work this
//     const attr: string = tag.rel ? 'rel' : 'hreflang';
//     return `${attr}="${tag[attr]}"`;
// }
}

 export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
  as?: string;
  onload?: string;
} & {
    [prop: string]: string;
};

