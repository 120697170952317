<div class="section-wrapper country-selector">
  <div class="countrySelectorWrapper">
    <div class="row selector-row">
      <div class="p0">

        <!-- clc selector desktop -->
        <ng-template #content let-c="close" let-d="dismiss" tabindex="0">
          <div class="modal-header">
            <h3 class="modal-title" id="select-country-header">{{
              allTranslations["#pps_WCC_FS_HomeHP_CLC_Modal_Title_Text"] }}</h3>
            <button (click)="d('Cross click')" class="close-btn" aria-label="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0817 12.7069L5.7349 19.0535C5.53963 19.2488 5.22305 19.2488 5.02779 19.0535C4.83253 18.8583 4.83253 18.5417 5.0278 18.3464L11.3746 11.9997L5.02847 5.65355C4.83321 5.45829 4.83321 5.14171 5.02847 4.94645C5.22374 4.75119 5.54032 4.75119 5.73558 4.94645L12.0817 11.2926L18.428 4.94643C18.6233 4.75117 18.9398 4.75117 19.1351 4.94644C19.3304 5.1417 19.3304 5.45828 19.1351 5.65354L12.7888 11.9998L19.1346 18.3457C19.3299 18.5409 19.3299 18.8575 19.1346 19.0528C18.9393 19.248 18.6228 19.248 18.4275 19.0528L12.0817 12.7069Z" fill="#212121"/>
                </svg>
              </button>
          </div>
          <div
            [ngClass]="pageConfig.isTablet ? 'tablet modal-body secondary-content' : 'nonTablet modal-body secondary-content'">
            <div *ngIf="userData" id="clc-table-container">
              <!-- <div *ngFor="let clcData of userData"> -->
              <div *ngFor="let clcData of userData" class="country-group"
                id="{{(setClassIdName|memoize:clcData.regionTranslation:'divId')}}">
                <h4 class="countryHeader">{{ allTranslations[clcData.regionName] }}</h4>
                <div class="hp-row">
                  <ul *ngIf="userData" id="{{(setClassIdName|memoize:clcData.regionTranslation:'ulId')}}"
                    class="{{(setClassIdName|memoize:clcData.regionTranslation:'ulClass')}}">
                    <!-- code to display flag beside country name -->
                    <!-- <span class="flag {{data.countryInternationalName.replace(' ','-')}} countryFlag" 
                    attr.alt='{{data.countryName}}'
                    *ngIf="data.showFlag == true"></span> -->
                    <li *ngFor="let data of clcData?.country">
                      <a href="javascript:void(0);" attr.aria-label='{{data.countryName}}'
                        (click)="onClickCountry($event)"
                        [ngClass]="(data.showFlag == true) ? 'clcCountriesName' : 'clcCountriesName noFlagImg'"
                        attr.rel='{{data.countryCode}}'
                        attr.data-languageCode='{{data.languageCode}}'>{{data.countryName}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <!-- code to display selected country -->
        <div id="clc-selector" [ngClass]="{'contact-page': pageConfig.pageName === 'contact'}">
          <span class="clc-header" id="country-header">{{ allTranslations["#pps_WCC_FS_HomeHP_CLC_Country_Text"]
            }}:</span>
          <a href="javascript:void(0)" (click)="open()"
            [title]="(setTitle|memoize:allTranslations['#pps_WCC_FS_HomeHP_CLC_Country_Text']:userCountryData?.countryName)"
            class="country-selector">
            <img class="country-flag" aria-hidden="true" *ngIf="(showCountryFlag|memoize:userCountryData?.showFlag:pageConfig.lc)" alt="Flag" [src]="flagImg">
            <span class="country-name" aria-hidden="true">{{userCountryData?.countryName}}</span>
          </a>
        </div>

        <!-- clc selector mobile -->
        <ng-template #countrySelectorMobile let-c="close" let-d="dismiss">
          <div class="clc-wrapper" [ngClass]="pageConfig.languageDirection === 'rtl' ? 'rtl' : ''">
            <div class="modal-header" >
              <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">
                  <img class="close-image" src="/wcc-assets/images/close-icon.svg" alt="close button" />
                </span>
              </button>
              <h3 class="modal-title" id="select-country-header">
                {{ allTranslations["#pps_WCC_FS_HomeHP_CLC_Modal_Title_Text"] }}
              </h3>
            </div>
            <div class="modal-body">
              <div class="country-selector-mobile">
                <div class="accordion">
                  <div *ngFor="let clcData of userData let i= index" class="accordion-item country-group-{{i}}"
                    (click)="accordianMobile(i)">
                    <h3 class="countryHeader title">
                      <span class="title-text">{{ allTranslations[clcData.regionName] }}</span>
                      <span class="accordian-arrow-icon">
                        <span class="svg-vault svg-chevron-right arrow-down">
                        </span>
                      </span>
                    </h3>
                    <ul *ngIf="userData" id="" class="country-list">
                      <li *ngFor="let data of clcData?.country">
                        <a href="javascript:void(0);" attr.aria-label='{{data.countryName}}' (click)="onClickCountry($event)"
                          [ngClass]="(data.showFlag == true) ? 'clcCountriesName' : 'clcCountriesName noFlagImg'"
                          attr.rel='{{data.countryCode}}' attr.data-languageCode='{{data.languageCode}}'>{{data.countryName}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </ng-template>
      </div>
    </div>
  </div>
</div>