import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, throwError } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import { PageService } from '../../services/common_service/page.service';
import { HttpService } from '../../services/service_loader/http.service';
import * as WarrantySpecificationDetailsAction from "./specs.actions";
import { AppState } from '../app.reducer';
import { isEmpty, cloneDeep } from "lodash-es";
import { Device, WarrantySpecsDataEntity, Entity, ProductSpecs, SubscriptionsEntity, Warranty } from '../../model/warranty-spec.model';
import { HttpHeaders } from '@angular/common/http';
import { AppConstants, POLY_PROD_CATEGORY, SUBSCRIBED_WARRANTY_TYPE, syncApiError } from '../../constants/app-constants';
import { sscKeysSelector } from '../../services/ssc-store/config.selectors';
import { CommonService } from '../../services/common_service/common.service';
import { ConfigKeysModel } from '../../services/ssc-store/sscKeys.reducer';
import { SpecsLoadingSelector } from './specs.selectors';
import { GlobalDataLayerService } from '../../services/common_service/globalDataLayer.service';

@Injectable()

export class SpecsEffects {
    pageConfig: any = this.pageService.config;
    loading = false;

    constructor(
        private actions$: Actions,
        private http: HttpService,
        private pageService: PageService,
        private store: Store<AppState>,
        private commonService: CommonService,
        private globalDataService: GlobalDataLayerService,
    ) { }

    getProductRibbonData = createEffect(
        () =>
            this.actions$.pipe(
                ofType(WarrantySpecificationDetailsAction.WarrantySpecificationDetailsActionType.LoadWarrantyDetails),
                concatLatestFrom(() => [
                    this.store.select(SpecsLoadingSelector),
                    this.store.select(sscKeysSelector)
                ]),
                mergeMap(([action, loading, sscKeys]: [any, boolean, ConfigKeysModel]) => {

                    if (loading) {
                        return of(new WarrantySpecificationDetailsAction.ClearWarrantyDetailsData())
                    };

                    const savePayload = cloneDeep(action?.payload);
                    delete savePayload['captchaToken'];
                    let oneHpNames = [];
                    if (!isEmpty(sscKeys)) {
                        oneHpNames = this.commonService.getSscKeysForOneHp(sscKeys);
                    }

                    let specsUrl = AppConstants.warrantySpecsURL;
                    let headers = new HttpHeaders();
                    
                    if (action?.payload?.devices?.[0]?.serialNumber) {
                        headers = headers.set(syncApiError.key, syncApiError.value);
                    }
                    
                    if (!(action?.payload?.captchaToken && action?.payload?.captchaToken !== '')) {
                        specsUrl += '?cache=true';
                    }
                    this.store.dispatch(new WarrantySpecificationDetailsAction.LoadingWarrantyDetailsData());
                    return this.http
                        .post(specsUrl, action?.payload, { headers })
                        .pipe(
                            shareReplay(),
                            map((devicesData: any) => {
                                const polyProductLineCodes = sscKeys?.CONTACT_POLY_PRODUCT_LINE?.keyValue;
                                this.attachOneHpFlag(devicesData?.data, oneHpNames, polyProductLineCodes);
                                const warrantyStatusDetails = {
                                    code: devicesData?.code,
                                    status: devicesData?.status,
                                    data: devicesData?.data,
                                }

                                if (warrantyStatusDetails?.data?.devices?.length) {
                                    const webReleaseDate = warrantyStatusDetails?.data?.devices[0]?.productSpecs?.webReleaseDate || '';
                                    const transformedDate = this.commonService.getFormattedWebReleaseDate(webReleaseDate);
                                    const gdlUpdate = {
                                        'Product': {
                                          'webReleaseDate': transformedDate,
                                       }
                                      };
                                    this.globalDataService.updateGDL(gdlUpdate, true);
                                }

                                return new WarrantySpecificationDetailsAction.LoadWarrantyDetailsSuccess({ ...warrantyStatusDetails, apiPayload: savePayload, date: new Date().toISOString().slice(0, 10), error: null })
                            }),
                            catchError(error => {
                                if (error?.syncError) {
                                    return throwError(error);
                                } else {
                                    return of(new WarrantySpecificationDetailsAction.LoadWarrantyDetailsFailure({
                                        error: error
                                    }))
                                }
                            })

                        )

                }),
            )
    );

    attachOneHpFlag(res: WarrantySpecsDataEntity, hpOneNames: string[], polyProductLineCodes): WarrantySpecsDataEntity {
        const devices = res?.devices || [];
        if (!devices.length) return;
        devices.forEach((device: Device) => {
            device?.warranty && delete Object.assign(device?.warranty, { ["warrantyDataStatus"]: device?.warranty.status })?.["status"];
            device?.productSpecs && delete Object.assign(device?.productSpecs, { ["ProductDataStatus"]: device?.productSpecs?.status })?.["status"];
            device.warranty = { ...device?.warranty, ...device?.warranty?.data };
            device.productSpecs = { ...device?.productSpecs, ...device?.productSpecs?.data };
            device.productSpecs = this.transformSpecs(device?.productSpecs, device?.warranty, polyProductLineCodes);
            device.warranty = this.transformWarranty(device?.warranty);
            delete device?.warranty?.data;
            delete device?.productSpecs?.data;
            const warranty = device?.warranty;
            const isOneHpWarranty = warranty?.warrantyType?.toString() === SUBSCRIBED_WARRANTY_TYPE;
            const subscriptions = warranty?.subscriptions || [];
            if ((!subscriptions.length && isOneHpWarranty) || !isOneHpWarranty) return;
            subscriptions.forEach((subscription: SubscriptionsEntity) => {
                const entities = subscription?.entities || [];
                if (!entities.length) return;
                entities.forEach((entity: Entity) => {
                    if (entity && entity?.name) {
                        if (!hpOneNames.length) {
                            entity["isOneHp"] = false;
                        } else {
                            const isOneHp = hpOneNames.some((name) => entity.name === name);
                            entity["isOneHp"] = isOneHp;
                        }
                    }
                });
            });
        })
        return { ...res };
    }

    transformSpecs(productSpecs: ProductSpecs, warranty: Warranty, polyProductLineCodes): ProductSpecs {
        if (!productSpecs) return;

        return {
            ...productSpecs,
            productSeriesName: productSpecs.productSeriesName || productSpecs.productName,
            productName: productSpecs.productName || productSpecs.productSeriesName,
            productNameDisplay: productSpecs?.seriesContext ? productSpecs?.productSeriesName : productSpecs.productName,
            serialNumber: warranty?.serialNumber || productSpecs.serialNumber,
            productNumber: (warranty?.productNumber || productSpecs?.productNumber)?.split('#')?.shift(),
            productLineCode: warranty?.productLineCode || productSpecs?.productLineCode,
            altProductNumber: (warranty?.altProductNumber || productSpecs?.altProductNumber)?.split('#')?.shift(),
            prodCategory: this.isPolyProduct(polyProductLineCodes, warranty?.productLineCode || productSpecs?.productLineCode) ? POLY_PROD_CATEGORY : productSpecs?.prodCategory,
            isRetiredProduct: (productSpecs.modelContext === true) ? productSpecs.modelLevelHistorical : productSpecs.seriesLevelHistorical
        }
    }

    transformWarranty(warranty: Warranty): Warranty {
        if(!warranty || isEmpty(warranty)) return warranty;
    
        return {
            ...warranty,
            productNumber: warranty?.productNumber?.split('#')?.shift(),
            altProductNumber: warranty?.altProductNumber?.split('#')?.shift(),
        }
    }

    isPolyProduct(polyProductLineCodes: string, productLineCode: string) {
        return polyProductLineCodes?.toLowerCase().includes(productLineCode?.toLowerCase())
    }
}
