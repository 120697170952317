import { SearchResultsResponse } from './search-results.model';
import { SearchResultsActions, SearchResultsActionTypes, SearchResultsPayload } from './search-results.actions';

export interface SearchResultsState {
    searchResults: SearchResultsResponse;
    loading: boolean;
    error: any | null;
    payload: SearchResultsPayload | null;
}

const initialState: SearchResultsState = {
    searchResults: null,
    loading: false,
    error: null,
    payload: null
};

export function searchResultsReducer(state = initialState, action: SearchResultsActions): SearchResultsState {
    switch (action.type) {
        case SearchResultsActionTypes.LoadSearchResults:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SearchResultsActionTypes.LoadSearchResultsSuccess:
            return {
                ...state,
                searchResults: action.response,
                payload: action.payload,
                loading: false,
                error: null,
            };
        case SearchResultsActionTypes.LoadSearchResultsFailure:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        // Add more cases for other actions here
        default:
            return state;
    }
}
