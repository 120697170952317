import { FetchingSscKeysFailedAction, FetchingSscKeysSuccessAction, SscKeysActions, SscKeysActionTypes } from "./sscKeys.actions";

export interface ConfigKeysModel{
    [key: string]: {
        keyDesc: string,
        keyValue: string
    }
}

export interface PageKeys{
    ssc: ConfigKeysModel;
    udl: ConfigKeysModel;
}
export interface SscKeysState{
    loading: boolean;
    dataFetchSuccess: boolean;
    SscUdlKeySet: {
        [key: string]: PageKeys;
    },
    pageName: string;
}

const initialState: SscKeysState = {
    loading: false,
    dataFetchSuccess: false,
    SscUdlKeySet: {},
    pageName: ''
};

export const sscKeysReducer = (state:SscKeysState = initialState, action: SscKeysActions):SscKeysState=>{
    switch (action.type){
        case SscKeysActionTypes.FETCHING_SSC_KEYS_SUCCESS:
            const successAction:FetchingSscKeysSuccessAction = action as FetchingSscKeysSuccessAction;
            const newKeySet = addPageConfigKeys(state, successAction);
            return {
                loading: false,
                dataFetchSuccess: true,
                pageName: successAction?.pageName,
                SscUdlKeySet: newKeySet
            };
        case SscKeysActionTypes.START_FETCHING_SSC_KEYS:
            return {
                loading: true,
                dataFetchSuccess: false,
                SscUdlKeySet: {...state?.SscUdlKeySet},
                pageName: ''
            };
        case SscKeysActionTypes.FETCHING_SSC_KEYS_FAILED:
            const failAction:FetchingSscKeysFailedAction = action as FetchingSscKeysFailedAction;
            return {
                loading: false,
                dataFetchSuccess: false,
                pageName: failAction?.pageName,
                SscUdlKeySet: {...state?.SscUdlKeySet}
            };
        default:
            return state;
    }
};

export const addPageConfigKeys = (state:SscKeysState, successAction: FetchingSscKeysSuccessAction): any  => {
    const pageName = successAction?.pageName;
    const newConfigKeys = {...state?.SscUdlKeySet} || {};
    const ssc = successAction?.payload?.sscKeys;
    const udl = successAction?.payload?.udlKeys
    newConfigKeys[pageName] = {
        ssc,
        udl
    }
   return newConfigKeys;
}