import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom, map } from 'rxjs/operators';
import { PageService } from '../../services/common_service/page.service';
import { HttpService } from '../../services/service_loader/http.service';
import * as confirmDeviceAction from "../../store/confirm-device-store/confirm-device.actions";
import { AppState } from '../app.reducer';
import { isEmpty, cloneDeep, isEqual } from "lodash-es";
import { confirmDeviceSelector } from './confirm-device.selectors';
import { AppConstants } from '../../constants/app-constants';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../services/common_service/auth.service';
import { syncApiError } from '../../constants/app-constants';
@Injectable()

export class ConfirmDeviceEffects{
    pageConfig: any = this.pageService.config;
    userData;
    constructor(
        private actions$: Actions,
        private http: HttpService,
        private pageService: PageService,
        private store : Store<AppState>,
        private authservice:AuthService
    ){
        this.authservice.userDataSub.subscribe(res=>{
            this.userData = res;
        })
    }
    getAllDeviceData = createEffect(()=>
        this.actions$.pipe(
            ofType(confirmDeviceAction.ConfirmDeviceActionType.loadConfirmDevice),
            withLatestFrom(this.store.select(confirmDeviceSelector)),
            mergeMap(([action,confirmDeviceData]:[any, any])=>{
                const oldPayload = confirmDeviceData?.oldPayload;
                let httpHeaders: HttpHeaders; 
                    if(!isEmpty(this.userData)){
                        // console.log(this.userData,'userData');
                        httpHeaders = new HttpHeaders().set(syncApiError.key,syncApiError.value);
                    }else{
                        httpHeaders = new HttpHeaders();
                    }
                if(confirmDeviceData?.dataLoded === false || (!isEqual(action?.payload,oldPayload) )){
                    return this.http.post(AppConstants.deviceVerifyURL, action?.payload,{headers:httpHeaders}).pipe(
                    map((res: any) => {
                    if (res) {
                        return new confirmDeviceAction.LoadConfirmDeviceSuccess({...res,oldPayload:cloneDeep(action?.payload),dataLoded:true});  
                    }}),
                    catchError((err)=>{
                        return of(new confirmDeviceAction.LoadConfirmDeviceFailure({...err,dataLoded:false}))
                    }))
                }
                else{
                    return of(new confirmDeviceAction.LoadConfirmDeviceSuccess({...confirmDeviceData,dataLoded:true}))
                }
               
            })
        )
    );
}