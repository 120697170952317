import { CountryList } from "../../modules/feature-module/country-lang-selector/countryList.model";
import { CountryListActionTypes, CountryListActions, StoreCountryListAction } from "./translated-countrylist.actions";

export interface CountryListState{
    success: boolean;
    response: {[key:string]: CountryList[]};
}

const initialState: CountryListState = {
    success: false,
    response: {}
};

export const countryListReducer = (state:CountryListState = initialState, action: CountryListActions):CountryListState=>{

    switch (action.type){
        case CountryListActionTypes.STORE_COUNTRY_LIST:
            const storeAction:StoreCountryListAction = action as StoreCountryListAction;
            let updated = {...state.response};
            updated[`${storeAction.payload.locale}`] = storeAction.payload.response;
            return {
                success: storeAction.payload.success,
                response: updated
            };
        default:
            return state;
    }
};