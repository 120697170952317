import { createSelector } from '@ngrx/store';
import { SscKeysState } from './sscKeys.reducer';
import { isEmpty } from 'lodash-es';
import { AppState } from '../../store/app.reducer';

export const sscKeysStore = (state: AppState) => state.sscKeys;


export const getState = (state: AppState) => state?.sscKeys;

export const selectAllConfigKeys = createSelector(
  getState,
  (state:SscKeysState)=> state?.SscUdlKeySet
);
    
export const sscKeysSelector = createSelector(
    sscKeysStore,
    state => {
        const pageName = state?.pageName;
        if(pageName && state.dataFetchSuccess && !isEmpty(state?.SscUdlKeySet[pageName]?.ssc)) {
            return state?.SscUdlKeySet[pageName]?.ssc
        }
    }
);

export const udlKeysSelector = createSelector(
    sscKeysStore,
    state => {
        const pageName = state?.pageName;
        if(pageName && state.dataFetchSuccess &&  !isEmpty(state?.SscUdlKeySet[pageName]?.udl)) {
            return state?.SscUdlKeySet[pageName]?.udl
        }
    }
);

 export default { sscKeysSelector, udlKeysSelector };
