import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { mergeMap, withLatestFrom, map } from 'rxjs/operators';
import { PageService } from '../../services/common_service/page.service';
import * as device_deviceIdAction from "../device-deviceId-store/device-deviceId.actions";
import { AppState } from '../app.reducer';
import { isEmpty, cloneDeep, isEqual } from "lodash-es";
import { device_deviceIdSelector } from './device-deviceId.selectors';
import { DeviceCardService } from '../../pages/dashboard/device-card/device-card.service';
@Injectable()

export class Device_DeviceIdEffects{
    pageConfig: any = this.pageService.config;
    constructor(
        private actions$: Actions,
        private pageService: PageService,
        private store : Store<AppState>,
        private deviceCardService: DeviceCardService
    ){}
    getDevice_DeviceIdData = createEffect(()=>
        this.actions$.pipe(
            ofType(device_deviceIdAction.Device_DeviceIDActionType.Device_DeviceID),
            withLatestFrom(this.store.select(device_deviceIdSelector)),
            mergeMap(([action,device_deviceIdData]:[any, any])=>{
                const oldPayload = device_deviceIdData?.oldPayload;
                if(isEmpty(device_deviceIdData.deviceInfo) ||  (!isEqual(action?.payload,oldPayload))){
                  return  this.deviceCardService.getDeviceCardDetailsnew(action?.payload?.deviceId,action?.payload?.formattedUTCOffset,action?.payload?.payloadForAPI)
                        .pipe(map(res=>{
                            let cloneResponse = cloneDeep(res);
                            if(res?.code.toString() === "102" || res?.code.toString() === "424"){
                                return  new device_deviceIdAction.Device_DeviceIDSuccess({...cloneResponse,oldPayload:cloneDeep(action?.payload),dataLoaded:false});
                            }
                            else{
                                return  new device_deviceIdAction.Device_DeviceIDSuccess({...cloneResponse,oldPayload:cloneDeep(action?.payload),dataLoaded:true});
                            }
                            },err=>{
                                return of(new device_deviceIdAction.Device_DeviceIDFailure({...err,dataLoaded:false}));
                            }))
                }
                else{
                    let cloneResponse = cloneDeep(device_deviceIdData);
                    return of(new device_deviceIdAction.Device_DeviceIDSuccess({...cloneResponse,dataLoaded:true}))
                }
               
            })
        )
    );
}