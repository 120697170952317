import { Action } from '@ngrx/store';


// Define action types
export enum XhtmlTermbaseActionTypes {
  LoadXhtmlTermbase = 'load xhtml termbase',
  LoadXhtmlTermbaseSuccess = 'load xhtml termbase Success',
  LoadXhtmlTermbaseFailure = 'load xhtml termbase Failure',
}

export interface XhtmlTermbaseKeysModel{
  [key: string]: any
}

 export interface XhtmlTermbasePayload {
    pageName?: string
 }

// Define action creators
export class FetchXhtmlTermbase implements Action {
  readonly type = XhtmlTermbaseActionTypes.LoadXhtmlTermbase;
  constructor(public payload: XhtmlTermbasePayload) {}
}

export class FetchXhtmlTermbaseSuccess implements Action {
  readonly type = XhtmlTermbaseActionTypes.LoadXhtmlTermbaseSuccess;
  constructor(public pageName: string,public response: XhtmlTermbaseKeysModel) {}
}

export class FetchXhtmlTermbaseFailure implements Action {
  readonly type = XhtmlTermbaseActionTypes.LoadXhtmlTermbaseFailure;
  constructor(public error: any) {}
}

// Export all actions as a union type
export type XhtmlTermbaseActions = FetchXhtmlTermbase | FetchXhtmlTermbaseSuccess | FetchXhtmlTermbaseFailure;
