import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import { PageService } from '../../services/common_service/page.service';
import * as SyncStatusAction from "./sync-status.actions";
import { AppState } from '../app.reducer';
import { SyncStatusSelector } from './sync-status.selectors';
import { HttpService } from '../../services/service_loader/http.service';
import { GlobalDataLayerService } from '../../services/common_service/globalDataLayer.service';
import { Router } from '@angular/router';
import { LoadingScreenService } from '../../services/loader/loader.service';

const jobStatus=['PROCESSING'];
// const mockData={
//     timeElapsedInMS: 594,
//     code: 200,
//     status: "OK",
//     message: "Success",
//     data: {
//         jobDetails: {
//             uri: "/devices/service/async",
//             id: "16412846",
//             name: "Get Devices",
//             jobStatus: "COMPLETED"
//         },
//         faultItemList: [],
//         hasChanges: "false"
//     }
// }
@Injectable()

export class SyncStatusEffects {
    pageConfig: any = this.pageService.config;
    constructor(
        private actions$: Actions,
        private pageService: PageService,
        private store : Store<AppState>,
        private httpService:HttpService,
        private globalDataService: GlobalDataLayerService,
        private router:Router,
        private loaderService : LoadingScreenService,
    ){}
    getSyncStatusData = createEffect(()=>
        this.actions$.pipe(
            ofType(SyncStatusAction.SyncStatusActionType.SyncStatus),
            withLatestFrom(this.store.select(SyncStatusSelector)),
            switchMap(([action,syncStatusData]:[any, any])=>{
                if(syncStatusData.dataLoaded === false || jobStatus.includes(syncStatusData?.data?.jobDetails?.jobStatus)){
                    return this.httpService.get('/wcc-services/profile/devices/sync/status').pipe(
                            map((res:any)=>{
                                let syncStatus;
                                if(res?.data?.jobDetails?.jobStatus === 'COMPLETED'){
                                    syncStatus = 'Success';
                                }
                                else if(res?.data?.jobDetails?.jobStatus === 'FAILED'){
                                    syncStatus = 'Failed';
                                }
                                else{
                                    syncStatus = res?.data?.jobDetails?.jobStatus;
                                }
                                this.globalDataService.updateGDL({EntitlementResults:{sync:syncStatus}}, true);
                                return  new SyncStatusAction.LoadSyncStatusSuccess({...res,dataLoaded:true});
                            }),
                            catchError(err=>{
                                // console.log(err);
                                // this.loaderService.stopLoading();
                                // this.globalDataService.updateGDL({EntitlementResults:{sync:mockData?.data?.jobDetails?.jobStatus}}, true);
                                // return  of(new SyncStatusAction.LoadSyncStatusSuccess({...mockData,dataLoaded:true}));
                                this.loaderService.stopLoading();
                                this.globalDataService.updateGDL({EntitlementResults:{sync:'Failed'}}, true);
                                this.router.navigate([`${this.pageConfig.cc}-${this.pageConfig.lc}/sync-error`],{skipLocationChange: true });
                                return of(new SyncStatusAction.LoadSyncStatusFailure({...err,dataLoaded:false}));
                            })
                            )
                }
                else{
                    return of(new SyncStatusAction.LoadSyncStatusSuccess({...syncStatusData,dataLoaded:true}))
                }

            })
        )
    );
}
