export let whitelistedDomains : string[] = [
    'hp.com',
    'www.hp.com',
    'www8.hp.com',
    'support.hp.com',
    'www.support.hp.com',
    'myaccount.stg.cd.id.hp.com',
    'myaccount.id.hp.com',
    'myaccount.stage.portalshell.int.hp.com',
    'account.hp.com'
]