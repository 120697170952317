// create a Angular injectable service
// Path: hp-wcc\src\app\services\regions.service.ts
// RegionsService is a Angular injectable service
// add method getRegions() to get data from server
// inject HttpClient in constructor
// create typescript interface Region
// Path: hp-wcc\src\app\services\regions.service.ts
export interface Region {
    cclc: string;
    regionId: number;
    regionName: string;
}


//
// Path: hp-wcc\src\app\services\regions.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { URLS } from '../constants/app-constants';
@Injectable({
    providedIn: 'root'
})
export class RegionsService {
    constructor(private http: HttpClient) { }

    getRegions(): Observable<Region[]> {
       return this.http.get<{status: string, data: Region[]}>(URLS.REGIONS)
        .pipe( map(resp => resp?.data))
    }

    getCLCRegions(url): Observable<Region[]> {
       return this.http.get<{status: string, data: any}>(url)
        .pipe( map(resp => resp?.data))
    }
}