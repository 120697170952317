// create RegionsState reducer with regions and loaded and error properties
// Path: hp-wcc\src\app\store\regions\regions.reducer.ts
import { Region } from '../../services/regions.service';
import { RegionsActionTypes } from './regions.actions';
export interface RegionsState {
    regions: Region[];
    loaded: boolean;
    loading: boolean;
    error: any;
}
export const initialState: RegionsState = {
    regions: [],
    loaded: false,
    loading: false,
    error: null
};
export function RegionsReducer(state = initialState, action: any): RegionsState {
    switch (action.type) {

        case RegionsActionTypes.LoadRegions:
            return {
                ...state,
                loading: true
            };
        case RegionsActionTypes.RegionsLoaded:
            return {
                ...state,
                regions: action.payload,
                error: null,
                loaded: true,
                loading: false
            };
        case RegionsActionTypes.RegionsLoadError:
            return {
                ...state,
                error: action.payload,
                loaded: false,
                loading: false
            };
        default:
            return state;
    }
}

// create CLCRegionsState reducer with regions and loaded and error properties
// Path: hp-wcc\src\app\store\regions\regions.reducer.ts
// import { CLCRegion } from '../../services/regions.service';
import { ClcRegionsActionTypes } from './regions.actions';
export interface CLCRegionsState {
    regions: Region[];
    params: any;
    loaded: boolean;
    loading: boolean;
    error: any;
}
export const initialStateClc: CLCRegionsState = {
    regions: [],
    params: {},
    loaded: false,
    loading: false,
    error: null
};
export function CLCRegionsReducer(state = initialStateClc, action: any): CLCRegionsState {

    switch (action.type) {

        case ClcRegionsActionTypes.LoadClcRegions:
            return {
                ...state,
                loading: true
            };
        case ClcRegionsActionTypes.ClcRegionsLoaded:
            return {
                ...state,
                regions: action.payload.regions,
                params: action.payload.params,
                error: null,
                loaded: true,
                loading: false
            };
        case ClcRegionsActionTypes.ClcRegionsLoadError:
            return {
                ...state,
                error: action.payload,
                loaded: false,
                loading: false
            };
        default:
            return state;
    }
}


