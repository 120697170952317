// create reducer for VideoDetails store for collectionIds

import { VideoDetailsActionTypes, VideoDetailsActions } from "./video-details.actions";
import { CollectionVideosData } from "./video-details.model";


export interface VideosDetailState {
  videosDetail: CollectionVideosData;
  loading: boolean;
  error: any | null;
}

const initialState: VideosDetailState = {
  videosDetail: null,
  loading: false,
  error: null
}

export function videoDetailsReducer(state = initialState, action: VideoDetailsActions): VideosDetailState {
  switch (action.type) {
    case VideoDetailsActionTypes.LoadVideoDetails:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VideoDetailsActionTypes.LoadVideoDetailsSuccess:
      return {
        ...state,
        videosDetail: { ...state.videosDetail, ...action.response },
        loading: false,
        error: null,
      };
    case VideoDetailsActionTypes.LoadVideoDetailsFailure:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

