import { createAction, props } from '@ngrx/store';
import { ProductContext } from '../../model/product-context.model';


export const getProductContextData = createAction(
    '[ProductContext] Get ProductContext',
    props<{payload: any}>()
);

export const StoreProductContext = createAction(
    '[ProductContext] Store ProductContext',
    props<{ productContext: ProductContext }>()
);

export const ClearProductContext = createAction(
    '[ProductContext] Remove ProductContext'
);