import { Action } from "@ngrx/store";

export enum Device_DeviceIDActionType{
    Device_DeviceID = '[device-deviceid api] load',
    Device_DeviceIDSuccess = '[device-deviceid api] success',
    Device_DeviceIDFailure = '[device-deviceid api] failure',
}
export class Device_DeviceID implements Action{
    readonly type = Device_DeviceIDActionType.Device_DeviceID;
    constructor(public payload:any){};
}
export class Device_DeviceIDSuccess implements Action{
    readonly type = Device_DeviceIDActionType.Device_DeviceIDSuccess;
    constructor(public data:any){};
}
export class Device_DeviceIDFailure implements Action{
    readonly type = Device_DeviceIDActionType.Device_DeviceIDFailure;
    constructor(public error:any){};
}

export type Device_DeviceIDAction = Device_DeviceID | Device_DeviceIDSuccess | Device_DeviceIDFailure;

