import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingScreenService } from '../../services/loader/loader.service';
import { PageService } from '../../services/common_service/page.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  
  constructor(
    private pageService: PageService,
    private loadingScreenService: LoadingScreenService,
    private cdr: ChangeDetectorRef
  ) {
    this.loadingScreenService.startLoading();
  }

  pageConfig: any = this.pageService.config;
  loaderActive: boolean = true;
  loadingText: string;
  destroySubject$: Subject<void> = new Subject();

  ngOnInit() {
    this.loadingScreenService.loadingStatus
    .pipe(takeUntil(this.destroySubject$))
    .subscribe(
      (data) => {
        this.loaderActive = data.isLoading;
        this.loadingText = data.loadingText;
        this.cdr.detectChanges();
      }
    )
  }

  ngOnDestroy(){
    this.destroySubject$.next();
  }

}
