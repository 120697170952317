import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WpdWaitingModalComponent } from "./wpd-waiting-modal-component/wpd-waiting-modal.component";

@NgModule({
  declarations: [WpdWaitingModalComponent],
  imports: [
    CommonModule,
    ],
  exports: [WpdWaitingModalComponent],
})
export class WpdWaitingModule { }
