import { DeviceResponseData } from '../../modules/feature-module/device-carousel/device-carousel/device.model';
import { AllDeviceAction, AllDeviceActionType } from './all-devices.actions';

export interface AllDeviceData {
    timeElapsedInMS: number;
    code: number;
    status: string;
    message: string;
    data: DeviceResponseData;
    dataLoded:boolean;
    oldPayload:any;
    error?:any;
  }
export const allDeviceInitialState:AllDeviceData = {
        timeElapsedInMS:0,
        code: 0,
        status: '',
        message: '',
        data:{
            deviceCount: 0,
            caseCount: 0,
            deviceList:[],
            FaultItemList:[],
            customerState:'',
            icons: {
            hpplus: '',
            instantInk: ''
            }
        },
        error: null,
        dataLoded:false,
        oldPayload:{}
}
export function allDeviceActionReducer(state=allDeviceInitialState,action:AllDeviceAction){
    switch(action.type){
        case AllDeviceActionType.loadAllDevice:
            return{
                ...state,
                dataLoded: false
            }
        case AllDeviceActionType.loadAllDeviceSuccess:
            return{
                ...state,
                ...action.data
            }
        case AllDeviceActionType.loadAllDeviceFailure:
            return{
                ...state,
                error:action.error
            }
        case AllDeviceActionType.clearAllDeviceData: 
            return allDeviceInitialState;
        default :
        return state;
    }
}
