import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import { PageService } from '../../../services/common_service/page.service';
import { HPNotificationIconsService } from './notification-icons.service';
import { AppSelectorStore } from '../../../store/app.selector';
import { TermbaseKeysModel } from '../../../store/termbase-store/termbase.reducer';
import { CommonService } from '../../../services/common_service/common.service';
import { DEFAULT_ALERTS_COUNT } from '../../../constants/app-constants';
import { AppState } from '../../../store/app.reducer';
import { ConfigKeysModel } from '../../../services/ssc-store/sscKeys.reducer';
import { sscKeysSelector } from '../../../services/ssc-store/config.selectors';
import { omniLocaleConfig } from '../../../modules/feature-module/omnichannel-chat-module/omnichannel-config/omniLocaleConfig';

@Component({
  selector: 'app-notification-icons',
  templateUrl: './notification-icons.component.html',
  styleUrls: ['./notification-icons.component.scss'],
})

export class NotificationIconsComponent implements OnInit, OnDestroy {
  // Page related configs
  pageConfig: any = this.pageService.config;

  // Translations
  allTranslations: TermbaseKeysModel = {};

  // Notification alert related config
  alertData;
  public show = false;
  notificationsLength = 0;
  notificationAlertsUrl;

  // Configs set from local storage
  alertReadLocalStorage;
  isPageRefreshed;
  firstLoad;
  showColor: boolean;

  // SSC config List
  alertsCount = DEFAULT_ALERTS_COUNT;
  closeNotificationTime = null;

  destroySubject$: Subject<void> = new Subject();
  showNotifications = false;
  constructor(
    private pageService: PageService,
    private hpNotificationService: HPNotificationIconsService,
    private appSelectorStore: AppSelectorStore,
    private commonService: CommonService,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.appSelectorStore.getAllTranslations$().pipe(takeUntil(this.destroySubject$)).subscribe(res=>this.allTranslations=res);

    this.store.select(sscKeysSelector).pipe(
      first((sscKeys: ConfigKeysModel) => !isEmpty(sscKeys))
    ).subscribe((sscKeys: ConfigKeysModel) => {
        const limit = this.pageConfig.isMobile ? sscKeys?.ALERTS_WIDGET_MOBILE_COUNT?.keyValue : sscKeys?.ALERTS_WIDGET_DESKTOP_COUNT?.keyValue
        this.alertsCount = parseInt(limit) || DEFAULT_ALERTS_COUNT;
        this.closeNotificationTime = parseInt(sscKeys?.ALERTS_WIDGET_CLOSE_TIME?.keyValue) ?? null;
        this.getDataFromStorageAndFetchAlerts();
      
    });
  }

  getDataFromStorageAndFetchAlerts() {
    if (this.pageConfig.isBrowser) {
      if (typeof localStorage !== 'undefined' && localStorage.getItem('onLoadreadAlert')) {
        const keyValue = localStorage.getItem('onLoadreadAlert');
        if (keyValue === 'read') {
          this.showColor = false;
          this.show = false;
          // Detecting old user who refreshed page
          this.isPageRefreshed = true;
        }
      } else {
        // Detecting a fresh user whose localstorage is not set
        this.firstLoad = true;
        this.showColor = true;
        if (this.closeNotificationTime) {
          setTimeout(() => {
            this.show = false;
            this.hpNotificationService.setFocusOnModal('closeAlert');
          }, this.closeNotificationTime * 1000); // 10 seconds in milliseconds coming from SSC
        }
        this.isPageRefreshed = false;
      }
    }
    this.showHideNotification();
  }

  dismissAlert() {
    this.show = false;
    this.hpNotificationService.setFocusOnModal('notifications-trigger');
    this.showColor = false;
    if (this.pageConfig.isBrowser) {
      if (typeof localStorage !== 'undefined' && !localStorage.getItem('onLoadreadAlert')) {
        this.alertReadLocalStorage = 'read';
        localStorage.setItem('onLoadreadAlert', this.alertReadLocalStorage);
      }
    }
    this.alertData.forEach((el: any) => {
      el.alertType = 'null';
      this.isPageRefreshed = false;
    });
  }

  showHideNotification() {
    // get alertData from existing variable
    if (this.alertData?.length && !this.isPageRefreshed) {
      this.show = true;
      this.hpNotificationService.setFocusOnModal('closeAlert');
    }
    if (!this.show) {
      this.hpNotificationService.fetchNotifications();
      this.store.select((appState: AppState) => appState.alerts.alerts)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(
          (res: any) => {
            if (!isEmpty(res)) {
              this.alertData = this.hpNotificationService.sortAndFilterAlerts(res);
              this.alertData = this.alertData.slice(0, this.alertsCount);
              this.notificationsLength = this.alertData?.length;
              if (this.pageConfig.isBrowser && !localStorage?.getItem('onLoadreadAlert')) {
                // Set firstLoad to false on successful creation of localstorge and show color as well
                this.show = true;
                this.hpNotificationService.setFocusOnModal('closeAlert');
                this.firstLoad = false;
                this.showColor = true;
              }
              if (this.alertData.length !== 0 && this.firstLoad) {
                // Show alert message when user is new and alerts are present
                this.show = true;
                this.hpNotificationService.setFocusOnModal('closeAlert');
              }
              // Set the isPageRefreshed flag to false after page refresh
              this.isPageRefreshed = false;
            }
          }
        );
    }
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }

  toggleNotifications() {
    this.showNotifications = !this.showNotifications;
  }

  getlinkTarget(dataTarget: any) {
    return this.commonService.setLinkTarget(dataTarget);
  }

  getHrefLink(link: any) {
    return this.commonService.localizeURL(link);
  }

  print() {
    if(typeof document !== 'undefined'){
      const today = new Date();
      const date = today.getMonth()+1 + "/" + today.getDate() + "/" + today.getFullYear();
      const time =  today.toLocaleString(omniLocaleConfig[this.pageConfig.locale], { hour: 'numeric', minute: 'numeric', hour12: true });
      document.getElementById("printDateTime").innerHTML = date + " , " + time;
      // @TODO: Need to check, for URL filtering
      document.getElementById("pageURL").innerHTML = this.commonService.getSanitizedData(document.URL);
      document.getElementById("pageTitle").innerHTML = this.commonService.getSanitizedData(document.title?.split('|')?.[0]);
    }
  }
}
