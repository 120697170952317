// how to write effect to get data from server for a GET call and then dispatch an action to update the store

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RegionsService } from '../../services/regions.service';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ClcRegionsActionTypes, ClcRegionsLoaded, ClcRegionsLoadError, RegionsActionTypes, RegionsLoaded, RegionsLoadError } from './regions.actions';
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { getAllCLCRegions, getCLCRegionsParams, getRegionsLoaded } from './regions.selectors';
import { PageService } from '../../services/common_service/page.service';
import { isEqual } from 'lodash-es';

@Injectable()
export class RegionsEffects {

    // Can you inject store in constructor and use it in effect?
    // Yes, but you need to use withLatestFrom operator to get the data from store
    // https://stackoverflow.com/questions/50350472/how-to-access-ngrx-store-in-ngrx-effects
    // inject Ngrx Store in constructor

    pageConfig: any = this.pageService.config;

    constructor(
        private actions$: Actions,
        private pageService: PageService,
        private regionsService: RegionsService,
        private store: Store
    ) { }

    loadRegions$ = createEffect(
        () => this.actions$.pipe(
            ofType(RegionsActionTypes.LoadRegions),
            //How to avoid http call if we have data in store
            // import withLatestFrom from rxjs

            withLatestFrom(this.store.select(getRegionsLoaded)),
            filter(([action, loaded]) => !loaded),
            switchMap(() => this.regionsService.getRegions().pipe(
                map(regions => new RegionsLoaded(regions)),
                catchError(err => of(new RegionsLoadError(err)))
            ))
        )
    )

    // create effect for CLC regions
    // Path: hp-wcc\src\app\store\regions\regions.effect.ts
    loadClcRegions$ = createEffect(
        () => this.actions$.pipe(
            ofType(ClcRegionsActionTypes.LoadClcRegions),
            withLatestFrom(this.store.select(getCLCRegionsParams), this.store.select(getAllCLCRegions)),
            switchMap(([action, storedParams, storedRegions]: [{ url: string, type: string }, any, any]) => {
                let url = new URL(action?.url, this.pageConfig?.domain);
                let params = Object.fromEntries(url.searchParams);
                if ( !storedRegions?.length || !isEqual(params, storedParams))  {
                    return this.regionsService.getCLCRegions(action?.url).pipe(
                        map(regions => new ClcRegionsLoaded({ regions, params })),
                        catchError(err => of(new ClcRegionsLoadError(err)))
                    )
                } else {
                    return of(new ClcRegionsLoaded({ regions: storedRegions, params: storedParams }));
                }
            })
        )
    )

}