<div class="notification-container" [ngClass]="pageConfig.languageDirection === 'rtl' ? 'rtl' : 'ltr'">
  <div (click)="toggleNotifications()">
    <i class="icon-right icon-dwn" [class.icon-left]="showNotifications"></i>
  </div>
  <ul id="psf" class="unstyled notification-list" style="top: 72px;" [class.show]="showNotifications">
    <li class="psf-list notify-icon" *ngIf="notificationsLength !== 0">
      <a class="psf-link viewed" id="notifications-trigger" href="javascript:void(0);" (click)="showHideNotification()"
        [title]="allTranslations['#pps_WCC_FS_AlertNotification_NotificationIcon_sc']">
        <span class="icon icon-notifications" id="totalNotifications">{{alertData?.length}}</span>
        <svg id="bell-icon-svg" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 160 160.332"
          enable-background="new 0 0 160 160.332" xml:space="preserve">
          <g id="grid">
          </g>
          <g id="White_Background">
          </g>
          <g id="Text">
          </g>
          <g id="Icon_Blue">
            <g>
              <path d="M151.735,123.323c0,2.982-1.092,5.568-3.271,7.747c-2.178,2.178-4.765,3.271-7.747,3.271h-38.571
             c0,6.083-2.153,11.281-6.46,15.581c-4.3,4.307-9.498,6.46-15.581,6.46s-11.281-2.153-15.581-6.46
             c-4.307-4.3-6.46-9.498-6.46-15.581H19.493c-2.982,0-5.568-1.092-7.747-3.271c-2.185-2.178-3.271-4.765-3.271-7.747
             c2.869-2.411,5.481-4.941,7.835-7.577c2.348-2.643,4.79-6.071,7.314-10.289c2.53-4.219,4.664-8.77,6.416-13.648
             c1.752-4.878,3.189-10.792,4.307-17.735s1.676-14.408,1.676-22.387c0-8.72,3.359-16.831,10.076-24.32s15.525-12.041,26.43-13.648
             c-0.458-1.086-0.691-2.21-0.691-3.359c0-2.291,0.804-4.244,2.411-5.851c1.607-1.607,3.56-2.411,5.857-2.411
             c2.298,0,4.244,0.804,5.857,2.411c1.601,1.607,2.411,3.56,2.411,5.851c0,1.149-0.232,2.273-0.691,3.359
             c10.905,1.607,19.712,6.159,26.43,13.648s10.076,15.6,10.076,24.32c0,7.979,0.559,15.443,1.676,22.387s2.555,12.857,4.307,17.735
             c1.752,4.878,3.886,9.429,6.416,13.648c2.524,4.219,4.959,7.646,7.314,10.289C146.254,118.382,148.866,120.912,151.735,123.323z
              M81.48,148.114c0-0.917-0.458-1.375-1.375-1.375c-3.39,0-6.303-1.224-8.739-3.66c-2.442-2.442-3.66-5.355-3.66-8.739
             c0-0.917-0.458-1.375-1.375-1.375c-0.923,0-1.381,0.458-1.381,1.375c0,4.187,1.482,7.759,4.438,10.716
             c2.951,2.957,6.523,4.438,10.716,4.438C81.022,149.495,81.48,149.031,81.48,148.114z"></path>
            </g>
          </g>
          <g id="Gray_icon">
          </g>
          <g id="White_icon">
          </g>
        </svg>
      </a>
    </li>
    <li class="psf-print psf-list"><a class="psf-link" href="javascript:window.print();" title='print' (click)="print()"><span
          class="fa fa-print"></span>
      </a></li>
  </ul>
</div>
<div id="dateAndTitle">
  <div id="printDateTime"></div>
  <div id="pageTitle"></div>
  <div id="pageURL"></div>
</div>
<ng-container *ngIf="show">
  <div class="notifications-main" [ngClass]="pageConfig.languageDirection === 'rtl' ? 'rtl' : 'ltr'">
    <div class="close-bg">
      <a class="close-alert" href="javascript:void(0)" aria-label="close-alert" id="closeAlert" role="button"
        (click)="dismissAlert()">
        <span class="sr-only" aria-hidden="true">{{allTranslations["#pps_WCC_FS_Alert_Close_Icon_Screenreader"]}}</span>
      </a>
    </div>
    <div class="alertNotification-scroll" [ngClass]=" {'active': showColor, 'inactive': !showColor }">
      <div class="alertNotification" *ngFor="let alertData of alertData; let i = index">
        <div class='alert{{alertData.type}} notification'>
          <div class="{{alertData.type}} notification-title">
            {{allTranslations[alertData.title]}}
          </div>
          <div class="notification-body">
            <strong>
              <div class="notification-text">{{alertData?.alertDesc}}</div>
            </strong>
            <div class="cta-link-wrapper">
              <a [id]="alertData?.anrId" [href]="(getHrefLink|memoize:alertData.alertCTALink)"
                [title]="alertData?.alertCTALinkText" [target]="(getlinkTarget|memoize:alertData?.alertLinkToTarget)">
                <span>{{alertData?.alertCTALinkText}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
