<div [ngClass]="pageConfig.languageDirection === 'rtl' ? 'rtl' : 'ltr'" 
    class="error-modal service-unavialable"
    aria-labelledby="modal-heading" 
    aria-modal="true" 
    role="dialog">
  <div class="modal-header">
    <img class="company-logo" 
        *ngIf="!pageConfig.isMobile && largeDevice" 
        width="50px" height="50px" alt="hp-logo"
        src="/wcc-assets/images/hp-logo-modal.gif">
    <h3 class="modal-title" id="modal-heading">
      {{allTranslations['#pps_WCC_FS_Error_Service_Unavailable_Modal_Heading']}}</h3>
    <button type="button" 
            class="close" 
            aria-label="Close" 
            (click)="closeErrorModal()">
      <span aria-hidden="true" id="close-icon">&times;</span>
    </button>
  </div>
  <div [ngClass]="pageConfig.isTablet ? 'tablet modal-body secondary-content' : 'nonTablet modal-body secondary-content'">
    <p>{{specificMessage || allTranslations['#pps_WCC_FS_Error_Service_Unavailable_Modal_Text']}}</p>
    <div class="close-button">
      <button id="cancelBtn" 
              class="primary-button"
              (click)="closeErrorModal()">{{allTranslations['#pps_WCC_FS_Error_Service_Unavailable_Modal_Button_Text']}}</button>
    </div>
  </div>
</div>