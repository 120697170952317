export const omniLocaleConfig = {
    'au-en' : 'en-US',
    'bg-en' : 'en-US',
    'bg-bg' : 'en-US',
    'ca-en' : 'en-US',
    'ee-en' : 'en-US',
    'hr-hr' : 'en-US',
    'ee-et' : 'en-US',
    'lv-lv' : 'en-US',
    'ro-ro' : 'en-US',
    'rs-sr' : 'en-US',
    'sk-sk' : 'en-US',
    'si-sl' : 'en-US',
    'emea_africa-en' : 'en-US',
    'emea_middle_east-en' : 'en-US',
    'id-en' : 'en-US',
    'ie-en' : 'en-US',
    'in-en' : 'en-US',
    'lamerica_nsc_carib-en' : 'en-US',
    'lt-en' : 'en-US',
    'lt-lt' : 'en-US',
    'lv-en' : 'en-US',
    'my-en' : 'en-US',
    'nz-en' : 'en-US',
    'ph-en' : 'en-US',
    'ro-en' : 'en-US',
    'rs-en' : 'en-US',
    'sg-en' : 'en-US',
    'si-en' : 'en-US',
    'sk-en' : 'en-US',
    'th-en' : 'en-US',
    'gb-en' : 'en-US',
    'us-en' : 'en-US',
    'vn-en' : 'en-US',
    'za-en' : 'en-US',
    'at-de' : 'de-DE',
    'be-fr' : 'fr-FR',
    'fr-fr' : 'fr-FR',
    'ca-fr' : 'fr-FR',
    'ch-fr' : 'fr-FR',
    'emea_africa-fr' : 'fr-FR',    
    'be-nl' : 'nl-NL',
    'nl-nl' : 'nl-NL',
    'ar-es' : 'es-ES',
    'bo-es' : 'es-ES',
    'cl-es' : 'es-ES',
    'co-es' : 'es-ES',
    'ec-es' : 'es-ES',
    'lamerica_nsc_cnt_amer-es' : 'es-ES',
    'mx-es' : 'es-ES',
    'es-es' : 'es-ES',
    'pe-es' : 'es-ES',
    'pr-es' : 'es-ES',
    'py-es' : 'es-ES',
    'uy-es' : 'es-ES',
    've-es' : 'es-ES',
    'br-pt' : 'pt-BR',
    'by-ru' : 'ru-RU',
    'kz-ru' : 'ru-RU',
    'ru-ru' : 'ru-RU',
    'ua-ru' : 'ru-RU',
    'ch-de' : 'de-DE',
    'de-de' : 'de-DE',
    'cn-zh' : 'zh-Hans',
    'cz-cs' : 'cs-CZ',
    'dk-da' : 'da-DK',
    'emea_middle_east-ar' : 'ar-SA',
    'fi-fi' : 'fi-FI',
    'gr-el' : 'el-GR',
    'hk-zh' : 'zh-Hant',
    'tw-zh' : 'zh-Hant',
    'hu-hu' : 'hu-HU',
    'il-he' : 'he-IL',
    'it-it' : 'it-IT',
    'jp-ja' : 'ja-JP',
    'kr-ko' : 'ko-kr',
    'no-no' : 'nb-NO',
    'pl-pl' : 'pl-PL',
    'pt-pt' : 'pt-PT',
    'se-sv' : 'sv-SE',
    'th-th' : 'th-TH',
    'tr-tr' : 'tr-TR'
}

