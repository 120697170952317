import { Warranty } from '../../model/warranty-spec.model';
import { DeviceWarrantyActionType,DeviceWarrantyAction  } from './device-warranty.actions';

export interface DeviceWarrantyData {
    data: Warranty;
    dataLoaded:boolean;
    oldPayload:any;
    code:number;
  }
export const deviceWarrantyInitialState:DeviceWarrantyData = {
    data:{} as Warranty,
    dataLoaded:false,
    oldPayload:{},
    code:0
}
export function DeviceWarrantyReducer(state=deviceWarrantyInitialState,action:DeviceWarrantyAction){
    switch(action.type){
        case DeviceWarrantyActionType.DeviceWarranty:
            return{
                ...state,
            }
        case DeviceWarrantyActionType.DeviceWarrantySuccess:
            return{
                ...state,
                ...action.data
            }
        case DeviceWarrantyActionType.DeviceWarrantyFailure:
            return{
                ...state,
                error:action.error
            }
        default :
        return state;
    }
}