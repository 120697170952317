<div class="service-unavailable-error-container">
    <div class="error-info" [ngClass]="{ 'center-align': specsError }">
        <h1>{{allTranslations['#pps_WCC_FS_Personalized_Error_Sorry_Text']}}</h1>
        <p>{{allTranslations['#pps_WCC_FS_Personalized_Error_Info_Text']}}</p>
        <a href="javascript:void(0)" title="{{allTranslations['#pps_WCC_FS_Personalized_Error_Button_Text']}}" id = "dashboardError_button_refreshPage"
        class="refresh-btn btn" (click)="refreshPageData()"><span aria-hidden="true">{{allTranslations['#pps_WCC_FS_Personalized_Error_Button_Text']}}</span></a>
        <div class="helpful-link">
            <p>
                {{allTranslations['#pps_WCC_FS_Personalized_Error_Helpful_Link_Heading']}}
            </p>
            <app-helpful-links></app-helpful-links>
        </div>
    </div>
</div>