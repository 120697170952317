export const PDPConfig = {
    SETUP_USER_GUIDES_TMS_ID: "1814239515815695429141114101114104631515117082",
    INK_PAPER_TMS_ID: "110133834146794145838771441112101241143817",
    TROUBLESHOOTING_TMS_ID: "11270154141412431504211610126131397014136111143",
    WARRANTY_REPAIR_TMS_ID: "113139121015811149848153101110120131013555714109",
    ACCOUNT_REGISTRATION_TMS_ID: "11390813131226596434121110813674814954914117",
    PRODUCT_SPECIFICATIONS_TMS_ID: "131042714310101113741111399313171310613834515",
    SOFTWARE_DRIVERS_TMS_ID: "1338661345260104157981128910143408151120",
    SECURITY_VIRUSES_TMS_ID: "184131362139127815413110100192310961413696105",
}

export enum CategoryContentTypes {
    RECOMMENDED_SOLUTIONS = 'recommendedSolutions',
    RELATED_DOCUMENTS_AND_VIDEOS = 'relatedDocuments',
    SOFTWARE_AVAILABILITY = 'softwareAvailability',
    MANUALS = 'manuals',
    PRODUCT_SPECIFICATIONS = 'productSpecifications',
    REPAIR_STATUS_LINK = 'checkRepairStatusLink'
}

export const PDPSupportCategoriesPageTemplateName = {
    "default": "pdpdefault",
    "noContent": "nocontent",
    [PDPConfig.SOFTWARE_DRIVERS_TMS_ID]: "softwarendrivers",
    [PDPConfig.SETUP_USER_GUIDES_TMS_ID]: "setupnuserguides",
    [PDPConfig.ACCOUNT_REGISTRATION_TMS_ID]: "accountnregistration",
    [PDPConfig.TROUBLESHOOTING_TMS_ID]: "troubleshooting",
    [PDPConfig.WARRANTY_REPAIR_TMS_ID]: "warrantynrepairs",
    [PDPConfig.PRODUCT_SPECIFICATIONS_TMS_ID]: "productspecification",
    [PDPConfig.INK_PAPER_TMS_ID]: "inknpaper",
    [PDPConfig.SECURITY_VIRUSES_TMS_ID]: "securitynvirus"
}



