import { Component, OnDestroy, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { AppSelectorStore } from '../../../store/app.selector';
import { TermbaseKeysModel } from '../../../store/termbase-store/termbase.reducer';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './service-unavailable.component.html'
})

export class ServiceUnavailableErrorComponent implements OnInit, OnDestroy {
  // Translations
  allTranslations: TermbaseKeysModel = {};

  // Feature realted config
  @Output() refreshdatatriggered = new EventEmitter<string>();
  specsError: boolean = false;
  private window: Window;
  destroySubject$: Subject<void> = new Subject();

  constructor(
    private appSelectorStore: AppSelectorStore,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
    this.specsError = (this.activatedRoute?.data as any).value?.specs;
  }

  ngOnInit() {
    this.appSelectorStore.getAllTranslations$()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => this.allTranslations = res);
  }

  refreshPageData() {
    if (this.specsError) {
      this.window.location.reload();
    } else {
      this.refreshdatatriggered.emit('pageDataRefresh');
    }
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
