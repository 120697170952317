import { Action } from "@ngrx/store";

export enum AllDeviceActionType{
    loadAllDevice = '[all device api] load',
    loadAllDeviceSuccess = '[all device api] success',
    loadAllDeviceFailure = '[all device api] failure',
    clearAllDeviceData = '[all device api] clear'
}
export class LoadAllDevice implements Action{
    readonly type = AllDeviceActionType.loadAllDevice;
    constructor(public payload:{}){};
}
export class LoadAllDeviceSuccess implements Action{
    readonly type = AllDeviceActionType.loadAllDeviceSuccess;
    constructor(public data:{}){};
}
export class LoadAllDeviceFailure implements Action{
    readonly type = AllDeviceActionType.loadAllDeviceFailure;
    constructor(public error:{}){};
}

export class ClearAllDeviceData implements Action{
    readonly type = AllDeviceActionType.clearAllDeviceData;
    constructor(){};
}

export type AllDeviceAction = LoadAllDevice | LoadAllDeviceSuccess | LoadAllDeviceFailure | ClearAllDeviceData;

