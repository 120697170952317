import { createSelector } from '@ngrx/store';
import { AppState } from '../app.reducer';


export const WarrantySpecsStoreSelector = (state: AppState) => state.warrantySpecsDataStore;

export const WarrantySpecsDataSelector = createSelector(
    WarrantySpecsStoreSelector,
    state => state.warrantySpecsData
);

export const WarrantySpecsPayloadSelector = createSelector(
    WarrantySpecsStoreSelector,
    state => state.warrantySpecsData?.apiPayload
);

export const ProductSpecsSelector = createSelector(
    WarrantySpecsStoreSelector,
    state => state.warrantySpecsData?.data?.devices?.[0]?.productSpecs
);

export const SpecsLoadingSelector = createSelector(
    WarrantySpecsStoreSelector,
    state => state.loading
);

export default { WarrantySpecsStoreSelector, WarrantySpecsDataSelector, WarrantySpecsPayloadSelector, ProductSpecsSelector };
