import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HpHeaderComponent } from '../../components/hp_com/hp_header/hp-header.component';
import { HeaderComponent } from '../../components/core/header/header.component';
import { HpFooterComponent } from '../../components/hp_com/hp_footer/hp-footer.component';
import { FooterComponent } from '../../components/core/footer/footer.component';
import { SecNavComponent } from '../../components/core/sec-nav/sec-nav.component';
import { CountryLocatorComponent } from '../../components/core/country-locator/country-locator.component';
import { NotificationIconsComponent } from '../../components/core/notification-icon-component/notification-icons.component';
import { ErrorModalComponent } from '../../components/error-scenario/error-modal/error-modal.component';
import { HelpfulLinksComponent } from '../../components/error-scenario/helpful-links/helpful-links';
import { ServiceUnavailableErrorComponent } from '../../components/error-scenario/service-unavailable/service-unavailable.component';
import { SessionTimeoutErrorComponent } from '../../components/error-scenario/session-timeout/session-timeout.component';
import { SuggestedServiceComponent } from '../../components/core/suggested-services/suggested-services.component';
import { MatchHeightModule } from './matchHeightDirective.module';
import { TooltipComponent } from '../../components/core/common-toolTip/toolTip.component';
import { HpCountryLocatorComponent } from '../../components/hp_com/hp-country-locator/hp-country-locator.component';
import { CommonDirectiveModule } from './../../directives/common-directive.module';
import { MemoizePipe } from '../../filter/memo.pipe';
@NgModule({
    declarations: [
        HpHeaderComponent,
        HeaderComponent,
        HpFooterComponent,
        FooterComponent,
        SecNavComponent,
        CountryLocatorComponent,
        NotificationIconsComponent,
        ErrorModalComponent,
        HelpfulLinksComponent,
        ServiceUnavailableErrorComponent,
        SessionTimeoutErrorComponent,
        SuggestedServiceComponent,
        HpCountryLocatorComponent,
        TooltipComponent
    ],
    imports: [
        CommonModule,
        MatchHeightModule,
        CommonDirectiveModule,
        MemoizePipe
    ],
    exports: [
        CommonModule,
        HpHeaderComponent,
        HeaderComponent,
        HpFooterComponent,
        FooterComponent,
        SecNavComponent,
        CountryLocatorComponent,
        NotificationIconsComponent,
        ErrorModalComponent,
        HelpfulLinksComponent,
        ServiceUnavailableErrorComponent,
        SessionTimeoutErrorComponent,
        SuggestedServiceComponent,
        HpCountryLocatorComponent,
        TooltipComponent
    ]
})
export class SharedModule {}
