import { Injectable } from "@angular/core";
import { WindowRefService } from "../../../services/window/window.service";

// Provided in Component
@Injectable()

export class HPSecNavService {
  window;

  constructor(
    private windowRef: WindowRefService,
  ) {
    this.window = this.windowRef.nativeWindow;
  }
  
  isLargeDevice() {
    if (this.window) {
      return this.window?.innerWidth <= 768 ? false : true;
    }
  }
}