import { createSelector } from '@ngrx/store';
import { TermbaseState } from '../termbase-store/termbase.reducer';
import { AppState } from '../app.reducer';
import { isEmpty } from 'lodash-es';

export const getTermBaseState = (state: AppState) => state?.termbaseKeys;

export const allTermbaseKeysSelector = createSelector(
  getTermBaseState,
  (state:TermbaseState)=> state?.termBaseKeySet
);
    
export const pageTermbaseSelector = createSelector(
  getTermBaseState,
    state => {
        const pageName = state?.pageName || "";
        if(pageName && state?.dataFetchSuccess && !isEmpty(state?.termBaseKeySet[pageName])) {
            return state?.termBaseKeySet[pageName]
        }
    }
);

export default { allTermbaseKeysSelector, pageTermbaseSelector };