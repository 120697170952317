import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import {  Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Device } from "../../../app/model/warranty-spec.model";
import { MastiffOffersPayload } from "../../../app/pages/pdp/model/pdp.models";
import { OfferConstants } from './offer.constant';
import { GetMastiffOffers } from "../../store/mastiff-offers-store/mastiff-offers.actions";
import { MastiffOffersStore, SpecsDataFirstDeviceSelector, SearchResultSelector } from "../../store/mastiff-offers-store/mastiff-offers-selectors";
import { AppState } from "../../../app/store/app.reducer";
import { OfferEntity } from '../../../app/store/mastiff-offers-store/mastiff-offers.reducer';

@Injectable({
    providedIn: 'root'
  })
  
export class OfferService implements OnDestroy {

  destroySubject$: Subject<void> = new Subject();
  sscKeys: any;
  pdpService: any;

    constructor(
      private store: Store<AppState>
    ) { }


    getMastiffOffers(pageId : string){
      return this.store.select(SpecsDataFirstDeviceSelector)
      .pipe(switchMap((specsData: Device) => {
        const REQUESTID_CHARACTERS = this.sscKeys?.PDP_MASTIFF_REQUESTID_CHARACTERS?.keyValue || OfferConstants.MASTIFF_REQUESTID_CHARACTERS;
        const payload: MastiffOffersPayload = {
          requestId: this.createReqId(REQUESTID_CHARACTERS),
          pageId: pageId,
          products: [
            {
              modelNumber: specsData?.warranty?.altProductNumber || specsData?.productSpecs?.productNumber,
              serialNumber: specsData?.productSpecs?.serialNumber,
              productSeriesOid: specsData?.productSpecs?.productSeriesOid?.toString(),
              productNameOid: specsData?.productSpecs?.productNameOid?.toString(),
              warranty: {
              }
            }
          ],
        }
        this.store.dispatch(GetMastiffOffers({ payload }));
        return this.store.select(MastiffOffersStore);
      }))
    }
    // This needs to be called when search result is available
 

   
    getMastiffShoppingLink(pageId : string,products?){
     const REQUESTID_CHARACTERS = this.sscKeys?.PDP_MASTIFF_REQUESTID_CHARACTERS?.keyValue || OfferConstants.MASTIFF_REQUESTID_CHARACTERS;
      const payload: MastiffOffersPayload = {
          requestId: this.createReqId(REQUESTID_CHARACTERS),
          pageId: pageId,
          products: products
        }
      this.store.dispatch(GetMastiffOffers({ payload }));
      return this.store.select(MastiffOffersStore);
    }

    createReqId(REQUESTID_CHARACTERS) {
      const timestamp = Date.now();
      const tsLength = String(timestamp).length;
      let randStrLen = 20 - tsLength;
      const characters = REQUESTID_CHARACTERS;
      const charactersLength = characters?.length;
      let result = "";
      for (let i = 0; i < randStrLen; i++) {
        result += characters?.charAt(Math.floor(Math.random() * charactersLength));
      }
      return String(timestamp).concat(result);
    }

    filterAndSortOffers(data: OfferEntity[], categoryCheck: boolean = true): OfferEntity[] {
      return data
        .filter(offer =>
          (offer.offerTitle && offer.offerTitle !== 'null') &&
          (categoryCheck ? (offer.category && offer.category !== 'null') : true) &&
          (offer.productSKU && offer.productSKU !== 'null')
        )
        .sort((a, b) => +a.offerRank - +b.offerRank);
    }
    
    

    ngOnDestroy() {
        this.destroySubject$.next();
      }
  }