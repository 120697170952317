import { Action } from "@ngrx/store";
import { AlertsDataModel } from "./alerts.reducer";

export enum AlertsActionTypes {
  START_FETCHING_ALERTS = '[alerts.action.ts] START_FETCHING_ALERTS',
  FETCHING_ALERTS_SUCCESS = '[alerts.action.ts] FETCHING_ALERTS_SUCCESS',
  FETCHING_ALERTS_FAILED = '[alerts.action.ts] FETCHING_ALERTS_FAILED'
}

export class StartFetchingAlertsAction implements Action {
  readonly type: string = AlertsActionTypes.START_FETCHING_ALERTS;

  constructor(
    public payload: {
      "allAlerts"?: boolean;
      "cclc"?: string;
      "oid"?: string;
    }
  ) { }
}

export class FetchingAlertsSuccessAction implements Action {
  readonly type: string = AlertsActionTypes.FETCHING_ALERTS_SUCCESS;

  constructor(
    public payload: {
      alerts?: AlertsDataModel[],
      pageName?: string
    }
  ) { }
}

export class FetchingAlertsFailedAction implements Action {
  readonly type: string = AlertsActionTypes.FETCHING_ALERTS_FAILED;

  constructor() { }
}

export type AlertsActions = StartFetchingAlertsAction | FetchingAlertsSuccessAction | FetchingAlertsFailedAction;
