import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { switchMap, takeUntil, first } from "rxjs/operators";
import { isEmpty } from 'lodash-es';

import { Store } from "@ngrx/store";
import { SyncStatusSelector } from "../store/sync-status-store/sync-status.selectors";


import { LoadingScreenService } from "../services/loader/loader.service";
import { PageService } from "../services/common_service/page.service";
import { AuthService } from "../services/common_service/auth.service";
import { LoadSyncStatus } from "../store/sync-status-store/sync-status.actions";
import { AppSelectorStore } from "../store/app.selector";
import { syncApiError } from "../constants/app-constants";

@Injectable()

export class syncAPIErrorInterceptor implements HttpInterceptor{
    destroySubject$: Subject<void> = new Subject();
    pageConfig: any = this.pageService.config;
    isSignedIn: boolean;
    loadingText;

    constructor(
        private router:Router,
        private pageService: PageService,
        private loaderService : LoadingScreenService,
        private authService: AuthService,
        private appSelectorStore:AppSelectorStore,
        private store:Store
    ){
        this.getLoadingText();
        this.checkAuth();
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(req.headers.get(syncApiError.key) !== null && req.headers.get(syncApiError.key) === syncApiError.value && this.isSignedIn){
            this.store.dispatch(new LoadSyncStatus({}));
            this.loaderService.loadingText = this.loadingText;
            return this.store.select(SyncStatusSelector)
            .pipe(
                first(res => res.dataLoaded ),
                switchMap(res => {
                      this.loaderService.resetLoadingText();
                        if(res?.data?.jobDetails?.jobStatus === 'COMPLETED'){
                            return next.handle(req);
                        }
                        else {
                            this.loaderService.stopLoading();
                            this.router.navigate([`${this.pageConfig.cc}-${this.pageConfig.lc}/sync-error`],{skipLocationChange: true });
                        }
                })
            )
        }
        else{
            return next.handle(req);
        }
    }

    checkAuth() {
        this.authService.userDataSub
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(res => {
            if (isEmpty(res)) return;
            if (res && res.customerId) {
              this.isSignedIn = true;
            }
            if ((res && res.state && (res.state === 'sessionExpired' || res.state === 'sessionInvalid'))) {
              this.isSignedIn = false;
            }
          });
      }

      getLoadingText() {
        this.appSelectorStore.getAllTranslations$()
        .subscribe(res=> {
          this.loadingText= res?.['#pps_WCC_FS_Dashboard_My_Devices_Loading_Text'];
        }
        );
      }

    ngOnDestroy(): void {
        this.destroySubject$.next();
      }
}
