import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { sortBy } from 'lodash-es';
import { CommonService } from '../../../services/common_service/common.service';
import { HttpService } from '../../../services/service_loader/http.service';
import { AuthService } from '../../../services/common_service/auth.service';
import { Router } from '@angular/router';
import { PageService } from '../../../services/common_service/page.service';
import { HPSecNavService } from './sec-nav.service';
import { WindowRefService } from '../../../services/window/window.service';
import { secNavTitleConfig } from './sec-nav-smDevice-title.config';
import { cloneDeep } from 'lodash-es';
import { contactCClcAlias } from '../../../pages/contact/configuration/contact-cclc-aliases';
import { AppSelectorStore } from '../../../store/app.selector';
import { TermbaseKeysModel } from '../../../store/termbase-store/termbase.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import { includes } from 'lodash-es';
import { ConfigKeysModel } from '../../../services/ssc-store/sscKeys.reducer';
import { ContactProductContext } from '../../../pages/contact/model/product-data.model';
import { sscKeysSelector } from '../../../services/ssc-store/config.selectors';
import { HP_ALL_IN_PLAN_SERIESOID } from '../../../constants/app-constants';
@Component({
  selector: 'app-sec-nav',
  templateUrl: './sec-nav.component.html',
  styleUrls: ['./sec-nav.component.scss'],
  providers: [ HPSecNavService ]
})

export class SecNavComponent implements OnInit, OnDestroy {
  // Page related config
  pageConfig: any = this.pageService.config;
  contactCClcAlias = contactCClcAlias;

  @Input() config: any;
  allTranslations:TermbaseKeysModel={};
  secNav: any;
  url: string;
  authData;
  dataUrl;
  secNavTitle: string="";
  currentPage;
  isUserAuthenticated;
  activeLink;
  isLargeDevice: boolean;
  dropdownOpen: string;
  destroySubject$: Subject<void> = new Subject();
  private window: Window;
  vsidThemeEnabled: boolean;
  openMenu: boolean = false;
  // Sev nav title for mobile Obj
  secNavTitleConfig = cloneDeep(secNavTitleConfig);


  @ViewChild('navContainer', { static: true }) el: ElementRef;

  @HostListener('window:resize') onResize() {
    this.isLargeDevice = this.secNavService.isLargeDevice();
  }

  @HostListener('document:click', ['$event']) click(event) {
    if (!this.isLargeDevice) {
      const $el = event.target;
      if (!$el.classList.contains('secnav-dropdown-carrot') && !$el.classList.contains('submenu')) {
        this.dropdownOpen = null;
      }
    }
  }

  constructor(
    private pageService: PageService,
    public commonService: CommonService,
    private auth: AuthService,
    private http: HttpService,
    private router: Router,
    private secNavService: HPSecNavService,
    private windowRef: WindowRefService,
    private appSelectorStore:AppSelectorStore,
    private store: Store<AppState>
  ) {
    this.window = this.windowRef.nativeWindow;
    this.auth.userDataSub
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
        this.authData = res;
        this.isUserAuthenticated = this.authData && this.authData.customerId && this.authData.customerId.length;
      });

    let currentPath: any = this.router.url.split('/');
    currentPath = currentPath[currentPath.length - 1];
    this.currentPage = currentPath;
  }
  getPagename(pageName){

    const products = ['printerLanding', 'computerLanding', 'productLanding'];
    const contacts = ['contact', 'failureDiagnostic'];
    const softwareAndDrivers = ['swdLanding', 'swdDrivers', 'swdDriverDetails', 'swdClosure'];

    if (products.includes(pageName)) {
      pageName = 'products';
    }

    if (softwareAndDrivers.includes(pageName)) {
      pageName = 'softwareAndDrivers';
    }

    if (contacts.includes(pageName)) {
      pageName = 'contactSupport';
    }

    return pageName;
  }
  ngOnInit() {
    this.isLargeDevice = this.secNavService.isLargeDevice();

    this.dataUrl = `/wcc-services/cms-v2/${this.pageConfig.cc}-${this.pageConfig.lc}/wcc_second_Nav`;


    this.getNav();

    this.appSelectorStore.getAllTranslations$(false).pipe(takeUntil(this.destroySubject$)).subscribe(resp=>{
      this.allTranslations=resp;
      this.secNavTitle = this.allTranslations[this.secNavTitleConfig[this.pageConfig.pageName]];
    });

    this.store.select(sscKeysSelector).pipe(
      takeUntil(this.destroySubject$)
    ).subscribe((sscKeys: ConfigKeysModel) => {
      this.vsidThemeEnabled = sscKeys?.ENABLE_VIS_ID_LAYOUT ?
        includes(sscKeys?.ENABLE_VIS_ID_LAYOUT?.keyValue,this.pageConfig.pageName) : false;
      // if(sscKeysState.dataFetchSuccess && !isEmpty(sscKeysState.keys)){
        
      // }
    })
  }

  enableDashboardLink(menuObj, isUserAuthenticated) {
    if (menuObj.activeLink === 'dashboard' && !isUserAuthenticated) {
      return false;
    }
    return true;
  }

  mouseEnterEvent(menuTitle: string): void {
    if (this.isLargeDevice) {
      this.dropdownOpen = menuTitle;
    }
  }

  mouseLeaveEvent(): void {
    if (this.isLargeDevice) {
      this.dropdownOpen = null;
    }
  }

  toggleMenu(menuTitle: string) {
    this.dropdownOpen = this.dropdownOpen !== menuTitle ? menuTitle : null;
  }

  private getNav(): void {
    this.http
      .get(this.dataUrl)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res) => {
        if (res && res.data) {
          this.secNav = sortBy( res['data'], (obj) =>{ return obj.order; });
        }
      },
        (err) => {
        },

        () => {
          if (this.pageConfig.isBrowser && this.secNav && this.secNav.indexOf('html') === -1) {
            this.commonService.contexualSecNavContactHpLink.subscribe((updatedUrl) => {
              if (this.currentPage === 'device') {
                if (typeof this.window !== 'undefined') {
                  var contactLink = this.window?.document?.getElementById("sec-nav-contact-support");
                  if (contactLink) {
                    contactLink.setAttribute("href", updatedUrl);
                  }
                }
              }
            });

          }
        }
      );
  }


  showHideMenu() {
    if (this.pageConfig.isBrowser) {
      const div = document.getElementById('drop-down');
      if (div.classList) {
        div.classList.toggle('open');
      } else {
        // For IE9
        const classes = div.className.split(' ');
        const i = classes.indexOf('open');
        if (i >= 0) {
          classes.splice(i, 1);
        } else {
          classes.push('open');
          div.className = classes.join('');
        }
      }
      if(div.className.includes('open')) {
        this.openMenu = true;
      } else {
        this.openMenu = false;
      }
    }
  }

  gotoContact() {
    const hpAllInPlanTemplateNames = ['SDP-HP-all-in-plan-Print-Landing', 'SDP-HP-all-in-plan-Print-Yourhpallinplan', 'SDP-HP-all-in-plan-Print-Yourink', 'SDP-HP-all-in-plan-Print-Yourprinter'];
    const contactFormMetaData = sessionStorage?.getItem('contactFormMetaData') && JSON.parse(sessionStorage?.getItem('contactFormMetaData'));

    // Sec nav changes for Contact link from HP All In Plan SDP page (ENGLISH ONLY for Release 24.4 - SSVSPRT-9379)
    if (hpAllInPlanTemplateNames.includes(this.window?.globalDataLayer?.Template) ||  contactFormMetaData?.contactProductContext?.seriesOid === HP_ALL_IN_PLAN_SERIESOID) {
      return this.router.navigate([`/us-en/contact/all-in-plan-serial-number`]);
    }

    //NOTE If we have Product Context and user is on Contact page then No Navigation

    if(!(location.pathname.split('/')[2] === 'contact' && contactFormMetaData?.contactProductContext)) {
        const local = this.contactCClcAlias.hasOwnProperty(`${this.pageConfig.locale}`)? this.contactCClcAlias[`${this.pageConfig.locale}`]:this.pageConfig.locale;// for local which has support for contact page
        if(contactFormMetaData?.contactProductContext) {
          const contextUrl = this.genarateProdContextUrl(contactFormMetaData?.contactProductContext);
          this.router.navigateByUrl(`/${local}/contact/product${contextUrl}`);
        } else {
          this.router.navigate([`/${local}/contact`]);
        }

        if (this.pageConfig.isBrowser) {
          const div = document?.getElementById('drop-down');
          if (div?.classList) {
            div.classList.toggle('open');
          }
        }

        // if(typeof window !== 'undefined'){
        //   window.location.href = `${this.pageConfig.domain}/${local}/contact`;
        // }
    }
  }

  private genarateProdContextUrl(productContext: ContactProductContext) {
    if(!productContext) return '';

    let url = new URL('', this.pageConfig.domain);
    const sku = productContext?.sku?.split('#')?.shift();
    productContext?.sku && url.searchParams.append('sku', sku);
    productContext?.serialNumber && url.searchParams.append('serialnumber', productContext?.serialNumber);
    if(productContext?.modelOid && productContext?.seriesOid) {
      url.pathname = `${productContext?.seriesName}/${productContext.seriesOid}/model/${productContext?.modelOid}`;
    } else if(productContext?.seriesOid) {
      url.pathname = `${productContext?.seriesName}/${productContext?.seriesOid}`;
    }
    return (url.pathname+ url.search);
  }


  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
