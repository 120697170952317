import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, Observable, Observer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageService } from '../common_service/page.service';
import { HttpService } from '../service_loader/http.service';

// Root provider is required
@Injectable({
    providedIn: 'root',
})

export class TermBaseService implements OnDestroy {
    pageConfig: any = this.pageService.config;
    allTranslations = {};
    TermBaseSub = new BehaviorSubject<any>({});
    destroySubject$: Subject<void> = new Subject();

    constructor(
        private pageService: PageService,
        private http: HttpService
    ) {}

    fetchTermTranslations(pageName = null) {
        const isDebugMode = this.pageConfig.queryParams && !(!this.pageConfig.queryParams.displayKey);
        const currentPage = pageName || this.pageConfig.pageName;

        this.http
            .get(`/wcc-services/termbase/${this.pageConfig.cc}-${this.pageConfig.lc}/${currentPage}`)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(result => {
                if (result && result.data) {
                    const rawTranslations = result['data'];
                    const translationKeys = Object.keys(rawTranslations);
                    if (isDebugMode) {
                        for (let i = 0; i < translationKeys.length; i++) {
                            this.allTranslations[translationKeys[i]] = translationKeys[i];
                        }
                    } else {
                        for (const key in rawTranslations) {
                            if (rawTranslations) {
                                this.allTranslations[key] = rawTranslations[key];
                            }
                        }
                    }
                    this.TermBaseSub.next(this.allTranslations);
                }
            });
    }

    fetchTermTranslationsForWC(apiDomain, termBasePath): Observable<any> {
        return new Observable((observer : Observer<any>) => {
            const handleObserver = (value) => {
                observer.next(value);
                observer.complete();
              };
            this.http
            .get(`${apiDomain}/wcc-services/termbase/${termBasePath}`)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(result => {
                if (result && result.data) {
                    const rawTranslations = result['data'];
                    for (const key in rawTranslations) {
                        if (rawTranslations) {
                            this.allTranslations[key] = rawTranslations[key];
                            this.TermBaseSub.next(this.allTranslations);
                        }
                    }
                }
                handleObserver({
                    success: true,
                    wcTranslations : this.allTranslations
                  });
            });
            
        });

    }

    ngOnDestroy() {
        this.destroySubject$.next();
    }
}
