import { SyncStatusActionType,SyncStatusAction  } from './sync-status.actions';

export interface syncStatusDataType {
    data:any;
    dataLoaded:boolean;
    code:number;
    timeElapsedInMS:number;
    status:string;
    message:string;
    error:any;
  }
export const syncStatusInitialState:syncStatusDataType = {
    timeElapsedInMS: 0,
    code: 0,
    status: "",
    message: "",
    data: {},
    dataLoaded:false,
    error:{}
  }
export function SyncStatusReducer(state=syncStatusInitialState,action:SyncStatusAction){
    switch(action.type){
        case SyncStatusActionType.SyncStatus:
            return{
                ...state,
            }
        case SyncStatusActionType.SyncStatusSuccess:
            return{
                ...state,
                ...action.data
            }
        case SyncStatusActionType.SyncStatusFailure:
            return{
                ...state,
                error:action.error
            }
        default :
        return state;
    }
}