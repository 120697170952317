import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appRestrictPattern]'
})

export class AllowPatternDirective {
    @Input() appRestrictPatternFlag;
    @Input() appRestrictPatternValue;

    constructor() {}

    @HostListener('keypress', ['$event']) onKeyPress(event: { which: any; keyCode: any; preventDefault: () => void; }) {
        // get the keyCode pressed from the event.
        const keyCode = event.which || event.keyCode;

        // determine the char from the keyCode.
        const keyCodeChar = String.fromCharCode(keyCode);
        
        // If the keyCode char does not match the allowed Regex Pattern, then don't allow the input into the field.
        if (!keyCodeChar.match(new RegExp(this.appRestrictPatternValue, 'i')) && this.appRestrictPatternFlag === 'true') {
            event.preventDefault();
            return false;
        }
    }
}
