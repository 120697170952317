import { Device_DeviceIDAction, Device_DeviceIDActionType } from './device-deviceId.actions';

export interface Device_DeviceIDData {
    deviceInfo:any,
    dataLoaded:boolean
    code:number;
    isRetiredProduct:boolean;
    allTilesData:any;
    matchHeightInput:any;
    serviceUnavailable:boolean;
    sessionTimedOut:boolean;
    modifiedNickname:string;
    oldPayload:any;
  }
export const deviceIDDataInitialState:Device_DeviceIDData = {
    deviceInfo:{},
    dataLoaded:false,
    code:0,
    isRetiredProduct:false,
    allTilesData:{},
    matchHeightInput:'',
    serviceUnavailable:false,
    sessionTimedOut:false,
    modifiedNickname:'',
    oldPayload:{}
}
export function device_DeviceIDReducer(state=deviceIDDataInitialState,action:Device_DeviceIDAction){
    switch(action.type){
        case Device_DeviceIDActionType.Device_DeviceID:
            return{
                ...state,
            }
        case Device_DeviceIDActionType.Device_DeviceIDSuccess:
            return{
                ...state,
                ...action.data
            }
        case Device_DeviceIDActionType.Device_DeviceIDFailure:
            return{
                ...state,
                error:action.error
            }
        default :
        return state;
    }
}