import { FetchingTermbaseFailedAction, FetchingTermbaseSuccessAction, StartFetchingTermbaseAction, TermBaseActions, TermbaseActionTypes } from "./termbase.actions";


export interface TermbaseKeysModel{
    [key: string]: any
}

export interface TermbaseState{
    loading: boolean;
    dataFetchSuccess: boolean;
    termBaseKeySet: TermbaseKeysModel;
    pageName: string;
}

const initialState: TermbaseState = {
    loading: false,
    dataFetchSuccess: false,
    termBaseKeySet: {},
    pageName: ''
};

export const termbaseReducer = (state:TermbaseState = initialState, action: TermBaseActions):TermbaseState=>{

    switch (action.type){
        case TermbaseActionTypes.FETCHING_TRANSLATIONS_SUCCESS:
            const successAction:FetchingTermbaseSuccessAction = action as FetchingTermbaseSuccessAction;
            let newTermBaseKeySet = {...state?.termBaseKeySet};
            const pageName = successAction?.payload?.pageName;
            newTermBaseKeySet[pageName] = {...successAction.payload.keys};
            return {
                loading: false,
                dataFetchSuccess: true,
                pageName: successAction.payload.pageName,
                termBaseKeySet: newTermBaseKeySet
            };
        case TermbaseActionTypes.START_FETCHING_TRANSLATIONS:
            const startAction:StartFetchingTermbaseAction = action as StartFetchingTermbaseAction;
            return {
                loading: true,
                dataFetchSuccess: false,
                termBaseKeySet: state?.termBaseKeySet,
                pageName: startAction.payload.pageName
            };
        case TermbaseActionTypes.FETCHING_TRANSLATIONS_FAILED:
            const failAction:FetchingTermbaseFailedAction = action as FetchingTermbaseFailedAction;
            return {
                loading: false,
                dataFetchSuccess: false,
                pageName: failAction.payload.pageName,
                termBaseKeySet: state?.termBaseKeySet
            };
        default:
            return state;
    }
};