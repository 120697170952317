import {UrlSerializer, UrlTree, DefaultUrlSerializer} from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
    public defaultUrlSerializer = new DefaultUrlSerializer();
    parse(url: any): UrlTree {
        return this.defaultUrlSerializer.parse(url);
    }
    serialize(tree: UrlTree) {
        const serializedUrl = this.defaultUrlSerializer.serialize(tree);
        return serializedUrl.replace(/%2F/gi, '/');
    }
}