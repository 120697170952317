import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { HpHeaderService } from './hp-header.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PageService } from '../../../services/common_service/page.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-hp-header',
  templateUrl: './hp-header.component.html',
  styleUrls: ['./hp-header.component.scss'],
  providers:[HpHeaderService]
  
})

export class HpHeaderComponent implements OnInit, OnDestroy{
  // Page related config
  pageConfig: any = this.pageService.config;
  destroySubject$: Subject<void> = new Subject();
  disableSecNav:boolean;
  disableAlert:boolean;
  isPrivacyBannerVisible = true;
  privacyBannerRetryCount = 0;
  PRIVACY_BANNER_MAX_RETRY = 10;
  hideSearchTypeahead: boolean;

  constructor(private hpHeaderService:HpHeaderService, 
    private pageService:PageService,
    @Inject(DOCUMENT) private document ) {}

  ngOnInit() {
    this.hpHeaderService.getHeaderSSC()
    .pipe(takeUntil(this.destroySubject$))
    .subscribe((res: any) => {
      if (res && res.success) {
        this.hideSearchTypeahead = res?.hideSearchTypeahead;
        this.disableAlert = res.alertEnabled.indexOf(this.pageConfig.templateName)>-1;
        this.disableSecNav =res.secNavEnabled.indexOf(this.pageConfig.templateName)>-1;
      }
    });
    this.loadPrivacyBanner();
  }

  loadPrivacyBanner() {
    this.hpHeaderService.getPrivacyBannerSSC()
    .pipe(takeUntil(this.destroySubject$))
    .subscribe((res: any) => {
      if (res?.success) {
        this.isPrivacyBannerVisible = 
          res?.privacyBannerSupportedLocales?.indexOf(`${this.pageConfig.cc}-${this.pageConfig.lc}`) > -1 ? true : false;
        if (this.isPrivacyBannerVisible) {
          this.loadPrivacyBannerScript(res.privacyBannerUrl);
        }
        else {
          if (typeof localStorage !== "undefined") {
            localStorage.removeItem('privacyBannerSeen');
          }
        }
      }
    });
  }
  loadPrivacyBannerScript(privacyBannerUrl) {
    let node = this.document.createElement('script');
    node.src = privacyBannerUrl;
    node.type = 'text/javascript';
    node.addEventListener("load", (event) => {
      this.modifyPrivacyBanner();
    });
    const headerEl = this.document.getElementById('privacyBanner');
    headerEl.appendChild(node);
    headerEl.classList.add('headerWithPrivacyBanner');
  }

  // Modify Privacy banner elements to make them accessible
  modifyPrivacyBanner() {
    setTimeout(() => {
      const desktopPrivacyLink = this.document.querySelectorAll('.privacy-nb .desktop p a');
      desktopPrivacyLink?.[0]?.setAttribute("target", "_blank");
      desktopPrivacyLink?.[0]?.setAttribute("tabindex", "0");
      
      const mobilePrivacyLink = this.document.querySelectorAll('.privacy-nb .mobile p a');
      mobilePrivacyLink?.[0]?.setAttribute("target", "_blank");
      mobilePrivacyLink?.[0]?.setAttribute("tabindex", "0");
      
      const closeButtonDesktop = this.document.querySelectorAll('.privacy-nb .desktop > a');
      closeButtonDesktop?.[0]?.setAttribute("tabindex", "0");
      closeButtonDesktop?.[0]?.classList.add("closeBanner");
      closeButtonDesktop?.[0]?.addEventListener( "keyup", (event) => {
        event.preventDefault();
        if (event.keyCode === 13) {
          closeButtonDesktop[0].click();
        }
      });
      const closeButtonMobile = this.document.querySelectorAll('.privacy-nb .mobile > a');
      closeButtonMobile?.[0]?.setAttribute("tabindex", "0");
      closeButtonMobile?.[0]?.classList.add("closeBanner");

      // Fallback - in case script does not load in 500ms
      const isBannerLoaded = ((closeButtonDesktop && closeButtonDesktop?.[0])
                              || (closeButtonMobile && closeButtonMobile?.[0])) == undefined;
      if (isBannerLoaded && this.privacyBannerRetryCount <= this.PRIVACY_BANNER_MAX_RETRY) {
        this.modifyPrivacyBanner();
        this.privacyBannerRetryCount++;
      }
    }, 400);
  }

  ngOnDestroy(){
    this.destroySubject$.next();
  }
}
