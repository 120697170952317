
import { createSelector } from '@ngrx/store';
import { AppState } from '../app.reducer';


// Selector to get the searchResultsResponse from the state
export const getSearchResultsState = createSelector(
  (state: AppState) => state.searchResultsState,
  (searchResultsState) => searchResultsState
);
export const getSearchResultsResponse = createSelector(
  (state: AppState) => state.searchResultsState,
  (searchResultsResponse) => searchResultsResponse
);

// Selector to check if the searchResultsResponse is loading
export const isSearchResultsResponseLoading = createSelector(
  (state: AppState) => state.searchResultsState.loading,
  (loading) => loading
);

// Selector to check if the searchResultsResponse has error
export const hasSearchResultsResponseError = createSelector(
  (state: AppState) => state.searchResultsState.error,
  (error) => error !== null
);
