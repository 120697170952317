import { ChangeDetectorRef, EmbeddedViewRef, Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'memoize',
    pure: true,
    standalone: true
})
export class MemoizePipe implements PipeTransform {
    constructor(
        @Inject(ChangeDetectorRef) private readonly cdr: EmbeddedViewRef<unknown>,
    ) {}

    /**
     * Transforms a function call by applying memoization. 
     * Function is called with the provided arguments and the result is memoized. 
     * 
     * Function is called only if the arguments are different from the previous call.
     * 
     * 
     * @usageNotes This pipe should be used with at least one argument.
     * 
     * And object properties using `this` should not be used in the function. Instead use the function arguments for the same.
     * If `this` is used, then function will be not triggered on change detection for the object properties.
     * 
     */
    transform<T extends (...args: unknown[])=>unknown>(fn:T, ...args: Parameters<T>): ReturnType<T> {
        return fn.apply(this.cdr.context, args);
    }
}