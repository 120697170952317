import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { mergeMap, withLatestFrom, map } from 'rxjs/operators';
import { PageService } from '../../services/common_service/page.service';
import * as DeviceWarrantyAction from "../device-warranty-store/device-warranty.actions";
import { AppState } from '../app.reducer';
import { cloneDeep, isEqual } from "lodash-es";
import { DeviceWarrantySelector } from './device-warranty.selectors';
import { DeviceCardService } from '../../pages/dashboard/device-card/device-card.service';
@Injectable()

export class DeviceWarrantyEffects{
    pageConfig: any = this.pageService.config;
    constructor(
        private actions$: Actions,
        private pageService: PageService,
        private store : Store<AppState>,
        private deviceCardService: DeviceCardService
    ){}
    getDeviceWarrantyData = createEffect(()=>
        this.actions$.pipe(
            ofType(DeviceWarrantyAction.DeviceWarrantyActionType.DeviceWarranty),
            withLatestFrom(this.store.select(DeviceWarrantySelector)),
            mergeMap(([action,deviceWarrantyData]:[any, any])=>{
                const oldPayload = deviceWarrantyData?.oldPayload;
                if(deviceWarrantyData.dataLoaded === false ||  (!isEqual(action?.payload,oldPayload))){
                  return  this.deviceCardService.checkWarrantyStatusNew(action?.payload?.refresh,action?.payload?.deviceId,action?.payload?.offset,action?.payload?.warrantyWidgetLoadingScreen)
                        .pipe(map(res=>{
                            let cloneResponse = cloneDeep(res);
                            if(res?.data?.code?.toString() === "102" || res?.data?.code?.toString() === "424"){
                                return  new DeviceWarrantyAction.LoadDeviceWarrantySuccess({...cloneResponse,oldPayload:cloneDeep(action?.payload),code:res?.data?.code,dataLoaded:false});
                            }
                            else{
                                return  new DeviceWarrantyAction.LoadDeviceWarrantySuccess({...cloneResponse,oldPayload:cloneDeep(action?.payload),code:res?.data?.code,dataLoaded:true});
                            }
                            },err=>{
                                return of(new DeviceWarrantyAction.LoadDeviceWarrantyFailure({...err,dataLoaded:false}));
                            }))
                }
                else{
                    let cloneResponse = cloneDeep(deviceWarrantyData);
                    return of(new DeviceWarrantyAction.LoadDeviceWarrantySuccess({...cloneResponse,code:cloneResponse?.data?.code,dataLoaded:true}))
                }
               
            })
        )
    );
}