import { AppBrowserModule } from '.././src/app/app.browser.module';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { register } from 'swiper/element/bundle';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = ()=>{};
    window.console.warn = ()=>{};
  }

}

register();

platformBrowserDynamic().bootstrapModule(AppBrowserModule)
  .catch(err => console.error(err));

