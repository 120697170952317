// create acitons for VideoDetails store for collectionIds

import { Action } from "@ngrx/store";
import { CollectionVideosData } from "./video-details.model";

export enum VideoDetailsActionTypes {
    LoadVideoDetails = '[VideoDetails] Load VideoDetails',
    LoadVideoDetailsSuccess = '[VideoDetails] Load VideoDetails Success',
    LoadVideoDetailsFailure = '[VideoDetails] Load VideoDetails Failure',
}

export interface VideoDetailsPayload {
    collectionIds: string;
}

export class LoadVideoDetails implements Action {
    readonly type = VideoDetailsActionTypes.LoadVideoDetails;
    constructor(public payload: VideoDetailsPayload) {}
}

export class LoadVideoDetailsSuccess implements Action {
    readonly type = VideoDetailsActionTypes.LoadVideoDetailsSuccess;
    constructor(public response: CollectionVideosData) {}
}

export class LoadVideoDetailsFailure implements Action {
    readonly type = VideoDetailsActionTypes.LoadVideoDetailsFailure;
    constructor(public error: any) {}
}

export type VideoDetailsActions = LoadVideoDetails | LoadVideoDetailsSuccess | LoadVideoDetailsFailure;