import { createSelector } from '@ngrx/store';
import { AppState } from '../app.reducer';

export const AllDeviceData = (state: AppState) => state.allDeviceData;
export const allDeviceSelector = createSelector(
    AllDeviceData,
    state => state
);
export const deviceListSelector = createSelector(
    AllDeviceData,
    state => state?.data?.deviceList || []
);