// create ngrx selector to get data from store
// Path: hp-wcc\src\app\store\regions\regions.selectors.ts
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RegionsState } from './regions.reducer';
export const getRegionsState = createFeatureSelector<RegionsState>('Regions');
export const getRegionsLoaded = createSelector(
    getRegionsState,
    (state: RegionsState) => state.loaded
);
export const getRegionsError = createSelector(
    getRegionsState,
    (state: RegionsState) => state.error
);
export const getAllRegions = createSelector(
    getRegionsState,
    getRegionsLoaded,
    (state: RegionsState, isLoaded) => {    
        return isLoaded ? state.regions : [];
    }
);

export const getRegionsLoading = createSelector(
    getRegionsState,
    (state: RegionsState) => state.loading
);

// create selectors for CLC regions
// Path: hp-wcc\src\app\store\regions\regions.selectors.ts
import { CLCRegionsState } from './regions.reducer';
export const getCLCRegionsState = createFeatureSelector<CLCRegionsState>('CLCRegions');
export const getCLCRegionsLoaded = createSelector(
    getCLCRegionsState,
    (state: CLCRegionsState) => state.loaded
);
export const getCLCRegionsParams = createSelector(
    getCLCRegionsState,
    (state: CLCRegionsState) => state.params
);
export const getCLCRegionsError = createSelector(
    getCLCRegionsState,
    (state: CLCRegionsState) => state.error
);
export const getCLCRegionsLoading = createSelector(
    getCLCRegionsState,
    (state: CLCRegionsState) => state.loading
);

export const getAllCLCRegions = createSelector(
    getCLCRegionsState,
    getCLCRegionsLoaded,
    getCLCRegionsLoading,
    (state: CLCRegionsState, isLoaded, isLoading) => {
        return isLoaded ? state.regions : [];
    }
);


