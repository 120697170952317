import { Action } from "@ngrx/store";
import { TermbaseKeysModel } from "./termbase.reducer";

export enum TermbaseActionTypes {
    START_FETCHING_TRANSLATIONS = '[termbase.action.ts] START_FETCHING_TRANSLATIONS',
    FETCHING_TRANSLATIONS_SUCCESS = '[termbase.action.ts] FETCHING_TRANSLATIONS_SUCCESS',
    FETCHING_TRANSLATIONS_FAILED = '[termbase.action.ts] FETCHING_TRANSLATIONS_FAILED'
}

export class StartFetchingTermbaseAction implements Action{
    readonly type: string = TermbaseActionTypes.START_FETCHING_TRANSLATIONS;

    constructor(
        public payload: {
            pageName?: string
        }
    ){}
}

export class FetchingTermbaseSuccessAction implements Action{
    readonly type: string = TermbaseActionTypes.FETCHING_TRANSLATIONS_SUCCESS;

    constructor(
        public payload: {
            keys?: TermbaseKeysModel,
            pageName?: string
        }
    ){}
}

export class FetchingTermbaseFailedAction implements Action{
    readonly type: string = TermbaseActionTypes.FETCHING_TRANSLATIONS_FAILED;
    
    constructor(
        public payload: {
            pageName?:string
        }
    ){}
}

export type TermBaseActions = StartFetchingTermbaseAction | FetchingTermbaseSuccessAction | FetchingTermbaseFailedAction;