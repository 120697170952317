import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { environment } from '../../../environments/environment';
// import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})

export class LaunchDarklyService {
  ldClient:LDClient;
  flags:LDFlagSet;
  flagChange  = new Subject<any>();

  constructor(private ngZone: NgZone){}

  initializeFlags(customerId) {
    if (typeof window !== 'undefined') {
      
      /**
       * `initialize()` method is called outside of Angular zone to avoid change detection
       * 
       * initialize() is calling setTimeout() internally which triggers change detection
       * setTimeout() is being called countinously with ~500ms interval
       * 
       * It can be run outside angular zone as it is not responsible for any state change in application
       * 
       * This is will save us from unnecessary change detection cycles
       */
      this.ngZone.runOutsideAngular(async() => {

        try {
          await this.ldClient?.waitUntilReady();
        } catch (err) {
          console.log("LaunchDarkly : Flags initialization failed!");
        }

        this.ldClient = await initialize(environment.launchDarklySDKKey, 
          { 
            key: "WCC_User", name : customerId, anonymous: true 
          });

        await this.ldClient.on('ready', async () => {
          this.setFlags();
        });
        
      });
  
      
    } else { 
      console.log("LaunchDarkly : Flags initialization failed since no window object was found!");
    }

  }

  isLDReady() : Promise<any> {
    return this.ldClient?.waitUntilReady();
  }

  setFlags() {
    this.flags = this.ldClient.allFlags();
  }
 }
