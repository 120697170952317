import { Inject, Injectable } from '@angular/core';
import getSvgString from './svg-storage';
import { DOCUMENT } from '@angular/common';
import { CommonService } from '../services/common_service/common.service';

// Root provider is required
/*
  
    Service to load SVG into HTML
    Note: 
      * using CSS all styles can be updated of SVG (Add CSS in common CSS file like *base.scss, adding in component will be blocked by View Encapsulation of Angular)
      * If SVG is not found in storage file then it will be searched in AEM with path (/wcc-assets/content/dam/hp-wcc/headless-assets/images/svg-vault/) and will render on page inside img tag

*/

@Injectable({
  providedIn: 'root'
})
export class SvgVaultService {

  constructor(
    @Inject(DOCUMENT) private document:Document,
    private commonService:CommonService
  ){}

  private getIcon(key:string):string{
    return getSvgString(key) || null;
  }

  public isSvgExists(key:string):boolean{
    return this.getIcon(key) !== null;
  }

  private renderSvg(){
    const foundSvgTags = this.document.body.querySelectorAll('.svg-vault:not(:has(*))');
      
    foundSvgTags.length && foundSvgTags.forEach(tag=>{
      this.renderTag(tag as HTMLElement);
    });
      
  }

  public triggerSvgRender(timeout:number=100){
    setTimeout(()=>{
      this.renderSvg();
    }, timeout);
  }

  public renderTag(tag: HTMLElement, defaultLink:string=null){
    if(tag){
      const classList:string[] = Array.from(tag.classList);
      const svgId = classList.filter(val=>val!=='svg-vault' && /^svg-/.test(val))[0];

      if(svgId){
        const imgSvg = this.getIcon(svgId);
        if(imgSvg){
          tag.innerHTML = imgSvg;
        }else{
          if(defaultLink){
            tag.innerHTML = `<img src='${defaultLink}' alt='${svgId.replace(/-|svg/g, ' ').trim()}'/>`;
          }else{
            tag.innerHTML = `<img src='/wcc-assets/content/dam/hp-wcc/headless-assets/images/svg-vault/${svgId}.svg' alt='${svgId.replace(/-|svg/g, ' ').trim()}'/>`;
          }
        }
      }else{
        if(defaultLink){
          tag.innerHTML = `<img src='${defaultLink}'/>`;
        }
      }

      this.commonService.addViewEncapsulation(tag);

    }
  }

  


}

