import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { PageService } from "../../services/common_service/page.service";
import { HttpService } from "../../services/service_loader/http.service";
import { FetchingAlertsFailedAction, FetchingAlertsSuccessAction, StartFetchingAlertsAction, AlertsActionTypes } from "./alerts.actions";
import { AlertsDataModel } from "./alerts.reducer";

@Injectable()
export class AlertsEffects {

  pageConfig: any = this.pageService.config;

  constructor(
    private actions: Actions,
    private http: HttpService,
    private pageService: PageService,
  ) { }

  alertsFetch = createEffect(() => {
    return this.actions.pipe(
      ofType(AlertsActionTypes.START_FETCHING_ALERTS),
      switchMap((alertsAction: StartFetchingAlertsAction) => {
        return this.http
          .post(`/wcc-services/cms-v2/alerts`, alertsAction.payload).pipe(
            map(
              (result: any) => {
                if (result && result.data) {
                  let alerts: AlertsDataModel[] = result.data || [];
                  return new FetchingAlertsSuccessAction({
                    alerts: alerts
                  });
                } else {
                  throwError(0);
                }
              }
            ),
            catchError(error => {
              return of(new FetchingAlertsFailedAction());
            })
          )
      })
    );
  });

}
