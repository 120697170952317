import { Action } from "@ngrx/store";

export enum SyncStatusActionType{
    SyncStatus = '[sync status api] load',
    SyncStatusSuccess = '[sync status api] success',
    SyncStatusFailure = '[sync status api] failure',
}
export class LoadSyncStatus implements Action{
    readonly type = SyncStatusActionType.SyncStatus;
    constructor(public payload:any){};
}
export class LoadSyncStatusSuccess implements Action{
    readonly type = SyncStatusActionType.SyncStatusSuccess;
    constructor(public data:any){};
}
export class LoadSyncStatusFailure implements Action{
    readonly type = SyncStatusActionType.SyncStatusFailure;
    constructor(public error:any){};
}

export type SyncStatusAction = LoadSyncStatus | LoadSyncStatusSuccess | LoadSyncStatusFailure;

