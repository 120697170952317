import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class SEOService {
  constructor(
    private meta: Meta,
    private titleService: Title
  ) {}

  setTitle(newTitle) {
    this.titleService.setTitle(newTitle);
  }

  addMetaTagIndex() {
    this.updateMetaTagIndex('INDEX, FOLLOW');
  }

  addMetaTagNonIndex() {
    this.updateMetaTagIndex('NOINDEX, FOLLOW');
  }

  updateMetaTagIndex(desc: string) {
    this.meta.updateTag({ name: 'robots', content: desc });
  }

  updatePageDescription(content: string){
    this.meta.updateTag({ name: 'description', content});
  }

  updatePageKeyswords(content: string){
    this.meta.updateTag({ name: 'keywords', content});
  }
}
