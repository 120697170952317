import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Observable, forkJoin } from 'rxjs';
import { HttpService } from '../service_loader/http.service';
import { WindowRefService } from '../window/window.service';
import { first, takeUntil } from 'rxjs/operators';
import { PageService } from './page.service';
import { CommonService } from './common.service';
import { HttpHeaders } from '@angular/common/http';
import { ScriptLoaderService } from '../script_loader/scriptLoader.service';
import { GlobalDataLayerService } from '../../services/common_service/globalDataLayer.service';

import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { ConfigKeysModel } from '../ssc-store/sscKeys.reducer';
import { isEmpty } from "lodash-es";
import { sscKeysSelector } from '../ssc-store/config.selectors';

// Root provider is required
@Injectable({
  providedIn: 'root',
})

export class AuthService implements OnDestroy {
  // Page related config
  pageConfig: any = this.pageService.config;

  isUserAuthenticated;
  private window: Window;

  profileCallInProgress;

  userDataSub = new BehaviorSubject<any>({});
  destroySubject$: Subject<void> = new Subject();
  fallBackCC;
  fallBackLC;

  //Related To SSO 
  isNavAuthienticated = new Subject();
  isHeaderSignInStatusChecked: boolean;
  logoutUrl: string;
  globalNavJsEndpoint: string;
  sscKeys: any;

  constructor(
    private pageService: PageService,
    private commonService: CommonService,
    private http: HttpService,
    private windowRef: WindowRefService,
    private scriptLoader: ScriptLoaderService,
    private globalDataLayerService: GlobalDataLayerService,
    private store: Store<AppState>
  ) {
    this.window = this.windowRef.nativeWindow;

  }


  fetchSSOCheckApiData() {
    const getSSOCheckApiData = () => new Observable((observer: any) => {
      const browser = this.pageConfig.browser.toLowerCase();
      let headerParam = (browser === 'ie') ?
        {
          'Content-Type': 'text/plain',
          //Added to make sign in work in IE 
          'cache': 'false',
          'Expires': 'Tue, 01 Jan 1980 1:00:00 GMT',
          'Pragma': 'no-cache',
          'skipLoadingScreen':'true'
        } :
        { 'Content-Type': 'text/plain' ,
          'skipLoadingScreen':'true'
        }
        
      const httpOptions = {
        headers: new HttpHeaders(headerParam),
        withCredentials: true,
        observe: 'response' as 'response',
      };

      this.store.select(sscKeysSelector)
      .pipe(first(res=> !isEmpty(res)))
      .subscribe((sscKeys: ConfigKeysModel) => {
          if (sscKeys &&
            sscKeys.GLOBAL_NAV_SSO_CHECK_ENDPOINT &&
            sscKeys.GLOBAL_NAV_SSO_CHECK_ENDPOINT.keyValue) {
            this.sscKeys = sscKeys;
            
            this.http.get(sscKeys.GLOBAL_NAV_SSO_CHECK_ENDPOINT.keyValue, httpOptions)
              .pipe()
              .subscribe((res: any) => {
                // this.logoutUrl = sscKeys?.GLOBAL_NAV_LOGOUT_URL?.keyValue || '';
                this.logoutUrl = '/wcc-services/s/logout?cc={cc}&lc={lc}&pageurl={pageurl}';
                this.loadGlbalheaderFooterJs();
                
                observer.next(res);
                observer.complete();
              },
                ((err: any) => {
                  this.loadGlbalheaderFooterJs();
                  observer.next({
                    "sessionIsValid": false,
                    "user": null
                  });
                  observer.complete();
                }))
        }
      });

    });
    return getSSOCheckApiData();
  }
  forkJoinSSOCheckNProfile(): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('skipLoadingScreen', 'true');
    const requests = [this.fetchSSOCheckApiData(),
    this.http.get(`/wcc-services/s/profile/user/v2`, {headers})];
    return forkJoin(requests);
  }
  getAuthObject() {
    const emitSessionExpiredRes = () => {
      const sessionExpiredObj = {
        state: "sessionExpired",
        profileType: "Anonymous"
      };

      const sessionExpiredDevicesData = {
        data:{
          customerState:'Anonymous'
        }
      }

      this.updateCustomerState('Anonymous');      
      this.userDataSub.next(sessionExpiredObj);
      this.commonService.devicesDataSub.next(sessionExpiredDevicesData);
    }

    if (this.pageConfig.isBrowser) {
      this.forkJoinSSOCheckNProfile()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          const ssoCheckResponse = res[0].body;
          const profileResponse = res[1];
          //If SSO session is Valid and Profile call has data
          if (profileResponse && profileResponse.code === 200 && profileResponse.data) {
            if (ssoCheckResponse && ssoCheckResponse.sessionIsValid) {// Profile call is success
              const profileData = profileResponse.data;
              this.updateCustomerState(profileData.customerState);
              sessionStorage.removeItem('ssoRefreshCounter');
              profileData.firstName = profileResponse.data.firstName;
              profileData.lastName = profileResponse.data.lastName;
              profileData.customerId = profileResponse.data.id;
              this.profileCallInProgress = false;
              this.isUserAuthenticated = true;
              this.userDataSub.next(profileData);
            } else {
              this.isUserAuthenticated = false;
              this.profileCallInProgress = false;
              emitSessionExpiredRes();
              this.logoutSub();
            }
          } else {
            if (ssoCheckResponse && ssoCheckResponse.sessionIsValid) {
              this.silentSSOCheck();
            } else {
              this.isUserAuthenticated = false;
              this.profileCallInProgress = false;
              emitSessionExpiredRes();
            }
          }

        },
          (err: any) => {
            this.profileCallInProgress = false;
            this.isUserAuthenticated = false;
            emitSessionExpiredRes();
            this.logoutSub()
          });
    }
    //Added to fix Printersetup SEO Issue
    else{
      emitSessionExpiredRes();
    }
  }

  updateCustomerState(customerState?: string) {
    this.globalDataLayerService.updateGDL({ customerState }, true);
  }

  //Load Global Nav
  loadGlbalheaderFooterJs() {
    if (this.sscKeys && this.sscKeys.GLOBAL_NAV_JS_END_POINT && this.sscKeys.GLOBAL_NAV_JS_END_POINT.keyValue) {
      const url = new URL(`${this.sscKeys.GLOBAL_NAV_JS_END_POINT.keyValue}${this.pageConfig.countryLanguageCode}/default/latest.r?contentType=js`);

      url.searchParams.set('hide_search', this.sscKeys?.HEADER_HIDE_SEARCH?.keyValue || 'false');
      url.searchParams.set('show_shopping_cart', this.sscKeys?.HEADER_SHOW_SHOPPING_CART?.keyValue || 'true');
      url.searchParams.set('search_section_order', this.sscKeys?.HEADER_SEARCH_OPTION_ORDER?.keyValue || 'support-shop-explore');
      url.searchParams.set('hide_country_selector', this.sscKeys?.HEADER_HIDE_COUNTRY_SELECTOR?.keyValue || 'true');
      url.searchParams.set('show_sign_in', this.sscKeys?.HEADER_SHOW_SIGN_IN?.keyValue || 'true');

      this.globalNavJsEndpoint = url.href;

      if (!this.isScriptAlreadyIncluded(this.globalNavJsEndpoint)) {
        new Promise((resolve) => {
          this.scriptLoader.loadScript([this.globalNavJsEndpoint]);
          resolve(true);
        });
      }
      this.signinExp();
    }
  }

  isScriptAlreadyIncluded(src) {
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('src') == src) return true;
    }
    return false;
  }
  getAuthStatus() {
    this.getAuthObject();
    this.profileCallInProgress = true;

    const promise = new Promise(
      (resolve) => {
        this.userDataSub.subscribe(
          (userData) => {
            if ((this.isUserAuthenticated == true || this.isUserAuthenticated == false) && this.profileCallInProgress === false) {
              // console.log('Auth Service --- this.isUserAuthenticated --', this.isUserAuthenticated, userData);
              let userSessionState = this.isUserAuthenticated ? { state: "authenticated" } : { state: "sessionExpired" }
              // console.log('Auth Service --- userSessionState --', userSessionState);
              resolve(userSessionState);
            }
          }
        );
      }
    );

    return promise;
  }

  logoutSub() {
    const url = `/wcc-services/s/logout?cc=${this.pageConfig.cc}&lc=${this.pageConfig.lc}&pageurl=${encodeURIComponent(this.window?.location?.href)}`;
    this.http.get(url, { responseType: 'text' })
    .subscribe((res) => {
        this.globalDataLayerService.updateGDL({ customerState: 'Anonymous' });
        // console.log('User Logged out!');
      });
  }



  silentSSOCheck() {
    const refreshCounter = sessionStorage.getItem('ssoRefreshCounter') || 0;

    if (+refreshCounter >= 1) {
      sessionStorage.removeItem('ssoRefreshCounter');

      const sessionExpiredObj = {
        state: "sessionExpired",
        profileType: "Anonymous"
      };

      this.isUserAuthenticated = false;
      this.userDataSub.next(sessionExpiredObj);

      return;
    }


    const fallBackCCLC = this.sscKeys.COMMON_FALLBACK_COUNTRY_CODE && this.sscKeys.COMMON_FALLBACK_COUNTRY_CODE.keyValue
        ? this.commonService.getCustomLocale(this.commonService.safeParseJSON(this.sscKeys.COMMON_FALLBACK_COUNTRY_CODE.keyValue), this.pageConfig)
        : null;

    this.fallBackCC = fallBackCCLC ? fallBackCCLC['cc'] : this.pageConfig.cc;
    this.fallBackLC = fallBackCCLC ? fallBackCCLC['lc'] : this.pageConfig.lc;

    var url = `${this.pageConfig.domain}/wcc-services/s/signin?cc=${this.fallBackCC}&lc=${this.fallBackLC}&silentCheck=true&pageurl=${encodeURIComponent(this.window?.location?.href)}`;

    sessionStorage.setItem('ssoRefreshCounter', (+refreshCounter + 1).toString());
    window?.open(url, "_self");
  }

  skipLinkEventListner(){
    if(typeof document !== 'undefined'){
      const skipLinkList =  document.querySelectorAll('.js-skip-link');
    if(skipLinkList.length > 0) {
      Array.from(skipLinkList).forEach((skipLink: HTMLElement) => {
        skipLink.addEventListener('click', (event) => {
         event.preventDefault();
         const targetId = skipLink.getAttribute('href');
         const target = document.getElementById(targetId.split('#')[1]);
         if (target) {
          target.scrollIntoView({ behavior: 'smooth', block: 'start' });
          // Ensure the target can be focused
          target.setAttribute('tabindex', '-1');
          target.focus();
          // Optionally, set tabindex="0" for focusable elements within the target section
          this.setTabIndexForFocusableElements(target, '0');
         }
       })
      })
    }
    }
  }

  // Helper method to set tabindex for focusable elements within a target section
  setTabIndexForFocusableElements(target: HTMLElement, tabIndexValue: string) {
    const focusableSelectors = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const focusableElements = target.querySelectorAll(focusableSelectors);
    focusableElements.forEach(element => {
      element.setAttribute('tabindex', tabIndexValue);
    });
}

  signinExp() {
    let fallBackCC;
    let fallBackLC;
    let redirectionLink = "", newRedirection = "";
    
    const updateButtonHref = (button) => {
      this.skipLinkEventListner();
      this.isHeaderSignInStatusChecked = true;
      const linkId = button.getAttribute('data-link-id');
      const buttonIdsToUpdate = ['signInID', 'signInID-LogOut'];

      if (buttonIdsToUpdate.includes(linkId)) {
        const isSignedIn = button?.getAttribute('data-link-id') === 'signInID-LogOut';
        const signInRedirection = this.pageConfig?.signInRedirection || {};
        const templateName = this.pageConfig?.templateName;
        const urlPrefix = isSignedIn
                          ? `${this.pageConfig?.domain}${this.logoutUrl
                                  ?.replace(/{cc}/gi, this.pageConfig.cc)
                                  .replace(/{lc}/gi, this.pageConfig.lc)
                                  .replace(/{pageurl}/gi, encodeURIComponent(this.window?.location?.href))}`
                          : `${this.pageConfig?.domain}/wcc-services/s/signin?cc=${fallBackCC}&lc=${fallBackLC}&pageurl=`;

        if (!isSignedIn && !isEmpty(signInRedirection) && signInRedirection[templateName] && !this.isUserAuthenticated) {
          // set redirection when redirection link is other than current page and user not logged in
          redirectionLink = this.window?.location?.origin + signInRedirection[templateName];
          newRedirection = `${urlPrefix}${encodeURIComponent(redirectionLink)}`;
        }

        let url = `${urlPrefix}${!isSignedIn ? encodeURIComponent(window?.location?.href) : ''}`;

        this.userDataSub
          .pipe(first(), takeUntil(this.destroySubject$))
          .subscribe(res => {
            if (!res.customerId && signInRedirection[templateName]) {
              url = newRedirection;
              button.href = this.commonService.sanitizeUntrustedData(newRedirection);
            } else {
              button.href = this.commonService.sanitizeUntrustedData(url);
            }
          });
      }
    }

    fallBackCC = this.sscKeys.COMMON_FALLBACK_COUNTRY_CODE && this.sscKeys.COMMON_FALLBACK_COUNTRY_CODE.keyValue ?
      this.commonService.getCustomLocale(this.commonService.safeParseJSON(this.sscKeys.COMMON_FALLBACK_COUNTRY_CODE.keyValue), this.pageConfig)['cc'] : this.pageConfig.cc;
    fallBackLC = this.sscKeys.COMMON_FALLBACK_COUNTRY_CODE && this.sscKeys.COMMON_FALLBACK_COUNTRY_CODE.keyValue ?
      this.commonService.getCustomLocale(this.commonService.safeParseJSON(this.sscKeys.COMMON_FALLBACK_COUNTRY_CODE.keyValue), this.pageConfig)['lc'] : this.pageConfig.lc;

    if (typeof document !== 'undefined' && typeof window !== 'undefined' && this.isScriptAlreadyIncluded(this.globalNavJsEndpoint)) {
      const header = document.getElementById('header');

      const subscribeToHrefChanges = (button) => {
        const subtreeObserver = new MutationObserver((subtreeMutations) => {
          subtreeMutations.forEach(function (m) {
            if (m.type === 'attributes' && m.attributeName === 'href') {
              updateButtonHref(button);
              subtreeObserver.disconnect();
            }
          });
        });

        subtreeObserver.observe(button, {
          attributes: true
        });
      }

      const updateSignInBtns = () => {
        const signInButtons = header.querySelectorAll('.signin-button, .signoutButton');
        signInButtons.forEach(button => {
          if (!this.isHeaderSignInStatusChecked) {
            // update on initial load
            subscribeToHrefChanges(button);
          } else {
            // update on router navigation
            updateButtonHref(button);
          }
        })
      }

      if (!this.isHeaderSignInStatusChecked) {
        const observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.type === 'childList') {
              mutation.addedNodes.forEach((addedNode: any) => {
                const signInButtons = addedNode?.querySelectorAll('.signin-button, .signoutButton');
                if (signInButtons.length) {
                  updateSignInBtns();
                  observer.disconnect();
                }
              })
            }
          })
        });

        observer.observe(header, {
          childList: true
        });
      } else {
        updateSignInBtns();
      }
    }
  }
  debounce(func, wait) {
    let timeout;
    return function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {func.apply(this, args)}, wait);
    };
  }
  ngOnDestroy() {
    if (this.pageConfig.isBrowser) {
      this.destroySubject$.next();
    }
  }
}