import { createSelector } from '@ngrx/store';
import { cloneDeep, intersection } from "lodash-es";
import { AppState } from '../../../../store/app.reducer';
import { PDPConfig } from '../../config/pdp.config';
import { sscKeysSelector } from '../../../../services/ssc-store/config.selectors';

export const selectSupportCategoriesObj = (state: AppState) => state.pdpFeature.supportCategories;

export const selectSupportCategories = (state: AppState) => state.pdpFeature.supportCategories?.categories;

export const selectRecommendedSolutions = (state: AppState) => state.pdpFeature.recommendedSolutions;

export const selectProductSpecs = (state: AppState) => state.warrantySpecsDataStore?.warrantySpecsData?.data?.devices?.[0]?.productSpecs;

export const ProductWarrantySelector = (state: AppState) => state.warrantySpecsDataStore?.warrantySpecsData?.data?.devices?.[0]?.warranty;

export const SpecsDataFirstDeviceSelector = (state: AppState) => state.warrantySpecsDataStore?.warrantySpecsData?.data?.devices?.[0];

export const selectSSCKeys = (state: AppState) => state.sscKeys;

export const selectSupportCategoriesList = createSelector(
    selectSupportCategories,
    (state) => {
        return state;
    }
);

export const selectSupportCategoriesState = createSelector(
    selectSupportCategoriesObj,
    (state) => {
        return state;
    }
);


export const selectRecommendedSolutionsList = createSelector(
    selectRecommendedSolutions,
    (state) => {
        return { recommendedSolutions: state?.recommendedSolutions, timestamp: state.timestamp} ;
    }
);

export const categoryPageTemplate = (state: AppState) => state.pdpFeature.categoryPageTemplate;

export const selectCategoryPageTemplateData = createSelector(
    categoryPageTemplate,
    (state) => {
        return { categoryPageTemplateDetails: state?.categoryPageTemplateDetails} ;
    }
);

export const selectLaserJetProduct = createSelector(
    selectSupportCategoriesList,
    selectProductSpecs,
    sscKeysSelector,
    (categoriesList, productDetails, sscKeys) => {
        const laserPrinterIds = sscKeys?.keys?.['PDP_LASER_JET_PRINTER_ID']?.keyValue ? sscKeys?.keys?.['PDP_LASER_JET_PRINTER_ID']?.keyValue?.split(',') : [];
        if(categoriesList
            && productDetails?.customHierarchySet?.length
            && laserPrinterIds?.length
            && PDPConfig.INK_PAPER_TMS_ID
            && intersection(productDetails?.customHierarchySet, laserPrinterIds)?.length) {
            const inkPaperIndex = categoriesList.findIndex((category) => category.tmsId === PDPConfig.INK_PAPER_TMS_ID);
            if(inkPaperIndex >= 0) {
                let categoryListCopy = cloneDeep(categoriesList);
                return { categoriesList: categoryListCopy, inkPaperIndex };
            }
        }

        return false;
    }
)

// Security Alerts Selectors
export const SecurityAlertsStore = (state: AppState) => state.pdpFeature?.securityAlerts;

// Mastiff Offers Selectors
//export const MastiffOffersStore = (state: AppState) => state.pdpFeature?.mastiffOffers?.accessories;
