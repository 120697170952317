import { FetchingAlertsSuccessAction, AlertsActions, AlertsActionTypes } from "./alerts.actions";


export interface AlertsDataModel {
  alertCTALink?: string;
  alertCTALinkText?: string;
  alertCategory?: string;
  alertDesc?: string;
  alertLinkToTarget?: string;
  alertName?: string;
  alertPriority?: string;
  alertType?: string;
  anrId?: string;
}

export interface AlertsState {
  loading: boolean;
  dataFetchSuccess: boolean;
  alerts: AlertsDataModel[];
}

const initialState: AlertsState = {
  loading: false,
  dataFetchSuccess: false,
  alerts: [],
};

export const alertsReducer = (state: AlertsState = initialState, action: AlertsActions): AlertsState => {
  switch (action.type) {
    case AlertsActionTypes.FETCHING_ALERTS_SUCCESS:
      const successAction: FetchingAlertsSuccessAction = action as FetchingAlertsSuccessAction;
      return {
        loading: false,
        dataFetchSuccess: true,
        alerts: [ ...successAction.payload.alerts ]
      };
    case AlertsActionTypes.START_FETCHING_ALERTS:
      // const startAction: StartFetchingAlertsAction = action as StartFetchingAlertsAction;
      return {
        loading: true,
        dataFetchSuccess: false,
        alerts: [],
      };
    case AlertsActionTypes.FETCHING_ALERTS_FAILED:
      // const failAction: FetchingAlertsFailedAction = action as FetchingAlertsFailedAction;
      return {
        loading: false,
        dataFetchSuccess: false,
        alerts: []
      };
    default:
      return state;
  }
};
