export let  secNavTitleConfig: Object = {
    'siteHome':"#pps_WCC_FS_HomeHP_Support_Home_Text",
    'printerLanding':"#pps_WCC_FS_Landing_Secnav_Printer_Label",
    'computerLanding':"#pps_WCC_FS_Landing_Secnav_Comp_Label",
    'productLanding':"#pps_WCC_FS_Landing_Secnav_Products_Label",
    'dashboard':"#pps_WCC_FS_Dashboard_BreadCrumb",
    'addDevice':"#pps_WCC_FS_Dashboard_BreadCrumb",
    'conversion':"#pps_WCC_FS_Landing_Secnav_Conversion_Label",
    'deviceSearch':"#pps_WCC_FS_Landing_Secnav_Products_Label",
    'printerSetupLanding':"#pps_WCC_FS_PS_PrintSetup_Text",
    'printerProductDetail':"#pps_WCC_FS_PS_PrintSetup_Text",
    'securityBulletin':"#pps_WCC_FS_SecurityBulletins_Page_Heading",
    'topicPage':"#pps_WCC_FS_SecondryNavigation_Title_Dropdown_Text",
    'topicLandingPage':"#pps_WCC_FS_SecondryNavigation_Title_Dropdown_Text",
    'error-page':"#pps_WCC_FS_PS_PrintSetup_Text",
    'search': '#pps_WCC_FS_PDP_Prod_sec_nav_Support_menu',
    'contact':"#pps_WCC_FS_UCE_Secnav_Contact_Label",
    'failureDiagnostic':"#pps_WCC_FS_UCE_Secnav_Contact_Label",
    'sdpPage':"#pps_WCC_FS_AEM_SecNav_All_Text",
    'disputeWarranty':"#pps_WCC_FS_HomeHP_Support_Home_Text",
    'checkWarranty':"#pps_WCC_FS_HomeHP_Support_Home_Text",
    'polyLanding':"#pps_WCC_FS_Landing_Secnav_Products_Label",
    'pdpLanding':"#pps_WCC_FS_PDP_Prod_sec_nav_Support_menu",
    'retiredProducts': '#pps_WCC_FS_lbl_PageAssembly_HPSupport',
    'swdLanding':"#pps_WCC_FS_AEM_SecNav_SoftwareandDrivers_Text",
    'swdDrivers':"#pps_WCC_FS_AEM_SecNav_SoftwareandDrivers_Text",
    'swdDriverDetails':"#pps_WCC_FS_AEM_SecNav_SoftwareandDrivers_Text",
    'swdClosure':"#pps_WCC_FS_AEM_SecNav_SoftwareandDrivers_Text",
    'documentTemplate':'#pps_WCC_FS_Document_Template_Support_Menu_Txt'
}
