// create actions with action creators
// Path: hp-wcc\src\app\store\regions\regions.actions.ts
// create actions with action creators

import { Action } from '@ngrx/store';
import { Region } from '../../services/regions.service';

export enum RegionsActionTypes {
    LoadRegions = '[Regions] Load Regions',
    RegionsLoaded = '[Regions] Regions Loaded',
    RegionsLoadError = '[Regions] Regions Load Error'
}
// Selectors for wcc-services/ssc/region API
export class LoadRegions implements Action {
    readonly type = RegionsActionTypes.LoadRegions;
}

export class RegionsLoadError implements Action {
    readonly type = RegionsActionTypes.RegionsLoadError;
    constructor(public payload: any) { }
}

export class RegionsLoaded implements Action {
    readonly type = RegionsActionTypes.RegionsLoaded;
    constructor(public payload: Region[]) { }
}

// Selectors for CLC Regions /wcc-services/clc/allRegions API

export enum ClcRegionsActionTypes {
    LoadClcRegions = '[Regions] Load CLC Regions',
    ClcRegionsLoaded = '[Regions] CLC Regions Loaded',
    ClcRegionsLoadError = '[Regions] CLC Regions Load Error'
}

export class LoadClcRegions implements Action {
    readonly type = ClcRegionsActionTypes.LoadClcRegions;
    constructor(public url: string) { } // params is used to pass query params to API call (example: {regionId: 1})
}

export class ClcRegionsLoadError implements Action {
    readonly type = ClcRegionsActionTypes.ClcRegionsLoadError;
    constructor(public payload: any) { }
}

export class ClcRegionsLoaded implements Action {
    readonly type = ClcRegionsActionTypes.ClcRegionsLoaded;
    constructor(public payload:{regions: Region[], params: any}) { }
}

export type RegionsActions = LoadRegions | RegionsLoaded | RegionsLoadError | LoadClcRegions | ClcRegionsLoaded | ClcRegionsLoadError;
