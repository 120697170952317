import { ProductAttributesActionTypes, ProductAttributesActions, SetProductAttributesAction } from "./product-attributes.actions";

export interface ProductAttributesModel{
    seriesOid: string,
    modelOid: string,
    productAttributes: string[],
    commonAttributes: string[],
    subscriptionEligibility: string[],
    mergedAttributes: string[]
}

export interface ProductAttributesState{
    data: ProductAttributesModel,
    loading: boolean,
    success: boolean
}

const initialState: ProductAttributesState = {
    data: null,
    loading: false,
    success: false
};

export const productAttributesReducer = (state:ProductAttributesState = initialState, action: ProductAttributesActions):ProductAttributesState=>{
    switch (action.type){
        case ProductAttributesActionTypes.FETCH_PRODUCT_ATTRIBUTES:
            // const fetchAction:FetchProductAttributesAction = action as FetchProductAttributesAction;
            return {
                data: {...state.data},
                loading: true,
                success: false
            };
        case ProductAttributesActionTypes.SET_PRODUCT_ATTRIBUTES:
            const setAction:SetProductAttributesAction = action as SetProductAttributesAction;
            return {
                data: setAction.payload.data,
                loading: false,
                success: setAction.payload.success
            };
        default:
            return state;
    }
};