import { createReducer, on } from "@ngrx/store";
import { ProductContextState } from "../../model/product-context.model";
import * as ProductContextActions from './productContext.actions';
import { isEqual } from "lodash-es";


const initialState: ProductContextState = {
    productContext: {
        seoName: '',
        seriesOid: '',
        modelOid: '',
        serialNumber: '',
        sku: ''
    },
    productData: {},
    loading: false
};


export const productContextReducer = createReducer(
    initialState,
    on(ProductContextActions.getProductContextData, (state: ProductContextState) => ({
        ...state,
        loading: true
    })),

    on(ProductContextActions.StoreProductContext, (state: ProductContextState, action) => {
        if (!isEqual(state?.productContext, action?.productContext)) {
            return { ...state, loading: false, productContext: action.productContext };
        }
        return state;
    }),

    on(ProductContextActions.ClearProductContext, (state: ProductContextState, action) => ({
        ...initialState
    })),
);