import { Component, OnDestroy, OnInit, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { WindowRefService } from '../../../services/window/window.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PageService } from '../../../services/common_service/page.service';
import { AppSelectorStore } from '../../../store/app.selector';
import { TermbaseKeysModel } from '../../../store/termbase-store/termbase.reducer';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html'
})

export class ErrorModalComponent implements OnInit, OnDestroy {
  // Page related config
  pageConfig: any = this.pageService.config;

  // Translations
  allTranslations:TermbaseKeysModel={};

  @Input() specificMessage:string = null;
  
  // Resolution based config
  largeDevice = false;
  smallDevice = false;
  
  window;

  @HostListener('window:resize') onResize() {
    this.resolutionBaseDisplay();
  }

  destroySubject$: Subject<void> = new Subject();

  constructor(
    private pageService: PageService,
    private windowRef: WindowRefService,
    private modalService: NgbModal,
    private appSelectorStore:AppSelectorStore
  ) {
    this.window = this.windowRef.nativeWindow;
  }
  
  ngOnInit() {
    this.appSelectorStore.getAllTranslations$()
    .pipe(takeUntil(this.destroySubject$))
    .subscribe(res=>this.allTranslations=res);      
    this.resolutionBaseDisplay();
  }

  resolutionBaseDisplay() {
    if (this.window) {
      this.largeDevice = this.window?.innerWidth <= 800 ? false : true;
      this.smallDevice = this.window?.innerWidth <= 800 ? true : false;
    }
  }

  closeErrorModal() {
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
