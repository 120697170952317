import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, map, shareReplay} from 'rxjs/operators';
import { PageService } from '../../services/common_service/page.service';
import * as allDeviceAction from "../../store/all-devices-store/all-devices.actions";
import { AppState } from '../app.reducer';
import { isEmpty, cloneDeep } from "lodash-es";
import { CommonService } from '../../services/common_service/common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../../services/common_service/auth.service';
import { AllDeviceData } from './all-devices.reducer';
import { SUBSCRIBED_WARRANTY_TYPE, syncApiError } from '../../constants/app-constants';
import { DeviceDetails, Entity, Subscriptions } from '../../modules/feature-module/device-carousel/device-carousel/device.model';
import { sscKeysSelector } from '../../services/ssc-store/config.selectors';
import { allDeviceSelector } from './all-devices.selectors';
@Injectable()

export class AllDeviceEffects{
    pageConfig: any = this.pageService.config;
    userData;
    constructor(
        private actions$: Actions,
        private httpClient : HttpClient,
        private pageService: PageService,
        private store : Store<AppState>,
        private commonService : CommonService,
        private deviceDetectorService: DeviceDetectorService,
        private authservice:AuthService
    ){
        this.authservice.userDataSub.subscribe(res=>{
            this.userData = res;
        })
    }
    getAllDeviceData = createEffect(()=>
        this.actions$.pipe(
            ofType(allDeviceAction.AllDeviceActionType.loadAllDevice),
            concatLatestFrom(() => [
                this.store.select(allDeviceSelector),
                this.store.select(sscKeysSelector)
            ]),
            mergeMap(([action,allDeviceData, sscKeys]:[any, any, any])=>{
                    let httpParams = new HttpParams();
                    let httpHeaders: HttpHeaders;
                    let oneHpNames = [];
                    if(!isEmpty(this.userData)){
                        httpHeaders = new HttpHeaders().set(syncApiError.key,syncApiError.value);
                    }else{
                        httpHeaders = new HttpHeaders();
                    }
                    const params = action?.payload?.options['params'];
                    const headers = action?.payload?.options['headers'];
                    httpParams = httpParams.append('login', 'false');
                    httpParams = httpParams.append('cc', this.pageConfig.cc);
                    httpParams = httpParams.append('lc', this.pageConfig.lc);
                    httpParams = httpParams.append('startIndex', '0');
                    httpParams = httpParams.append('limit', '10');
                    httpParams = httpParams.append('offset', this.commonService.getUTCOffset());
                    httpParams = httpParams.append('isMobile', this.deviceDetectorService.isMobile().toString());
                    httpParams = httpParams.append('timeStamp', Date.now().toString()); // Added to avoid cache
                    if(!isEmpty(sscKeys)) {
                        oneHpNames = this.commonService.getSscKeysForOneHp(sscKeys);
                    }
                    if (!isEmpty(params)) {
                    for (let param in params) {
                        httpParams = httpParams.set(param, params[param]);
                    }
                    }

                    if (!isEmpty(headers)) {
                    for (let header in headers) {
                        httpHeaders = httpHeaders.append(header, headers[header]);
                    }
                    }
                    return this.httpClient.get(`/wcc-services/profile/devices/all`, { params: httpParams, headers: httpHeaders }).pipe(
                        shareReplay(),
                        map((devicesData:AllDeviceData)=>{
                            if(oneHpNames.length) {
                                this.attachOneHpFlag(devicesData, oneHpNames);
                            }
                            return new allDeviceAction.LoadAllDeviceSuccess({...devicesData,oldPayload:cloneDeep(action?.payload),dataLoded:true});
                        }),
                        catchError((err)=>{
                            return of(new allDeviceAction.LoadAllDeviceFailure({...err,dataLoded:false}))
                        }))
            })
        )
    );


   attachOneHpFlag(res:AllDeviceData, hpOneNames: string[]): AllDeviceData {
        const devices = res?.data?.deviceList || [];
        if(!devices.length) return;
        devices.forEach((device: DeviceDetails)=>{
            const subscriptions = device?.subscriptions || [];
            const isOneHpWarranty = device?.warrantyType?.toString() === SUBSCRIBED_WARRANTY_TYPE;
            if((!subscriptions.length && isOneHpWarranty) || !isOneHpWarranty) return;
            subscriptions.forEach((subscription: Subscriptions)=>{
                const entities = subscription?.entities || [];
                if(!entities.length) return;
                entities.forEach((entity: Entity)=>{
                    if(entity && entity?.name) {
                        if(!hpOneNames.length) {
                            entity["isOneHp"] = false;
                        } else {
                            const isOneHp = hpOneNames.some( (name)=> entity.name === name);
                            entity["isOneHp"] = isOneHp;
                        }
                    }
                });
            });
        })
        return {...res};
    }

}
