import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Root provider is required
@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {

  private _loading: boolean = true;
  private _loadingText: string = '';
  loadingStatus: Subject<any> = new Subject();
  blockScreen: boolean;

  // Moved this Variable from loading interceptor to check activeRequest before stopping loader
  activeRequests: number = 0;

  get loading():boolean {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
  }

  get loadingText():string {
    return this._loadingText;
  }

  set loadingText(value) {
   this._loadingText = value;
   this.loadingStatus.next({isLoading: this._loading, loadingText: this._loadingText });
   this.resetLoadingText();
  }

  resetLoadingText() {
    this._loadingText = '';
  }

  startLoading(blockScreen: boolean = false) {
    this.blockScreen = blockScreen || this.blockScreen;
    this.loading = true;
    this.loadingStatus.next({isLoading: this._loading, loadingText: this._loadingText });
  }

  stopLoading(checkActiveRequests?: boolean) {
    this.blockScreen = false;

    if(this.activeRequests && checkActiveRequests) return;

    this.loading = false;
    this.loadingStatus.next({isLoading: this._loading, loadingText: this._loadingText });
  }
}
