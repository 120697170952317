import { WarrantySpecsData } from '../../model/warranty-spec.model';
import { WarrantySpecificationDetailsAction, WarrantySpecificationDetailsActionType } from './specs.actions';

export interface WarrantySpecsDataStore {
  warrantySpecsData: WarrantySpecsData;
  dataLoaded:boolean;
  loading: boolean;
}
export const initialState: WarrantySpecsDataStore = {
  warrantySpecsData: {
          error: null,
          apiPayload: null,
          date: '',
          data: null
      },
  dataLoaded:false,
  loading: false
};

export function contactProductRibbonDataReducer(state=initialState,action:WarrantySpecificationDetailsAction) {
    switch (action.type){
        case WarrantySpecificationDetailsActionType.LoadWarrantyDetails:
            return{
                ...state,
                dataLoaded:false
            }
        case WarrantySpecificationDetailsActionType.LoadWarrantyDetailsSuccess:
            return{
                ...state,
                warrantySpecsData:action.data,
                error:null,
                dataLoaded:true,
                loading: false
            }
        case WarrantySpecificationDetailsActionType.LoadWarrantyDetailsFailure:
            return{
                ...state,
                warrantySpecsData:action.data,
                error:action.data.error,
                dataLoaded:true,
                loading: false
            }
        case WarrantySpecificationDetailsActionType.ClearWarrantyDetailsData:
            return {
                ...initialState
            }
        case WarrantySpecificationDetailsActionType.LoadingWarrantyDetailsData:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}
