
export let globalDataLayer: Object = {

   'CountryCode': '',
   'LanguageCode': '',
   'Locale': '',
   'WebSectionId': 'R11839',
   'PfinderQueryNew': '',
   'PfinderQuery': '',
   'Template': 'SiteHome_New',
   'swSniffedOSsupported': null,
   'Document': {
      'documentID': ''
   },
   'Dashboard': {
      'NoOfDevices': '',
      'Products': [],
      'SpecsMissing': [

      ]
   },
   'Product': {
      'bigSeriesName': '',
      'bigSeriesOid': '',
      'supportCategoryName': '',
      'supportCategoryOid': '',
      'seriesName': '',
      'seriesOid': '',
      'productLineCode': '',
      'supportNameName': '',
      'supportNameOid': '',
      'platformId': '',
      'webReleaseDate': '',
      'serialNumber': '',
      'productModelNumber': '',
      'audienceType': ''
   },
   'Search': {
      'Query': '',
      'Redirect': false,
      'Count': '',
      'Product': {
         'bigSeriesName': '',
         'bigSeriesOid': '',
         'supportCategoryName': '',
         'supportCategoryOid': '',
         'seriesName': '',
         'seriesOid': '',
         'productLineCode': '',
         'supportNameName': '',
         'supportNameOid': '',
         'platformId': '',
         'webReleaseDate': '',
         'serialNumber': '',
         'productModelNumber': ''
      },
      'IDOLProduct': {
         'productName': '',
         'productTMSOID': '',
         'productTMSClass': ''
      }
   },
   'DMD': {
      'DMDrMismatch': '',
      'SSFInstalled': '',
      'ProdCount': '',
      'WPDSSFDownload': '',
      'WPDSSFavailable': '',
      'Products': [
         {
            'Pn': '',
            'Pl': '',
            'PName': '',
            'sOid': '',
            'pOid': '',
            'DetectMethod': ''
         }
      ]
   },
   'EntitlementResults': {
      'EntResults': 'NA', // updated as part of SSVSPRT-14180
      'BundleType': '',
      'SupportCode': ''
   },
   'ErrorCode': '',
   'softwareDownloadedFileNames': '',
   'IOG': [

   ],
   'sessionId': '',
   'chatBotAccessed': false,
   'customerState': 'Anonymous',
   'Flag': {
      'dashboardVisited':false,
      'documentViewed': false,
      'searchExecuted': false,
      'swdVisited': false,
      'contactHPVisited': false,
      'identifyNowDmd': false,
      'findSolutionsDmd': false,
      'checkNowDmd': false,
      'EASVisited': false
   },
   'chatBotWidgetId': 'chatbotToronto',
   'videosession': 'FALSE'
};
export const globalRegionId = 1;
