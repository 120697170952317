export let regional2ByteCCCodes: Object = {
    "emea_middle_east": {
      "twoByteCC": "m_"
    },
    "emea_africa": {
      "twoByteCC": "f_"
    },
    "lamerica_nsc_carib": {
      "twoByteCC": "c_"
    },
    "lamerica_nsc_cnt_amer": {
      "twoByteCC": "l_"
    }
  }