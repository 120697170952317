import { Action } from "@ngrx/store";
import { ConfigKeysModel } from "./sscKeys.reducer";

export enum SscKeysActionTypes {
    START_FETCHING_SSC_KEYS = '[sscKeys.action.ts] START_FETCHING_SSC_KEYS',
    FETCHING_SSC_KEYS_SUCCESS = '[sscKeys.action.ts] FETCHING_SSC_KEYS_SUCCESS',
    FETCHING_SSC_KEYS_FAILED = '[sscKeys.action.ts] FETCHING_SSC_KEYS_FAILED'
}

export class StartFetchingSscKeysAction implements Action{
    readonly type: string = SscKeysActionTypes.START_FETCHING_SSC_KEYS;

    constructor(
        public payload: {
            pageName?: string,
            isWebCompActive?: boolean,
            wcCC?: string,
            wcLC?: string,
            wcApiDomain?: string
        }
    ){}
}

export class FetchingSscKeysSuccessAction implements Action{
    readonly type: string = SscKeysActionTypes.FETCHING_SSC_KEYS_SUCCESS;

    constructor(
        public payload: {
            sscKeys?: ConfigKeysModel,
            udlKeys?: ConfigKeysModel
        },
        public pageName: string
    ){}
}

export class FetchingSscKeysFailedAction implements Action{
    readonly type: string = SscKeysActionTypes.FETCHING_SSC_KEYS_FAILED;
    
    constructor(public payload: {},
        public pageName: string){}
}

export type SscKeysActions = FetchingSscKeysSuccessAction | FetchingSscKeysFailedAction | StartFetchingSscKeysAction;