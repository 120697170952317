import { XhtmlTermbaseActions, XhtmlTermbaseActionTypes, XhtmlTermbasePayload } from './xhtml-termbase.actions';


export interface XhtmlTermbaseState {
    pageName: string;
    loading: boolean;
    error: any | null;
    response: XhtmlTermbasePayload | null;
}

const initialState: XhtmlTermbaseState = {
    pageName: '',
    loading: false,
    error: null,
    response: null
};

export function xhtmlTermbaseReducer(state = initialState, action: XhtmlTermbaseActions): XhtmlTermbaseState {
    switch (action.type) {
        case XhtmlTermbaseActionTypes.LoadXhtmlTermbase:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case XhtmlTermbaseActionTypes.LoadXhtmlTermbaseSuccess:
            return {
                ...state,
                pageName: action.pageName,
                response: action.response,
                loading: false,
                error: null,
            };
        case XhtmlTermbaseActionTypes.LoadXhtmlTermbaseFailure:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        // Add more cases for other actions here
        default:
            return state;
    }
}
