import { Component, Input, OnInit } from '@angular/core';
import { PageService } from '../../../services/common_service/page.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductListEntity, SNPNConflictModalResult, SearchResultsResponse, VerifyResponseData } from '../../../store/search-results/search-results.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import { sscKeysSelector } from '../../../services/ssc-store/config.selectors';
import { first } from 'rxjs/operators';
import { isEmpty, cloneDeep } from 'lodash-es';
import { ConfigKeysModel } from '../../../services/ssc-store/sscKeys.reducer';
import { CommonService } from '../../../services/common_service/common.service';
import { SearchResultsService } from '../../../services/common_service/search-results.service';
import { SearchResultsPayload } from '../../../store/search-results/search-results.actions';
import { TermbaseKeysModel } from '../../../store/termbase-store/termbase.reducer';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { WindowRefService } from '../../../services/window/window.service';
import { SPECS_ERROR_MESSAGES } from '../../../constants/app-constants';

@Component({
  selector: 'app-sn-pn-conflict-modal',
  templateUrl: './sn-pn-conflict-modal.component.html',
  styleUrls: ['./sn-pn-conflict-modal.component.scss']
})
export class SnPnConflictModalComponent implements OnInit {

  pageConfig: any = this.pageService.config;
  selectedOption;
  verifyData: VerifyResponseData;
  kaasData: ProductListEntity;
  serialNumber;
  pfinderMinCharLen: string;
  ssckeys;
  learnMore;
  invalidSN: boolean;
  noMatchFound: boolean;
  serialNumberButtonDisabled = true;
  selectedOptionData: SNPNConflictModalResult;
  _searchResults: SearchResultsResponse;
  window: any;
  @Input() set searchResults(v : SearchResultsResponse) {
    this._searchResults = v;
    this.verifyData = v?.data?.verifyResponse?.data;
    this.kaasData = v?.data?.kaaSResponse?.data?.searchResults?.categories?.[0].subCategoryList?.[0].productList?.[0];
  }

  @Input() searchResultsPayload: SearchResultsPayload;
  @Input() serialNumberRequired: boolean = false;
  @Input() allTranslations: TermbaseKeysModel = {};

  constructor(
    private pageService: PageService,
    public activeModal: NgbActiveModal,
    private commonService: CommonService,
    private searchResultsService: SearchResultsService,
    private modalService: NgbModal,
    private store: Store<AppState>,
    private windowRef: WindowRefService,
    ) {
      this.window = this.windowRef.nativeWindow;
    }

  ngOnInit(): void {
    this.getSSCconfig();
  }

  get closeBtnText() {
    if (this.window) {
      return this.window?.innerWidth <= 768 ? '' : this.allTranslations['#pps_WCC_FS_Search_Results_Conflict_Modal_Close'];
    }
  }

  closeModal() {
    setTimeout(() => this.activeModal.close(), 100)
  }

  getSSCconfig() {
    this.store.select(sscKeysSelector)
      .pipe(first(res => !isEmpty(res)))
      .subscribe((sscKeys: ConfigKeysModel) => {
        this.ssckeys = sscKeys;
        
        this.learnMore = sscKeys?.SN_PN_CONFLICT_MODAL_SERIAL_NUMBER_LEARN_MORE?.keyValue; 
        // check the pfinder minimum character length
        const polySearchExp = this.ssckeys.ENABLE_POLY_SEARCH_EXP && this.ssckeys.ENABLE_POLY_SEARCH_EXP.keyValue ? this.ssckeys.ENABLE_POLY_SEARCH_EXP.keyValue : '';
        this.pfinderMinCharLen = polySearchExp.toUpperCase() == "YES" ? (this.ssckeys.PFINDER_SN_MIN_CHAR_LENGTH && this.ssckeys.PFINDER_SN_MIN_CHAR_LENGTH.keyValue ? this.ssckeys.PFINDER_SN_MIN_CHAR_LENGTH.keyValue : "6") : "1"

      });
  }

  onChangeSerialNumberInput(data) {
    this.invalidSN = false;
    this.noMatchFound = false;
    if (data && typeof data === 'string') {
      data = data.trim();
    }
    if (data.length >= parseInt(this.pfinderMinCharLen)) {
      this.serialNumberButtonDisabled = false;
    } else {
      this.serialNumberButtonDisabled = true;
    }
  }

  submit() {
    if(this.disabled) return;

    if (this.selectedOption === this.kaasData && this.serialNumber && this.serialNumberRequired) {
      if (this.serialNumber && this.serialNumber.length >= parseInt(this.pfinderMinCharLen) && this.commonService.isSerialNumberPattern(this.serialNumber, this.ssckeys)) {
        this.searchResultsService.getSearchResult(this.serialNumber, this.searchResultsPayload.context, this.searchResultsPayload.query)
        .pipe(first(data => !isEmpty(data)))
        .subscribe((resp: SearchResultsResponse)  => {
          if(resp?.data?.verifyResponse?.code === 200) {
            this.selectedOptionData = { serialNumber: this.serialNumber, productNumber: this.searchResultsPayload?.query, targetUrl: resp?.data?.verifyResponse?.data?.targetUrl, verifyData: resp?.data?.verifyResponse?.data, historicalWebSupportFlag: resp?.data?.verifyResponse?.data?.historicalWebSupportFlag, kaasData: this.kaasData, verifySelected: true };
            this._searchResults = cloneDeep(resp);
            this._searchResults.result = this.selectedOptionData;
            this.activeModal.close(this._searchResults);
          } else if(resp?.data?.verifyResponse?.code === 500) {
            this.activeModal.close();
            this.modalService.open(ErrorModalComponent, { windowClass: 'error-modal-container', ariaLabelledBy: 'modal-heading', size: 'lg' });
          } else if(resp?.data?.verifyResponse?.code === 204 && resp?.data?.verifyResponse?.message === SPECS_ERROR_MESSAGES.NO_DEVICE_FOUND) {
            this.invalidSN = true;
          } else if(resp?.data?.verifyResponse?.code === 204 && resp?.data?.verifyResponse?.message === SPECS_ERROR_MESSAGES?.PN_NOT_MATCHING) {
            this.noMatchFound = true;
          } else {
            this.invalidSN = true;
          } 
        });

      } else {
        this.invalidSN = true;
      }
    } else {
      const verifySelected = this.selectedOption === this.verifyData;
      const targetUrl = verifySelected ? this.verifyData?.targetUrl : this.kaasData?.targetUrl;
      const serialNumber = verifySelected ? this.verifyData?.serialNumber : null;
      const productNumber = verifySelected ? this.verifyData?.productNumber : this.searchResultsPayload.query;
      const historicalWebSupportFlag = verifySelected ? this.verifyData?.historicalWebSupportFlag : this.kaasData?.historicalWebSupportFlag;
      this.selectedOptionData = { serialNumber, productNumber, targetUrl, historicalWebSupportFlag, verifyData: this.verifyData, kaasData: this.kaasData, verifySelected };
      this._searchResults.result = this.selectedOptionData;
      this.activeModal.close(this._searchResults);
    }
  }

  get disabled() {
    return !this.selectedOption || (this.selectedOption === this.kaasData && this.serialNumberButtonDisabled && this.serialNumberRequired) || (this.selectedOption === this.kaasData && this.invalidSN || this.noMatchFound && this.serialNumberRequired);
  }


}
