<div class="conflict-modal vis-id" [ngClass]="pageConfig.languageDirection" id="overlap-selectproduct">
    <div class="conflict-modal-header modal-header">
        <div class="modal-title with-logo">
            <h3 class="pf-modal-header">{{ allTranslations['#pps_WCC_FS_Search_Results_Conflict_Modal_Header'] }}</h3>
        </div>
        <button type="button" class="close" [attr.aria-label]="'Close'" id="close" (click)="closeModal()">
            <span class="text"></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="radio-option-cell">
            <input type="radio" (click)="selectedOption = verifyData" value="option1" [id]="verifyData?.productNumber || verifyData?.altProductNumber"
                name="personalDataCollection" />
            <label [for]="verifyData?.productNumber || verifyData?.altProductNumber">{{ verifyData?.productName + ' (' + (verifyData?.productNumber || verifyData?.altProductNumber || '')?.split('#')?.[0] +')' }}</label>
        </div>
        <div class="radio-option-cell">
            <input type="radio" (click)="selectedOption = kaasData" value="option2" [id]="kaasData?.productName"
                name="personalDataCollection" />
            <label [for]="kaasData?.productName">{{ kaasData?.productName }}</label>
        </div>

        <!-- Display text box only if option2 is selected -->
        <div class="serial-entry-box" *ngIf="selectedOption === kaasData && serialNumberRequired">
            <!-- Create bootstrap textbox with floating placeholder -->
            <div class="input-container">
                <div class="form-floating">
                    <input type="text"
                    [class.invalid]="invalidSN || noMatchFound" 
                    class="form-control"
                    [id]="'text-'+ kaasData?.productName"
                    appRestrictPattern
                    appRestrictPatternFlag= "true"
                    appRestrictPatternValue = "[^\@\%\_\&\*\+\$\(\)\!\^\<\>]" 
                    [(ngModel)]="serialNumber"
                    (keyup.enter)="submit()"
                    (ngModelChange)="onChangeSerialNumberInput($event)"
                    [placeholder]= "allTranslations['#pps_WCC_FS_Search_Results_Conflict_Modal_Placeholder']"
                    >
                    <label [for]="'text-'+ 'productOID'"> {{ allTranslations['#pps_WCC_FS_Search_Results_Conflict_Modal_Placeholder'] }}</label>
                </div>
                <a target="_blank" id="learn_more" [title]="allTranslations['#pps_WCC_FS_Search_Results_Conflict_Modal_Learn_More_Link']" [href]="learnMore">
                   <span aria-hidden="true" > {{ allTranslations['#pps_WCC_FS_Search_Results_Conflict_Modal_Learn_More_Link'] }} </span>
                </a>
            </div>
            <div class="error-box" *ngIf="noMatchFound">
                <p class="error-text" id="" aria-live="assertive" role="alert">{{ allTranslations['#pps_WCC_FS_Search_Results_Conflict_Modal_Error1'] }}</p>
            </div>
            <div class="error-box" *ngIf="invalidSN">
                <p class="error-text" id="" aria-live="assertive" role="alert">{{ allTranslations['#pps_WCC_FS_Search_Results_Conflict_Modal_Error2'] }}</p>
            </div>

        </div>


    </div>

    <div class="modal-footer">
        <button type="button" class="btn confirm"  [id]="'confirm-'+ (selectedOption?.productNumber || selectedOption?.altProductNumber || selectedOption?.productName)" (keydown.enter)="$event.preventDefault();" (keyup.enter)="submit()" (click)="submit()" [disabled]="disabled">{{ allTranslations['#pps_WCC_FS_Search_Results_Conflict_Modal_Confirm'] }}</button>
        <button type="button" class="btn cancel" id="cancel" (click)="closeModal()"> {{ allTranslations['#pps_WCC_FS_Search_Results_Conflict_Modal_Cancel'] }}</button>
    </div>

</div>