import { globalDataLayer } from '../../globals';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { merge as lodashMerge} from 'lodash-es';
import { WindowRefService } from '../../services/window/window.service';
import { CommonService } from './common.service';
import { PageService } from './page.service';
import { UdlLogService } from './udl.service';

// Root provider is required
@Injectable({
    providedIn: 'root'
})

export class GlobalDataLayerService implements OnDestroy {
    // Page related config
    pageConfig: any = this.pageService.config;

    private window: any;
    private destroySubject$: Subject<void> = new Subject();

    constructor(
        private pageService: PageService,
        private windowRef: WindowRefService,
        private commonService: CommonService,
        private udlLogService: UdlLogService
    ) {
        if (this.pageConfig.isBrowser) {
            this.window = this.windowRef.nativeWindow;

            if (this.window) {
                this.window.globalDataLayer = {};
            }
        }
    }

    ngOnDestroy(): void {
        this.destroySubject$.next();
    }

    ifGlobalDataExists() {
        if (this.pageConfig.isBrowser) {
            return localStorage.getItem('globalDataLayer') === null ? false : true;
        }
    }

    // getting window object
    getWindowGlobalData() {
        if (this.pageConfig.isBrowser) {
            return this.window?.globalDataLayer;
        }
    }

    // Function for setting window object
    setWindowGlobalData(globalData) {
        if (globalData && this.pageConfig.isBrowser) {
            this.window.globalDataLayer = globalData;
        }
    }

    // Reading the local storage objects
    getLocalstorageGlobalData() {
        if (this.ifGlobalDataExists()) {
            const parsedLocalStorageData = this.commonService.safeParseJSON(localStorage.getItem('globalDataLayer'));
            return parsedLocalStorageData;
        }
        else if (this.pageConfig.isBrowser) {
            return {};
        }
    }

    // Setting Localstorage and window object
    setLocalstorageGlobalData(globalData) {
        if (this.pageConfig.isBrowser && typeof localStorage !== 'undefined') {
            localStorage.setItem('globalDataLayer', JSON.stringify(globalData));
            this.setWindowGlobalData(globalData);
        }
    }

    setInitialGlobalData() {
        if (this.pageConfig.isBrowser && !localStorage.getItem('globalDataLayer')) {
            this.setLocalstorageGlobalData(globalDataLayer);
        }
    }

    updatePageTemplate(templateName?: string) {
        this.updateGDL({ Template: templateName || this.pageConfig.templateName }, true);
    }

    // One liner function
    updateGDL(updatedJSON, merge = false) {
        if (this.pageConfig.isBrowser && updatedJSON) {
            if (!this.ifGlobalDataExists()) {
                this.setInitialGlobalData();
            }

            const localDatalayer = this.getLocalstorageGlobalData();

            localDatalayer.EntitlementResults.SupportCode = localDatalayer?.EntitlementResults?.SupportCode || '';
            localDatalayer.EntitlementResults.BundleType = localDatalayer?.EntitlementResults?.BundleType || '';

            if (merge)
              lodashMerge(localDatalayer, updatedJSON);
            else
                Object.assign(localDatalayer, updatedJSON);

            this.setLocalstorageGlobalData(localDatalayer);

            if (updatedJSON?.Template) {
                this.pageConfig.templateName = updatedJSON.Template;
                this.udlLogService.logEvent(updatedJSON.Template);
            }

            if (updatedJSON?.customerState) {
                this.pageConfig.customerState = updatedJSON.customerState;
            }
        }
    }

    // Clearing the Dashboard info from GDL
    clearDashboardInfofromGDL() {
        if (this.pageConfig.isBrowser) {
            this.updateGDL({
                Dashboard: {
                    NoOfDevices: 0,
                    SubscriptionServices: '',
                    Products: [],
                    SpecsMissing: [],
                    customerState: 'Anonymous'
                }, customerState: 'Anonymous'
            })
        }

    }
}
