
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { SearchResultsActionTypes, LoadSearchResultsSuccess, LoadSearchResultsFailure } from './search-results.actions';
import { SearchResultsService } from '../../services/common_service/search-results.service';

@Injectable()
export class SearchResultsEffects {
  loadSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchResultsActionTypes.LoadSearchResults),
      mergeMap((action: any) =>
        this.searchResultsService.fetchSearchResults(action.payload).pipe(
          map((results) => new LoadSearchResultsSuccess(action.payload, results)),
          catchError((error) => of(new LoadSearchResultsFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private searchResultsService: SearchResultsService
  ) {}
}
