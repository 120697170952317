import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { of, throwError } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { PageService } from "../../services/common_service/page.service";
import { HttpService } from "../../services/service_loader/http.service";
import { FetchingTermbaseFailedAction, FetchingTermbaseSuccessAction, StartFetchingTermbaseAction, TermbaseActionTypes } from "./termbase.actions";
import { TermbaseKeysModel } from "./termbase.reducer";
import { AppState } from "../app.reducer";
import { Store } from "@ngrx/store";
import { allTermbaseKeysSelector} from "./termbase.selector";
import { isEmpty } from "lodash-es";
@Injectable()
export class TermbaseEffects{

    pageConfig: any = this.pageService.config;

    constructor(
        private actions$: Actions,
        private http: HttpService,
        private pageService: PageService,
        private store: Store<AppState>
    ){}

    termbaseFetch = createEffect(()=>
      this.actions$.pipe(
        ofType(TermbaseActionTypes.START_FETCHING_TRANSLATIONS),
        concatLatestFrom(() => this.store.select(allTermbaseKeysSelector)),
        mergeMap(([termbaseAction, termBaseKeys]: [StartFetchingTermbaseAction, any]) => {
              const isDebugMode = this.pageConfig.queryParams && !(!this.pageConfig.queryParams.displayKey);
              const currentPage = termbaseAction.payload.pageName || this.pageConfig.pageName;
              const selectedPageTermKeys = termBaseKeys[currentPage] || {};
              if(isEmpty(selectedPageTermKeys)) {
                return this.http
                .get(`/wcc-services/termbase/${this.pageConfig.cc}-${this.pageConfig.lc}/${currentPage}`).pipe(
                  map(
                    result=>{
                      if (result && result.data) {
                        let translations:TermbaseKeysModel = {};
                        if (isDebugMode) {
                          for(const key of Object.keys(result.data)){
                            translations[key] = key;
                          }
                        } else {
                          translations = result.data;
                        }
                        return new FetchingTermbaseSuccessAction({
                          keys: translations,
                          pageName: currentPage
                        });
                      }else{
                        throwError(0);
                      }
                    }
                  ),
                  catchError(error => {
                      return of(new FetchingTermbaseFailedAction({
                        pageName: currentPage
                      }));
                  })
                )
              } else {
                  return of(new FetchingTermbaseSuccessAction({
                    keys: selectedPageTermKeys,
                    pageName: currentPage
                  }));
                }
            })
        ));
    };