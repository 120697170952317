import { NgModule } from "@angular/core";
import { ViewEncapsulationDirective } from "./view-encapsulation.directive";
import { SvgVaultDirective } from "../vault/svg-vault.directive";
import { MemoizePipe } from "../filter/memo.pipe";


@NgModule({
    declarations: [
        ViewEncapsulationDirective,
        SvgVaultDirective
    ],
    exports:[
        ViewEncapsulationDirective,
        SvgVaultDirective
    ]
  })
  
  export class CommonDirectiveModule { }