import { ConfirmDeviceAction, ConfirmDeviceActionType } from './confirm-device.actions';
import {  Warranty } from '../../model/warranty-spec.model';

export interface DevicesList {
    faultItemList?: any[];
    devices: Warranty[];
}
export interface ConfirmDeviceData {
    timeElapsedInMS: number;
    code: number;
    status: string;
    message: string;
    data: DevicesList;
    dataLoded:boolean;
    oldPayload:any;
}

export const allDeviceInitialState: ConfirmDeviceData = {
    timeElapsedInMS:0,
    code: 0,
    status: '',
    message: '',
    data:{ devices:[], faultItemList:[] } ,
    dataLoded:false,
    oldPayload:{}
}

export function ConfirmDeviceActionReducer(state=allDeviceInitialState,action:ConfirmDeviceAction){
    switch(action.type){
        case ConfirmDeviceActionType.loadConfirmDevice:
            return{
                ...state,
                dataLoded: false
            }
        case ConfirmDeviceActionType.loadConfirmDeviceSuccess:
            return{
                ...state,
                ...action.data,
                dataLoded: true
            }
        case ConfirmDeviceActionType.loadConfirmDeviceFailure:
            return{
                ...state,
                error:action.error,
                dataLoded: true
            }
        default :
        return state;
    }
}