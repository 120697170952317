export const cclcAlias: Object = {
  'bg-bg': {
    "actualCc":'bg',
    "actualLc":'en'
  },
  'hr-hr': {
    "actualCc":'hr',
    "actualLc":'en'
  },
  'ee-et': {
    "actualCc":'ee',
    "actualLc":'en'
  },
  'lv-lv': {
    "actualCc":'lv',
    "actualLc":'en'
  },
  'ro-ro': {
    "actualCc":'ro',
    "actualLc":'en'
  },
  'rs-sr': {
    "actualCc":'rs',
    "actualLc":'en'
  },
  'sk-sk': {
    "actualCc":'sk',
    "actualLc":'en'
  },
  'si-sl': {
    "actualCc":'si',
    "actualLc":'en'
  },
  'lt-lt': {
    "actualCc":'lt',
    "actualLc":'en'
  }
}