<!--Retired product Modal-->
<div [ngClass]="{'vis-id': visidEnabled}" class="retired-modal-block {{pageConfig.languageDirection}}">
  <div class="modal-header">
    <img *ngIf="!pageConfig.isMobile && !visidEnabled" class="modal-logo" alt="hp-logo" src="/wcc-assets/images/hp-logo-modal.gif">
    <h3 *ngIf="!visidEnabled" class="modal-title non-vis" id="retired-product-header">{{ allTranslations["#pps_WCC_FS_Retired_Product_Modal_Heading"] }}</h3>
    <h3 class="modal-title" *ngIf="visidEnabled">You have selected a retired product</h3>
    <button *ngIf="!visidEnabled" type="button" 
            class="close" 
            aria-label="Close" 
            (click)="closeErrorModal()">
      <span aria-hidden="true" id="retired-close-icon">&times;</span>
    </button>
    <a *ngIf="visidEnabled" href="javascript:void(0)" (click)="closeErrorModal()" class="close">
      <span class="svg-vault svg-cross"></span>
    </a>
  </div>
  <div class="modal-body">
    <div *ngIf="!visidEnabled" class='modal-body-wrapper'>
      <div class='modal-row' [ngClass]="pageConfig.isMobile ? 'mob-retired-prod-modal' : ''">
        <div class='column'>
          <div class='status-column'>
            <div class='status-column-title'>
              <span>{{ allTranslations["#pps_WCC_FS_Dashboard_Status"] }}</span>
            </div>
            <img [src]="retiredProductData?.imageUrl"
                alt="{{ allTranslations['#pps_WCC_FS_Retired_Product_Modal_Column_1_Sub_Heading'] }}" />
            <p>{{ allTranslations["#pps_WCC_FS_Retired_Product_Modal_Column_1_Sub_Heading"] }}</p>
          </div>
        </div>
        <div class='column details'>
          <div class='details-column'>
            <div class='status-column-title'>
              <span>{{ allTranslations["#pps_WCC_FS_Retired_Product_Modal_Column_2_Heading"] }}</span>
            </div>
            <div class='status-column-sub-title'>
              <span>{{ allTranslations["#pps_WCC_FS_Retired_Product_Modal_Column_3_Sub_Heading"] }}</span>
            </div>
            <div class='status-column-details'>
              <span>{{ allTranslations["#pps_WCC_FS_Retired_Product_Modal_Column_2_Details"] }}
              </span>
            </div>
          </div>
        </div>
        <div class='column'>
          <div class='resource-column'>
            <div class='resource-column-title'>
              <span>{{ allTranslations["#pps_WCC_FS_Retired_Product_Modal_Column_3_Heading"] }}</span>
            </div>
            @if  (mastiffShopLink?.buttonURL){
              <div class='status-resource-sub-title-resource mastiff-offer'>
              <a [href]="mastiffShopLink?.buttonURL" target="_blank" [id]="mastiffLinkId" [title]="mastiffShopLink?.primaryButtonText"><span aria-hidden="true">{{ mastiffShopLink?.primaryButtonText }} </span></a>
            </div>
            }
            @if (retiredproductResourceLinks?.length){
              <div class='status-resource-sub-title-resource aem-offer'
              *ngFor="let retiredproductResourceLink of retiredproductResourceLinks | orderBy :'order';">
              <a [href]="retiredproductResourceLink?.link" target="_blank" [title]="retiredproductResourceLink?.linkText">{{ retiredproductResourceLink?.linkText }}
                ></a>
            </div>
            }
           
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="visidEnabled" class="sub-header">
      Products that are no longer supported
    </div>
    <div *ngIf="visidEnabled" class="description">
      Once a product is retired, the official HP support content (troubleshooting articles, user guides, how-to videos, etc.) for that product is removed from the HP website. Additional support content may still be available via the HP forums or from third-party websites; however, HP takes no responsibility for content authored by third-parties.
    </div>
    <div *ngIf="visidEnabled" class="redirection-link">
      @if (mastiffShopLink?.buttonURL) {
        <a  
        [href]="mastiffShopLink?.buttonURL" target="_blank" [id]="mastiffLinkId" title="mastiffShopLink?.primaryButtonText">
        <span aria-hidden="true"> {{ mastiffShopLink?.primaryButtonText }} </span>
      </a>
      }
      @if (retiredproductResourceLinks?.length){
        <a *ngFor="let retiredproductResourceLink of retiredproductResourceLinks | orderBy :'order';" 
        [href]="retiredproductResourceLink?.link" target="_blank" title="retiredproductResourceLink?.linkText">
        <span aria-hidden="true"> {{ retiredproductResourceLink?.linkText }} </span>
      </a>
      }
      
    </div>
  </div>

  <app-retired-product-modal-footer *ngIf="!visidEnabled"></app-retired-product-modal-footer>
</div>