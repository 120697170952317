import { Action } from "@ngrx/store";
import { CountryList } from "../../modules/feature-module/country-lang-selector/countryList.model";

export enum CountryListActionTypes {
    STORE_COUNTRY_LIST = '[translated-countrylist.action.ts] STORE_COUNTRY_LIST'
}

export class StoreCountryListAction implements Action{
    readonly type: string = CountryListActionTypes.STORE_COUNTRY_LIST;

    constructor(
        public payload: {
            success: boolean,
            locale: string,
            response: CountryList[]
        }
    ){}
}


export type CountryListActions = StoreCountryListAction;