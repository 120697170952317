import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment.prod';


// Root provider is required
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  // private readonly _getContent = new Map<any, Observable<any>>();
 baseUrl = environment.port ;
  constructor(
    private http: HttpClient
  ) {}

  safeParseJSON(jsonData) {
    let parsedData;

    try {
      parsedData = JSON.parse(jsonData);
    } catch (error) {
      console.log('Error occured while parsing data in commonService', error);
    }
    
    return parsedData;
  }

  // @todo : remove isNotificationIconCall option from the get call

  get(url, options = null, isNotificationIconCall = false) {
    const optionsTemplate = {
      responseType: null,
      headers: null
    };
    if (options && isNotificationIconCall) {
      if (options.responseType && options.responseType === 'text') {
        // console.log('Notif fired!',url);
        return this.http.get(url, options)
          .pipe(
              catchError( err => {
                // const errorMsg = `GET call failed with URL - ${url}`;
                return throwError(err);
              }),
              catchError(this.onError)
          )
          .pipe(finalize(() => {
            // console.log('Finalize');
          }));
      }
    } else {
      if (options && isNotificationIconCall === false) {
        Object.assign(optionsTemplate, options);
        if (options.responseType && options.responseType === 'text') {
          return this.http.get(url, options)
            .pipe(map(
              (res: any) => {
                // console.log("HttpService -> get -> res", res)
                // console.log("HttpService -> get -> res.indexOf(status) >-1", res.indexOf("status") >-1)
                res =  res.replace(/(\t\r\n|\n|\r)/gm,  ' ');
                res =  decodeURIComponent(encodeURIComponent(res).replace(/%09/g,  ''));
                // console.log('RegEx Res ----', res);
                const formattedData = this.safeParseJSON(res);
                // console.log("HttpService -> get -> formattedData", formattedData)
                return formattedData;
              }
            ))
            .pipe(catchError( err => {
              //const errorMsg = `GET call failed with URL - ${url}`;
              //// console.log('Throwing modified error on '+url);
              return throwError(err);
            }),catchError(this.onError))
            .pipe(finalize(() => {
              // console.log('Finalize');
            }));
        } else {
          return this.http.get(url, options)
            .pipe(map(res =>  res ))
            .pipe(catchError(this.onError))
            .pipe(finalize(() => {
              // console.log('Finalize');
            }));
        }
      } else {
        return this.http.get(url).pipe(map(res => {
          return res;
        })).pipe(catchError( err => {
          // const errorMsg = `GET call failed with URL - ${url}`;
          // // console.log('Throwing modified error on '+url);
          return throwError(err);
        }),catchError(this.onError))
          .pipe(finalize(() => {
            // console.log('Finalize');
          }));
      }
    }

  }

  post(url, postBody: any, options = null) {
    if (options) {
      return this.http.post(url, postBody, options)
        .pipe(map(res => res))
        .pipe(catchError( err => {
          // const errorMsg = `POST call failed with URL - ${url}`;
          // // console.log('Throwing modified error on '+url);
          return throwError(err);
        }), catchError(this.onError))
        .pipe(finalize(() => {
          // console.log('Submitted successfully');
        }));
    } else {
      return this.http.post(url, postBody)
        .pipe(map(res => res))
        .pipe(catchError( err => {
          // const errorMsg = `POST call failed with URL - ${url}`;
          // // console.log('Throwing modified error on '+url);
          return throwError(err);
        }), catchError(this.onError))
        .pipe(finalize(() => {
          // console.log('resoponse got loaded');
        }));
    }
  }
  delete(url, options) {
    return this.http.delete(url, options)
      .pipe(finalize(() => {
        // console.log('something went wrong delete method');
      }));
  }
  put(url, putData = null) {
    return this.http.put(url, putData).pipe(map(res => res)).pipe(catchError(this.onError))
      .pipe(finalize(() => {
        // console.log('something went wrong Put method');
      }));
  }
  patch(url, patchtData) {
    return this.http.put(url, patchtData).pipe(map(res => res)).pipe(catchError(this.onError))
      .pipe(finalize(() => {
        // console.log('something went wrong Put method');
      }));
  }
  onError(errorResponse: any) {
      return throwError(errorResponse);
  }
 

}

