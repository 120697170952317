import { Action } from "@ngrx/store";
import { ProductAttributesModel } from "./product-attributes.reducer";

export enum ProductAttributesActionTypes {
    FETCH_PRODUCT_ATTRIBUTES = '[product-attributes.actions.ts] FETCH_PRODUCT_ATTRIBUTES',
    SET_PRODUCT_ATTRIBUTES = '[product-attributes.actions.ts] SET_PRODUCT_ATTRIBUTES'
}

export class FetchProductAttributesAction implements Action{
    readonly type: string = ProductAttributesActionTypes.FETCH_PRODUCT_ATTRIBUTES;

    constructor(
        public payload: {
            seriesOid: string,
            modelOid: string
        }
    ){}
}

export class SetProductAttributesAction implements Action{
    readonly type: string = ProductAttributesActionTypes.SET_PRODUCT_ATTRIBUTES;

    constructor(
        public payload: {
            success: boolean,
            data: ProductAttributesModel
        }
    ){}
}

export type ProductAttributesActions = FetchProductAttributesAction | SetProductAttributesAction;