import { ActionReducerMap } from "@ngrx/store";
import { PdpState } from "../pages/pdp/store/pdp/pdp.reducer";
import { ProductContextState } from "../model/product-context.model";
import { sscKeysReducer, SscKeysState } from "../services/ssc-store/sscKeys.reducer";
import { productContextReducer } from "./product-context/productContext.reducer";
import { WarrantySpecsDataStore, contactProductRibbonDataReducer } from "./specs-store/specs.reducer";
import { allDeviceActionReducer, AllDeviceData } from "./all-devices-store/all-devices.reducer";
import { ConfirmDeviceActionReducer, ConfirmDeviceData } from "./confirm-device-store/confirm-device.reducer";
import { Device_DeviceIDData, device_DeviceIDReducer } from "./device-deviceId-store/device-deviceId.reducer";
import { DeviceWarrantyData, DeviceWarrantyReducer } from "./device-warranty-store/device-warranty.reducer";
import { termbaseReducer, TermbaseState } from "./termbase-store/termbase.reducer";
import { syncStatusDataType, SyncStatusReducer } from "./sync-status-store/sync-status.reducer";
import { CLCRegionsReducer, CLCRegionsState, RegionsReducer, RegionsState } from "./regions/regions.reducer";
import { alertsReducer, AlertsState } from "./alerts-store/alerts.reducer";
import { countryListReducer, CountryListState } from "./translated-countrylist/translated-countrylist.reducer";
import { searchResultsReducer, SearchResultsState } from "./search-results/search-results.reducer";
import { productAttributesReducer, ProductAttributesState } from "../pages/pdp/store/product-attributes/product-attributes.reducer";
import { videoDetailsReducer, VideosDetailState } from "./video-details/video-details.reducer";
import { MastiffOffersReducer, MastiffOffersState } from '../store/mastiff-offers-store/mastiff-offers.reducer';
import { xhtmlTermbaseReducer, XhtmlTermbaseState } from "./xhtml-termbase-store/xhtml-termbase.reducer";


export interface AppState {
    sscKeys: SscKeysState,
    pdpFeature?: PdpState,
    productContextData: ProductContextState,
    warrantySpecsDataStore: WarrantySpecsDataStore,
    allDeviceData:AllDeviceData,
    confirmDeviceData:ConfirmDeviceData,
    device_DeviceID:Device_DeviceIDData,
    DeviceWarrantyData:DeviceWarrantyData,
    termbaseKeys: TermbaseState,
    SyncStatusData:syncStatusDataType,
    Regions: RegionsState,
    CLCRegions: CLCRegionsState,
    alerts: AlertsState,
    translatedCountryList: CountryListState,
    searchResultsState: SearchResultsState,
    productAttributes: ProductAttributesState,
    videosDetailState: VideosDetailState,
    mastiffOffersState: MastiffOffersState,
    xhtmlTermbaseState: XhtmlTermbaseState,
}

export const appReducer: ActionReducerMap<AppState> = {
    sscKeys: sscKeysReducer,
    warrantySpecsDataStore: contactProductRibbonDataReducer,
    productContextData: productContextReducer,
    allDeviceData:allDeviceActionReducer,
    confirmDeviceData:ConfirmDeviceActionReducer,
    device_DeviceID:device_DeviceIDReducer,
    DeviceWarrantyData:DeviceWarrantyReducer,
    termbaseKeys: termbaseReducer,
    SyncStatusData:SyncStatusReducer,
    Regions: RegionsReducer,
    CLCRegions: CLCRegionsReducer,
    alerts: alertsReducer,
    translatedCountryList: countryListReducer,
    searchResultsState: searchResultsReducer,
    productAttributes: productAttributesReducer,
    videosDetailState: videoDetailsReducer,
    mastiffOffersState: MastiffOffersReducer,
    xhtmlTermbaseState: xhtmlTermbaseReducer
};

