import { MastiffOffersPayload } from './mastiff-offers.model';
import { props } from '@ngrx/store';
import { createAction } from '@ngrx/store';


export enum MastiffActionType{
 
    GetMastiffOffersData = '[MastiffOffers] Get Data',
    StoreMastiffOffersData = '[MastiffOffers] Store Data',
    ClearMastiffOffersData = '[MastiffOffers] Clear Data',
}


// Mastiff Offers Actions

export const GetMastiffOffers = createAction(
    MastiffActionType.GetMastiffOffersData,
    props<{ payload: MastiffOffersPayload }>()
);

export const StoreMastiffOffers = createAction(
    MastiffActionType.StoreMastiffOffersData,
    props<{ offers: any }>()
);

export const StoreRetiredProductMastiffOffers = createAction(
    MastiffActionType.StoreMastiffOffersData,
    props<{ offers: any }>()
);

export const ClearMastiffOffers = createAction(
    MastiffActionType.ClearMastiffOffersData,
);
